export function dateToFormattedString(date: Date): string {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const monthName = monthNames[monthIndex];
  const year = date.getFullYear();
  const suffix =
    day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th";
  return `${monthName} ${day}${suffix}, ${year}`;
}

// Function to convert a string of format "Month Day, Year" to a Date object
export function formattedStringToDate(str: string): Date | null {
  const parts = str.match(/(\w+)\s(\d+)[a-z]*,\s(\d+)/);
  if (parts && parts.length === 4) {
    const monthIndex = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ].indexOf(parts[1]);
    if (monthIndex !== -1) {
      const day = parseInt(parts[2], 10);
      const year = parseInt(parts[3], 10);
      return new Date(year, monthIndex, day);
    }
  }
  return null;
}

export function formatDateTimestampForMessages(date: Date): string {
  // Array of month abbreviations
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the month, date, hours, and minutes
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  let hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine the suffix for the day
  const daySuffix = (day: number): string => {
    if (day > 3 && day < 21) return "th"; // Numbers 4-20 have 'th' suffix
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Convert 24-hour time to 12-hour time and determine AM/PM
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Ensure minutes have leading zero if < 10
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;

  return `${month} ${day}${daySuffix(day)}, ${hours}:${minutesStr}${ampm}`;
}

export const getDateObjFromDate = (dateOfBirth: string) => {
  return new Date(
    parseInt(dateOfBirth.split("/")[2]),
    parseInt(dateOfBirth.split("/")[0]) - 1,
    parseInt(dateOfBirth.split("/")[1])
  );
};

export const getCalendarStringFromDateTime = (date: Date) => {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = date.getFullYear();
  return mm + "/" + dd + "/" + yyyy;
};

export function formatTimestamp(timestamp: number): string {
  // Create a Date object from the timestamp
  const date = new Date(timestamp);
  return formatDateTimestampForMessages(date);
}

export function formatTimestampOfESignature(timestamp: number): string {
  const date = new Date(timestamp);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert to 12-hour format and handle midnight

  return `${day}-${month}-${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
}
