import React, { useEffect } from "react";
import useSWR from "swr";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { fetcher } from "utils/api";
import { useAuth } from "contexts/AuthContext";
import { useQuery } from "@apollo/client";
import { getCurrentUserQuery } from "gql/User.gql";
import { UserRole } from "gql-gen/graphql";

export const useIsLoggedIn = () => {
  const { data, error } = useSWR("/api/auth/user/refresh", fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });
  return !!data?.access_token;
};

export function CheckAuthComponent({
  children,
  require,
  adminOnly,
}: {
  require: "loggedin" | "loggedout";
  adminOnly?: boolean;
  children?: React.ReactNode;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  const { currentUser, isLoading } = useAuth();

  console.log({ currentUser });

  const { error } = useQuery(getCurrentUserQuery, {
    pollInterval: 30000,
    skip: !currentUser,
  });
  useEffect(() => {
    if ((!currentUser && !isLoading) || error) {
      navigate(`/login${pathName ? `?redirect_url=${pathName}` : ""}`);
    }
    if (currentUser && adminOnly && currentUser.role !== UserRole.ADMIN) {
      navigate("/dashboard/patient/home");
    }
  }, [currentUser, isLoading, error, adminOnly, pathName]);

  return currentUser ? <Outlet /> : <div />;
}
