import React from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Stack, ThemeProvider } from "@mui/system";
import { Button, Fade, Typography, useMediaQuery } from "@mui/material";
import { matchPath, matchRoutes, useMatch, useNavigate } from "react-router";

import { NavBarItemProps } from "./NavbarRoutes";

export const NavbarItem = ({
  selectedIcon = undefined,
  label,
  unselectedIcon = undefined,
  baseRoute,
  routeTo = undefined,
  notification = false,
}: NavBarItemProps & { notification?: boolean; disabled?: boolean }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const routes = [{ path: baseRoute }];
  const matches = matchRoutes(routes, { pathname: location.pathname });

  const selected = !!matches?.length;
  return (
    <Stack
      sx={(theme) => ({
        width: "177px",
        borderRadius: "0px 50px 50px 0px",
        py: "17px",
        pl: "41px",
        mb: "4px",
        cursor: "pointer",
        backgroundColor: selected
          ? theme.palette.gray.activeNavbarItem
          : undefined,
        "&:hover": {
          backgroundColor: selected
            ? theme.palette.gray.activeNavbarItem
            : theme.palette.gray.hoverNavbarItem,
        },
      })}
      direction="row"
      alignItems={"center"}
      onClick={() => navigate(routeTo ?? baseRoute)}
    >
      {selectedIcon && unselectedIcon && (
        <Stack
          mr="16px"
          height="26px"
          width="26px"
          sx={{ position: "relative" }}
        >
          <Stack
            component="img"
            src={selected ? selectedIcon : unselectedIcon}
          />
          {notification && (
            <Stack
              width="9px"
              height="9px"
              sx={(theme) => ({
                borderRadius: "100px",
                backgroundColor: theme.palette.red.main,
                position: "absolute",
                top: 1,
                left: -2,
              })}
            />
          )}
        </Stack>
      )}
      <Stack justifyContent="end" height="100%" minHeight="26px">
        <Typography
          variant="h5"
          component={"div"}
          sx={{
            position: "relative",
          }}
          color={selected ? "primary.main" : "gray.dark"}
        >
          {label}
          {notification && !selectedIcon && !unselectedIcon && (
            <Stack
              width="9px"
              height={"9px"}
              sx={(theme) => ({
                borderRadius: "100px",
                backgroundColor: theme.palette.red.main,
                position: "absolute",
                top: 1,
                left: -10,
              })}
            />
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};

export function MobileNavbarItem(props: NavBarItemProps) {
  const {
    selectedIcon,
    unselectedIcon,
    label,
    baseRoute,
    routeTo,
    mobileRouteTo,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const routes = [{ path: baseRoute }];
  const matches = matchRoutes(routes, { pathname: location.pathname });

  const selected = !!matches?.length;
  return (
    <Stack
      alignItems="center"
      onClick={() => navigate(mobileRouteTo ?? routeTo ?? baseRoute)}
    >
      <Stack
        component="img"
        sx={{
          width: "26px",
          height: "26px",
          pb: "4px",
        }}
        src={selected ? selectedIcon : unselectedIcon}
      />
      <Typography
        component="div"
        sx={{
          fontSize: "10px",
          fontWeight: "400",
        }}
        color={(theme) =>
          selected ? theme.palette.primary.main : theme.palette.gray.medium
        }
      >
        {label}
      </Typography>
    </Stack>
  );
}
