import { Typography, alpha } from "@mui/material";
import { Stack } from "@mui/material";

export function Tab<T extends string>({
  currentTab,
  tab,
  label,
  onClick,
  mr,
}: {
  label: string;
  mr?: string;
  tab: T;
  currentTab: T;
  onClick: () => void;
}) {
  return (
    <Stack
      maxWidth="166px"
      flex={1}
      sx={(theme) => {
        if (tab === currentTab) {
          return {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderRadius: "50px",
            mr,
            cursor: "pointer",
          };
        }
        return {
          borderRadius: "50px",
          mr,
          cursor: "pointer",
          backgroundColor: undefined,
          color: theme.palette.gray.dark,
          border: `2px solid ${theme.palette.gray.main}`,
          "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
          },
        };
      }}
      direction="row"
      alignItems="center"
      justifyContent="center"
      onClick={() => {
        if (tab === currentTab) {
          return;
        }
        onClick();
      }}
    >
      <Typography component="div" py="16px">
        {label}
      </Typography>
    </Stack>
  );
}
