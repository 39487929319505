import { graphql } from "gql-gen";

export const getOrdersPaginatedViaAdmin = graphql(`
  query GetOrdersPaginatedViaAdmin(
    $filter: OrderFilterInput
    $pagination: PaginationInput
  ) {
    admin {
      id
      orders(filter: $filter, pagination: $pagination) {
        totalCount
        orders {
          id
          patient {
            ...UserWithProfilePic
            email
          }
          intakeResponse {
            id
          }
          isRefill
          createdAt
          state
          product {
            id
            name
          }
        }
        pageInfo {
          id: currentPage
          currentPage
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`);

export const getActiveSubscriptionsPaginatedViaAdmin = graphql(`
  query GetActiveSubscriptionsPaginatedViaAdmin(
    $patientId: Int
    $states: [SubscriptionState!]
  ) {
    admin {
      id
      subscriptions(patientId: $patientId, states: $states) {
        subscriptions {
          id
          state
          nextRefillAt
          billingFrequencyInWeeks
          priceInCents
          patientId
          patient {
            id
            firstName
            lastName
            email
          }
          product {
            id
            name
          }
          cancelledAt
          pausedAt
        }
      }
    }
  }
`);

export const getOrderDetailsViaAdmin = graphql(`
  query GetOrderDetailsViaAdmin($orderId: Int!) {
    admin {
      id
      order(id: $orderId) {
        id
        patient {
          ...UserWithProfilePic
          email
        }
        internalNotes
        billingPeriod
        trackingUrl
        isRefill
        createdAt
        state
        product {
          id
          name
        }
      }
    }
  }
`);

export const getSubscriptionDetailsViaAdmin = graphql(`
  query GetSubscriptionDetailsViaAdmin($subscriptionId: Int!) {
    admin {
      id
      subscription(id: $subscriptionId) {
        id
        state
        nextRefillAt
        billingFrequencyInWeeks
        priceInCents
        patientId
        patient {
          id
          lastEmailNotificationDate
          firstName
          lastName
          email
          state
        }
        product {
          id
          name
        }
        cancelledAt
        pausedAt
      }
    }
  }
`);

export const getLastOrderForUser = graphql(`
  query GetLastOrderForUser($patientId: Int!, $productId: Int!) {
    admin {
      id
      lastOrderForUser(patientId: $patientId, productId: $productId) {
        id
        state
        paymentInfoId
        trackingUrl
        product {
          id
          name
        }
      }
    }
  }
`);

export const updateOrderDetailsViaAdmin = graphql(`
  mutation UpdateOrderDetailsViaAdmin(
    $orderId: Int!
    $patientId: Int!
    $newState: OrderState!
    $billingPeriod: OrderBillingPeriod!
    $trackingUrl: String
    $internalNotes: String
  ) {
    updateOrder(
      orderId: $orderId
      patientId: $patientId
      newState: $newState
      billingPeriod: $billingPeriod
      trackingUrl: $trackingUrl
      internalNotes: $internalNotes
    )
  }
`);

export const sendPrescriptionToFoothills = graphql(`
  mutation SendPrescriptionToFoothills(
    $prescriptionIds: [Int!]!
    $isRefill: Boolean
    $orderId: Int
  ) {
    sendPrescriptionFoothills(
      prescriptionIds: $prescriptionIds
      isRefill: $isRefill
      orderId: $orderId
    )
  }
`);

export const createPendingPrescriptionOrderForRefillOrder = graphql(`
  mutation CreatePendingPrescriptionOrderForRefillOrder(
    $subscriptionId: Int!
    $paymentInfoId: Int!
    $internalNotes: String
    $quantityOverride: OrderBillingPeriod
    $priceOverrideInCents: Int
  ) {
    createPendingPrescriptionOrderForRefillOrder(
      subscriptionId: $subscriptionId
      paymentInfoId: $paymentInfoId
      internalNotes: $internalNotes
      quantityOverride: $quantityOverride
      priceOverrideInCents: $priceOverrideInCents
    )
  }
`);

export const createRefillOrderForUserBasedOnPrescription = graphql(`
  mutation CreateRefillOrderForUserBasedOnPrescription(
    $subscriptionId: Int!
    $paymentInfoId: Int!
    $prescriptionId: Int!
  ) {
    createRefillOrderForUserBasedOnPrescription(
      subscriptionId: $subscriptionId
      paymentInfoId: $paymentInfoId
      prescriptionId: $prescriptionId
    )
  }
`);

export const sendEmailRefillNotification = graphql(`
  mutation SendEmailRefillNotification($patientId: Int!) {
    sendEmailRefillNotification(patientId: $patientId)
  }
`);

export const getIntakeResponse = graphql(`
  query GetIntakeResponseViaAdmin($intakeResponseId: Int!) {
    admin {
      id
      intakeResponse(id: $intakeResponseId) {
        id
        prescriberNotes
        responses {
          id
          value
          question {
            id
            prompt
            severity
          }
          prescriberNotes
          selectedOptions {
            id
            value
            presignedUrl
            option {
              id
              label
              dataType
              severity
            }
          }
        }
      }
    }
  }
`);

export const getOrderMedicationsOptionsViaAdmin = graphql(`
  query GetOrderMedicationsOptionsViaAdmin($orderId: Int!) {
    admin {
      id
      order(id: $orderId) {
        id
        patientId
        state
        billingPeriod
        internalNotes
        product {
          name
          medicationOptions {
            id
            indication
            formulaFormat
            formulaIngredients
            defaultDirections
            quantity
            form
            units
            completeName
            defaultMessageToSendToUser
          }
          medicationBundleOptions {
            id
            indication
            formulaFormat
            formulaIngredients
            defaultDirections
            quantity
            form
            units
            completeName
            defaultMessageToSendToUser
          }
        }
      }
    }
  }
`);

export const getAllPrescriptionsForPatient = graphql(`
  query GetAllPrescriptionsForPatient($patientId: Int!) {
    admin {
      id
      allPrescriptionsForPatient(patientId: $patientId) {
        id
        order {
          id
          isRefill
          patient {
            ...UserWithProfilePic
            email
          }
          intakeResponse {
            id
          }
          createdAt
          state
          product {
            id
            name
          }
        }
      }
    }
  }
`);

export const getPrescriptionsAbleToSendViaAdmin = graphql(`
  query GetPrescriptionsAbleToSendViaAdmin($patientId: Int!) {
    admin {
      id
      allPrescriptionsForPatient(patientId: $patientId) {
        id
        medication {
          id
          completeName
          quantity
          units
        }
        order {
          id
        }
        refills
      }
    }
  }
`);

export const updateSubscriptionRefillDateViaAdmin = graphql(`
  mutation UpdateSubscriptionRefillDateViaAdmin(
    $subscriptionId: Int!
    $refillDate: String!
    $subscriptionState: SubscriptionState!
  ) {
    updateSubscriptionRefillDateViaAdmin(
      subscriptionId: $subscriptionId
      refillDate: $refillDate
      subscriptionState: $subscriptionState
    )
  }
`);

export const getPrescriptionForOrderViaAdmin = graphql(`
  query GetPrescriptionForOrderViaAdmin($orderId: Int!) {
    admin {
      id
      order(id: $orderId) {
        id
        prescriptions {
          id
          refills
          directions
          allergies
          docErxSignatureAt
          currentMedications
          medication {
            id
            indication
            formulaFormat
            formulaIngredients
            defaultDirections
            quantity
            form
            units
            completeName
            defaultMessageToSendToUser
          }
        }
      }
    }
  }
`);

export const writePrescriptionViaAdmin = graphql(`
  mutation WritePrescriptionViaAdmin(
    $patientId: Int!
    $orderId: Int!
    $refills: Int!
    $allergies: String!
    $currentMedications: String!
    $medications: [MedicationInput!]!
    $messageToSendToUser: String!
  ) {
    writePrescription(
      patientId: $patientId
      orderId: $orderId
      refills: $refills
      allergies: $allergies
      currentMedications: $currentMedications
      medications: $medications
      messageToSendToUser: $messageToSendToUser
    )
  }
`);

export const addPrescriberNoteToQuestion = graphql(`
  mutation AddPrescriberNoteToQuestion(
    $intakeQuestionResponseId: Int!
    $note: String!
  ) {
    addPrescriberNoteToQuestion(
      intakeQuestionResponseId: $intakeQuestionResponseId
      note: $note
    )
  }
`);

export const addPrescriberNoteToIntakeResponse = graphql(`
  mutation AddPrescriberNoteToIntakeResponse(
    $intakeResponseId: Int!
    $note: String!
  ) {
    addPrescriberNoteToIntakeResponse(
      intakeResponseId: $intakeResponseId
      note: $note
    )
  }
`);

export const getPatientDetailsViaAdmin = graphql(`
  query GetPatientDetailsViaAdmin($patientId: Int!) {
    admin {
      id
      patientDetails(id: $patientId) {
        ...UserWithProfilePic
        idVerified
        email
        gender
        state
        dateOfBirth
        supportThread {
          id
        }
        medicalThread {
          id
        }
      }
    }
  }
`);

export const getAllPatients = graphql(`
  query GetAllPatients($page: Int, $pageSize: Int, $search: String) {
    admin {
      id
      patients(page: $page, pageSize: $pageSize, search: $search) {
        patients {
          ...UserWithProfilePic
          idVerified
          gender
          dateOfBirth
        }
        totalCount
      }
    }
  }
`);

export const uploadFoothillsOrders = graphql(`
  mutation UploadFoothillsOrders($ordersCSV: File!) {
    ok: uploadOrderFiles(ordersCSV: $ordersCSV)
  }
`);

export const getMessagesForThreadViaAdmin = graphql(`
  query GetMessagesForThreadViaAdmin($threadId: Int!) {
    admin {
      id
      messagesForThread(threadId: $threadId) {
        ...MessageWithUserProfilePic
      }
    }
  }
`);

export const getPatientPhotosViaAdmin = graphql(`
  query GetPatientPhotosViaAdmin($patientId: Int!) {
    admin {
      id
      patientDetails(id: $patientId) {
        id
        dateOfBirth
        userSelfiePhoto {
          id
          s3Key
          presignedUrl
          filename
        }
        userIdPhoto {
          id
          s3Key
          presignedUrl
          filename
        }
      }
    }
  }
`);

export const getPatientIntakesViaOrders = graphql(`
  query GetPatientIntakesViaOrders($patientId: Int!) {
    admin {
      id
      patientDetails(id: $patientId) {
        id
      }
    }
  }
`);

export const verifyPatientId = graphql(`
  mutation VerifiyPatientId($patientId: Int!, $verified: Boolean!) {
    verifyPatientId(patientId: $patientId, verified: $verified)
  }
`);

export const getPatientSupportThreadsForAdmin = graphql(`
  query GetPatientSupportThreadsForAdmin($showAll: Boolean!) {
    admin {
      id
      threads: supportThreads(all: $showAll) {
        id
        patient {
          id
          email
          firstName
          lastName
        }
        latestMessageTimestamp
      }
    }
  }
`);

export const getPatientMedicalThreadsForAdmin = graphql(`
  query GetPatientMedicalThreadsForAdmin($showAll: Boolean!) {
    admin {
      id
      threads: medicalThreads(all: $showAll) {
        id
        patient {
          id
          email
          firstName
          lastName
        }
        latestMessageTimestamp
      }
    }
  }
`);

export const unrejectOrderViaAdmin = graphql(`
  mutation UnrejectOrderViaAdmin($orderId: Int!) {
    unrejectOrder(orderId: $orderId)
  }
`);

export const rejectOrderViaAdmin = graphql(`
  mutation RejectOrderViaAdmin($orderId: Int!) {
    rejectOrder(orderId: $orderId)
  }
`);
