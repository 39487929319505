import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  Typography,
  useMediaQuery,
  Button,
  Input,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Stack } from "@mui/material";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";
import { useMutation, useQuery } from "@apollo/client";
import { getMyPaymentMethods } from "gql/User.gql";
import Loading from "components/Loading";
import { Card, CardNmi } from "pages/intake/Checkout";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import CardsList from "assets/imgs/credit_cards/card-list.png";
import { useAuth } from "contexts/AuthContext";
import { addPaymentMethodToCurrentUser } from "gql/User.gql";
import { GetCurrentUserShippingAddressQueryQuery } from "gql-gen/graphql";
import { stateAcronyms } from "utils/constants";

const productionAppId = "sq0idp-4r-tbsouPE-cZmmmQAqWoQ";
const locationId = {
  CA: "L2G9X8JDXFE6G",
  FL: "L2G9X8JDXFE6G",
  TX: "L2G9X8JDXFE6G",
};

type ShippingAddress =
  GetCurrentUserShippingAddressQueryQuery["me"]["shippingAddress"];

export default function PaymentMethod() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { setHorizontalTopValues } = useHorizontalTopHeader();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [cardError, setCardError] = useState<any>(false); // Adjust the type as needed
  const [cardSuccess, setCardSuccess] = useState<any>(false); // Adjust the type as needed
  const [hasLoadedScript, setHasLoadedScript] = useState(false);
  const [processingTransaction, setProcessingTransaction] = useState(false);

  const [billingAddress, setBillingAddress] = useState<
    Partial<ShippingAddress> | undefined
  >(undefined);

  const [cardHolderFirstName, setCardHolderFirstName] = useState<
    string | undefined
  >(undefined);
  const [cardHolderLastName, setCardHolderLastName] = useState<
    string | undefined
  >(undefined);
  const hasAuthorizedTransaction = useRef(false);

  const state = currentUser?.state;
  useEffect(() => {
    if (!state) {
      return;
    }
    const script = document.createElement("script");

    script.src = "https://greenpay.transactiongateway.com/token/Collect.js";
    script.async = true;
    script.setAttribute(
      "data-tokenization-key",
      state === "CA"
        ? "wdGHPD-7Dw6NY-NKez5k-w9Q6qM"
        : "fWCpUr-cHgt6d-uR7gaf-USr4fB"
    );

    // script.src = "https://usapayments.transactiongateway.com/token/Collect.js";
    // script.async = true;
    // script.setAttribute(
    //   "data-tokenization-key",
    //   "checkout_public_46MUyGqU8XF5CPq585458Q4tAv8euE4Q"
    // );

    document.body.appendChild(script);

    script.onload = () => {
      setHasLoadedScript(true);
    };

    // Optional: Clean up
    return () => {
      document.body.removeChild(script);
    };
  }, [state, setHasLoadedScript]); // Empty array ensures this effect runs once on mount only

  useEffect(() => {
    if (!state) {
      return;
    }
    const intializeUSAPayment = async () => {
      (window as any).CollectJS.configure({
        paymentSelector: "#demoPayButton",
        variant: "inline",
        styleSniffer: "true",
        customCss: {
          border: "none",
          "padding-right": "16px",
          "padding-left": "16px",
          "padding-top": "18px",
          "padding-bottom": "18px",
          "line-height": "1.4375em",
          font: "inherit",
          height: "54px",
          "font-size": "18px",
          "font-weight": "400",
        },
        invalidCss: {
          color: "#9D0000",
          "background-color": "rgba(219, 177, 188, 0.60)",
        },
        validCss: {
          color: "black",
        },
        placeholderCss: {
          color: "#7B7B7B",
        },
        focusCss: {
          color: "black",
          border: "none !important",
        },
        fields: {
          ccnumber: {
            selector: "#ccnumber",
            title: "Card Number",
            placeholder: "Card Number",
          },
          ccexp: {
            selector: "#ccexp",
            title: "Card Expiration",
            placeholder: "MM/YY",
          },
          cvv: {
            display: "show",
            selector: "#cvv",
            title: "CVV Code",
            placeholder: "CVV",
          },
        },
        currency: "USD",
        country: "US",

        timeoutDuration: 10000,
        timeoutCallback: function () {
          setCardError("Error authorizing transaction");
          setProcessingTransaction(false);
          // console.log(
          //   "The tokenization didn't respond in the expected timeframe.  This could be due to an invalid or incomplete field or poor connectivity"
          // );
        },
        fieldsAvailableCallback: function () {
          // console.log("Collect.js loaded the fields onto the form");
        },
        callback: function (response: { token?: string }) {
          if (!response.token) {
            setCardError("Error authorizing transaction");
            setProcessingTransaction(false);
            return;
          }
          setPaymentToken(response.token);
        },
      });
    };
    if (hasLoadedScript) {
      intializeUSAPayment();
    }
  }, [currentUser, state, hasLoadedScript]);

  useEffect(() => {
    if (isMobile) {
      setHorizontalTopValues({
        customHeaderTitle: "Payment",
        onClickBack: () => navigate("/dashboard/patient/account/mobile-nav"),
        bottomPercentageCompletion: undefined,
        showOnDesktop: false,
        showBorderBoxShadow: true,
        hideMobileToolbar: false,
      });
    }
  }, [isMobile, setHorizontalTopValues, navigate]);

  const { data, loading } = useQuery(getMyPaymentMethods, {
    fetchPolicy: "cache-and-network",
  });

  const [addPaymentMethodToCurrentUserMutation] = useMutation(
    addPaymentMethodToCurrentUser
  );

  const [paymentToken, setPaymentToken] = useState<string | undefined>();
  const [existingPaymentMethod, setExistingPaymentMethod] = useState<
    number | undefined
  >();
  const [card, setCard] = useState<any>(null); // Adjust the type as needed

  const billAddressString = JSON.stringify(billingAddress ?? {});

  useEffect(() => {
    const authTransaction = async () => {
      const billingAddressParsed = JSON.parse(billAddressString);
      if (
        !hasAuthorizedTransaction.current &&
        cardHolderFirstName &&
        cardHolderLastName &&
        billingAddressParsed &&
        paymentToken
      ) {
        const result = await addPaymentMethodToCurrentUserMutation({
          variables: {
            paymentToken,
            cardholderFirstName: cardHolderFirstName,
            cardholderLastName: cardHolderLastName,
            billingAddress: {
              address: billingAddressParsed.address,
              zipCode: billingAddressParsed.zipCode,
              city: billingAddressParsed.city,
              state: billingAddressParsed.state,
              aptSuite: billingAddressParsed.aptSuite,
            },
          },
        });
        setProcessingTransaction(false);
        if (result.data?.addPaymentInfo) {
          hasAuthorizedTransaction.current = true;
          setCardSuccess(true);
          setCardError(false);
        } else {
          setCardSuccess(false);
          setCardError("Error when validating credit card.");
        }
      }
    };
    authTransaction();
  }, [
    paymentToken,
    cardHolderFirstName,
    cardHolderLastName,
    billAddressString,
    setProcessingTransaction,
    addPaymentMethodToCurrentUserMutation,
  ]);

  const enabledSubmission =
    billingAddress?.address &&
    billingAddress.state &&
    billingAddress.zipCode &&
    billingAddress.city &&
    cardHolderFirstName &&
    cardHolderLastName;

  return (
    <Stack
      alignItems="start"
      justifyContent="flex-start"
      width={!isMobile ? "100%" : undefined}
      p={isMobile ? "32px" : "64px"}
      sx={(theme) => ({
        backgroundColor: theme.palette.neutral["200"],
        overflowY: "scroll",
      })}
    >
      <Stack maxWidth="320px" minWidth="250px" direction="row" width="100%">
        <Stack direction="column" width="100%">
          {!isMobile && (
            <Typography variant="h2" mb={"32px"} color="primary.main">
              Payment method
            </Typography>
          )}
          {loading && <Loading />}
          {data?.me.paymentMethods.map((f) => {
            if (f.ccExp && f.ccType && f.ccNumber) {
              return (
                <CardNmi
                  ccExp={f.ccExp}
                  ccType={f.ccType}
                  ccNumber={f.ccNumber}
                />
              );
            }
            if (!f.squareCardInfo) {
              return;
            }
            return <Card card={f.squareCardInfo} />;
          })}
          {(data?.me.paymentMethods.length ?? 0) > 0 && (
            <Typography
              variant="caption"
              mt={"32px"}
              fontSize={"14px"}
              color="primary.main"
            >
              To add or delete a card <br />
              <Link
                component={RouterLink}
                sx={{
                  ml: "2px",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
                to={`/dashboard/patient/messages/support`}
              >
                please contact support
              </Link>
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        maxWidth="320px"
        minWidth="250px"
        alignItems="flex-start"
        direction="column"
        mt={"32px"}
      >
        <Typography variant="h4" sx={{ mt: "12px" }}>
          Add a new card
        </Typography>
        <Stack alignItems="flex-start" direction="column" mt={"8px"} mb="32px">
          <Stack mb={"16px"} width="100%">
            <Typography
              variant="caption"
              sx={{
                mb: "12px",
              }}
              color="primary.main"
            >
              Cardholder Name
            </Typography>
            <Stack flexDirection={"row"}>
              <Input
                sx={(theme) => ({
                  "& input": {
                    color: theme.palette.primary.main,
                  },
                  mr: "16px",
                  backgroundColor: theme.palette.primary.contrastText,
                })}
                placeholder="First name"
                multiline={false}
                value={cardHolderFirstName ?? ""}
                onChange={(e) => {
                  e.preventDefault();
                  setCardHolderFirstName(e.target.value);
                }}
              />
              <Input
                sx={(theme) => ({
                  "& input": {
                    color: theme.palette.primary.main,
                  },
                  backgroundColor: theme.palette.primary.contrastText,
                })}
                multiline={false}
                placeholder="Last name"
                value={cardHolderLastName ?? ""}
                onChange={(e) => {
                  e.preventDefault();
                  setCardHolderLastName(e.target.value);
                }}
              />
            </Stack>
          </Stack>
          <Stack
            sx={{ mt: "8px" }}
            alignItems={"self-start"}
            justifyContent={"center"}
          >
            <Stack
              flexDirection={"row"}
              alignItems={"self-start"}
              justifyContent={"center"}
              pl={"16px"}
            >
              <Stack
                sx={{
                  height: "17px",
                  width: "106px",
                  mb: "8px",
                }}
                src={CardsList}
                component={"img"}
              ></Stack>
            </Stack>
            <Stack
              flexDirection="row"
              sx={{
                border: "2px solid #DDD",
                borderRadius: "0.5rem",
                overflow: "hidden",
                "&:hover": {
                  border: "2px solid black",
                },
              }}
            >
              <Stack flexDirection="row" flex={1}>
                <div id="ccnumber" />
              </Stack>
              <Stack flexDirection="row" flex={1}>
                <div id="ccexp" />
                <div id="cvv" />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            alignItems="flex-start"
            direction="column"
            mt={"24px"}
            width={"100%"}
          >
            <Typography variant="h4" color={"primary.main"} mb={"16px"}>
              Billing address
            </Typography>
            <Stack mb={"16px"} width="100%">
              <Typography
                variant="caption"
                sx={{
                  mb: "8px",
                }}
                color="primary.main"
              >
                Address
              </Typography>
              <Input
                sx={(theme) => ({
                  "& input": {
                    color: theme.palette.primary.main,
                  },
                  backgroundColor: theme.palette.primary.contrastText,
                })}
                multiline={false}
                value={billingAddress?.address ?? ""}
                onChange={(e) => {
                  setBillingAddress((s) => ({
                    ...s,
                    address: e.target.value,
                  }));
                }}
              />
            </Stack>
            <Stack mb={"16px"} width="100%">
              <Typography
                variant="caption"
                sx={{
                  mb: "8px",
                }}
                color="primary.main"
              >
                Apartment, suite, etc (optional)
              </Typography>
              <Input
                sx={(theme) => ({
                  "& input": {
                    color: theme.palette.primary.main,
                  },
                  backgroundColor: theme.palette.primary.contrastText,
                })}
                multiline={false}
                value={billingAddress?.aptSuite ?? ""}
                onChange={(e) => {
                  setBillingAddress((s) => ({
                    ...s,
                    aptSuite: e.target.value,
                  }));
                }}
              />
            </Stack>
            <Stack
              mb={"16px"}
              direction="row"
              width="100%"
              justifyContent={"space-between"}
            >
              <Stack flex={1} mr="8px">
                <Typography
                  variant="caption"
                  sx={{
                    mb: "8px",
                  }}
                  color="primary.main"
                >
                  City
                </Typography>
                <Input
                  sx={(theme) => ({
                    "& input": {
                      color: theme.palette.primary.main,
                    },
                    backgroundColor: theme.palette.primary.contrastText,
                  })}
                  multiline={false}
                  value={billingAddress?.city ?? ""}
                  onChange={(e) => {
                    setBillingAddress((s) => ({
                      ...s,
                      city: e.target.value,
                    }));
                  }}
                />
              </Stack>
              <Stack flex={1}>
                <Typography
                  variant="caption"
                  sx={{
                    mb: "8px",
                  }}
                  color="primary.main"
                >
                  State
                </Typography>
                <Select
                  value={billingAddress?.state ?? ""}
                  onChange={(e) => {
                    setBillingAddress((s) => ({
                      ...s,
                      state: e.target.value,
                    }));
                  }}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.primary.contrastText,
                  })}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {stateAcronyms.map((state) => {
                    return (
                      <MenuItem
                        value={state}
                        key={state}
                        sx={(theme) => ({
                          backgroundColor: theme.palette.primary.contrastText,
                          height: "48px",
                          "&.Mui-selected": {
                            backgroundColor: theme.palette.purple["100"],
                          },
                          "&:hover": {
                            backgroundColor: theme.palette.purple["200"],
                          },
                        })}
                      >
                        <Typography variant="caption" color="primary.main">
                          {state}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Stack>
            <Stack width="136px">
              <Typography
                variant="caption"
                sx={{
                  mb: "8px",
                }}
                color="primary.main"
              >
                Zip code
              </Typography>
              <Input
                sx={(theme) => ({
                  "& input": {
                    color: theme.palette.primary.main,
                  },
                  backgroundColor: theme.palette.primary.contrastText,
                })}
                type="text"
                multiline={false}
                value={billingAddress?.zipCode ?? ""}
                onChange={(e) => {
                  setBillingAddress((s) => ({
                    ...s,
                    zipCode: e.target.value,
                  }));
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {cardSuccess && (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={(theme) => ({
            height: "40px",
            borderRadius: "10px",
            p: "12px",
            mb: "16px",
            mt: "16px",
            backgroundColor: theme.palette.green["100"],
          })}
        >
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
            }}
          >
            Successfully, Added Payment Method
          </Typography>
        </Stack>
      )}
      {cardError && (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={(theme) => ({
            height: "40px",
            borderRadius: "10px",
            p: "12px",
            mb: "16px",
            mt: "16px",
            backgroundColor: theme.palette.pink.transparent80,
          })}
        >
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
            }}
          >
            Error validating payment
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
            }}
          >
            Please try again or add a new card
          </Typography>
        </Stack>
      )}
      {processingTransaction ? (
        <Stack alignItems="center" mt={"32px"}>
          <CircularProgress color="primary" />
        </Stack>
      ) : (
        <Button
          size="large"
          sx={(theme) => ({
            backgroundColor: enabledSubmission
              ? theme.palette.primary.main
              : theme.palette.gray.main,
            mb: "32px",
          })}
          onClick={() => {
            const response = (window as any).CollectJS.startPaymentRequest();
            console.log({ response });
            setProcessingTransaction(true);
          }}
          disabled={!enabledSubmission}
        >
          <Typography
            variant="body1"
            component="div"
            color="primary.contrastText"
            sx={{
              "&&": {
                fontSize: 16,
              },
            }}
          >
            {"Add payment method"}
          </Typography>
        </Button>
      )}
    </Stack>
  );
}
