import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  useRoutes,
  Outlet,
  useLocation,
} from "react-router-dom";
import { CheckAuthComponent } from "components/CheckAuthComponent";
import { AuthProvider, useAuth } from "contexts/AuthContext";
import { ApolloProvider } from "contexts/ApolloProvider";
import { muiTheme } from "theme";
import { Stack, ThemeProvider } from "@mui/system";
import { Button, Fade, Typography, useMediaQuery } from "@mui/material";
import Welcome from "pages/intake/Welcome";
import IntakeQuestions from "pages/intake/IntakeQuestions";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { matchPath, matchRoutes, useMatch, useNavigate } from "react-router";

import { useQuery } from "@apollo/client";
import { getUserHasUnreadMessages } from "gql/Messages.gql";
import { charlyAxios } from "utils/api";
import {
  HorizontalTopHeaderProvider,
  useHorizontalTopHeader,
} from "contexts/HorizontalTopHeaderContext";
import LeftArrowGreen from "assets/icons/left-arrow-black.svg";
import MobileAccountNav from "pages/account/MobileAccountNav";
import MobileMessagesNav from "pages/messages/MobileMessagesNav";
import {
  AccountRouteToData,
  AdminAccountRouteToData,
  MessagesRouteToData,
  NavBarItemProps,
  TopLevelRouteToData,
} from "./NavbarRoutes";
import { MobileNavbarItem, NavbarItem } from "./NavbarItems";

export const AdminLeftNavbar = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const location = useLocation();
  // const selected = route && !!matchPath(location.pathname, route);
  const auth = useAuth();
  const navigate = useNavigate();
  const { data } = useQuery(getUserHasUnreadMessages, {
    fetchPolicy: "cache-and-network",
  });
  const handleLogout = async () => {
    try {
      await charlyAxios.get("/api/auth/logout");
      auth.logout();
      navigate("/login");
    } catch (error) {
      // Handle error during logout
    }
  };
  return (
    <Stack direction="row" flexGrow={1} minHeight={0}>
      <Stack
        component="div"
        display="flex"
        direction="column"
        alignItems="flex-start"
        flexShrink={0}
        justifyContent="space-between"
        minWidth={"250px"}
        sx={(theme) => ({
          zIndex: 3,
          background: theme.palette.neutral["100"],
          boxShadow: "4px 0px 4px 0px rgba(217, 217, 217, 0.40)",
          py: "24px",
        })}
      >
        <Stack>
          <Typography
            variant="h1"
            component="div"
            sx={{
              pb: "17px",
              pl: "41px",
            }}
            color={(theme) => theme.palette.primary.main}
          >
            Oana
          </Typography>
          <NavbarItem {...TopLevelRouteToData.adminAllOrders} />
          {/* <NavbarItem
            {...TopLevelRouteToData.adminMessagesQueue}
            notification={!!data?.me.hasUnreadMessages}
          /> */}
          <NavbarItem {...TopLevelRouteToData.adminIntakeQueue} />
          <NavbarItem {...TopLevelRouteToData.adminAllPatients} />
          <NavbarItem {...TopLevelRouteToData.adminAccount} />
          <NavbarItem
            {...TopLevelRouteToData.adminUploadOrder}
            unselectedIcon=""
            selectedIcon=""
          />
          <NavbarItem
            {...TopLevelRouteToData.adminActiveSubscriptions}
            unselectedIcon=""
            selectedIcon=""
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Button onClick={handleLogout}>Logout</Button>
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
};

export const AdminAccountLeftNavbar = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Stack flexGrow={1}>
      <Stack direction="row" flexGrow={1} height={0}>
        {!isMobile && (
          <Stack
            component="div"
            display="flex"
            direction="column"
            alignItems="flex-start"
            minWidth={"250px"}
            sx={(theme) => ({
              zIndex: 2,
              background: theme.palette.neutral["100"],
              boxShadow: "4px 0px 4px 0px rgba(217, 217, 217, 0.40)",
              py: "24px",
            })}
          >
            <Stack>
              <NavbarItem {...AdminAccountRouteToData.profile} />
              <NavbarItem {...AdminAccountRouteToData.passwordReset} />
              <NavbarItem {...AdminAccountRouteToData.address} />
              <NavbarItem {...AdminAccountRouteToData.licenses} />
            </Stack>
          </Stack>
        )}
        <Stack
          alignItems="start"
          justifyContent="flex-start"
          flexGrow={1}
          sx={(theme) => ({
            backgroundColor: theme.palette.neutral["200"],
            overflowY: isMobile ? undefined : "scroll",
          })}
          // direction="row"
        >
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};
