import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";
import { useQuery, useMutation } from "@apollo/client";
import {
  getCurrentUserQuery,
  getOrdersForHomeViewForCurrentUser,
  updateUserFields,
} from "gql/User.gql";
import { useAuth } from "contexts/AuthContext";
import {
  Address,
  GenderEnum,
  OrderState,
  GetOrdersForHomeViewForCurrentUserQuery,
} from "gql-gen/graphql";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";
import ProductTile from "components/ProductTile";
import CircleCheckboxGray from "assets/icons/circle-check-gray.svg";
import { OrderStateToTimeLineData } from "./orders/Orders";
import Loading from "components/Loading";
import { allowedStates } from "./intake/Welcome";
import { dateToFormattedString } from "utils/date";

function OrderInProgessTile({
  order,
}: {
  order: GetOrdersForHomeViewForCurrentUserQuery["me"]["ordersBeingProcessed"][number];
}) {
  const navigate = useNavigate();
  return (
    <Stack
      sx={(theme) => ({
        maxWidth: "326px",
        minWidth: "250px",
        maxHeight: "300px",
        backgroundColor: theme.palette.primary.contrastText,
        borderRadius: "10px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
      })}
    >
      <Stack
        alignItems="start"
        justifyContent="center"
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.gray.main}`,
        })}
      >
        <ProductTile
          product={{
            ...order.product,
          }}
          billingPeriod={order.billingPeriod as any}
          containerSx={{ m: "16px" }}
        />
      </Stack>
      <Stack p={"16px"} direction="row" alignItems="center">
        <Stack component="img" sx={{ mr: "16px" }} src={CircleCheckboxGray} />
        <Typography variant="body2" color="primary.main">
          {order.state === OrderState.SHIPPED_TO_PATIENT ? (
            <Stack>
              Shipped on{" "}
              {dateToFormattedString(
                new Date(parseInt(order.shippedAt ?? `${new Date().getTime()}`))
              )}
              <Link
                target="_blank"
                rel="noopener"
                variant="body2Bold"
                sx={{
                  cursor: "pointer",
                }}
                href={order.trackingUrl ?? ""}
              >
                Tracking URL
              </Link>
            </Stack>
          ) : (
            OrderStateToTimeLineData[order.state].captionIfCurrentState
          )}
        </Typography>
      </Stack>
      <Stack px={"16px"} pb={"16px"} direction="row">
        <Button
          size="large"
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.main,
            flexGrow: 1,
          })}
          onClick={async () => {
            navigate(
              `/dashboard/patient/orders${
                order.state === OrderState.SHIPPED_TO_PATIENT ? "?tab=past" : ""
              }`
            );
          }}
        >
          <Typography
            variant="body1"
            component="div"
            color="primary.contrastText"
            sx={{
              "&&": {
                fontSize: 16,
              },
            }}
          >
            See order details
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );
}

function CompleteYourVisitTile({
  order,
}: {
  order: GetOrdersForHomeViewForCurrentUserQuery["me"]["ordersInProgress"][number];
}) {
  const navigate = useNavigate();

  console.log({ order });
  return (
    <Stack
      sx={(theme) => ({
        maxWidth: "326px",
        minWidth: "250px",
        maxHeight: "336px",
        backgroundColor: theme.palette.primary.contrastText,
        borderRadius: "10px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
      })}
    >
      <Stack
        alignItems="start"
        justifyContent="center"
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.gray.main}`,
        })}
      >
        <ProductTile
          product={{
            ...order.product,
          }}
          billingPeriod={order.billingPeriod as any}
          containerSx={{ m: "16px" }}
        />
      </Stack>
      <Stack p={"16px"} direction="row" alignItems="center">
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
          }}
          color="primary.main"
        >
          Progress
        </Typography>
      </Stack>
      <Stack
        px={"16px"}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body2" color="primary.main">
          {order.state === OrderState.PENDING_INTAKE_COMPLETION &&
            "Missing medical intake information"}
          {order.state === OrderState.MISSING_ID_VERIFICATION &&
            "Please upload a photo ID while our medical team evaluates your request"}
          {order.state === OrderState.MISSING_PAYMENT_INFO &&
            "Missing payment information"}
        </Typography>
      </Stack>
      <Stack p="16px" direction="row">
        <Button
          size="large"
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.main,
            flexGrow: 1,
          })}
          onClick={() => {
            if (
              order.intakeResponse?.completedAt &&
              order.state === OrderState.MISSING_ID_VERIFICATION
            ) {
              navigate("/id-verification");
            } else {
              navigate(
                `/intake/${order.product?.productEnum.toLowerCase()}/qs`
              );
            }
          }}
        >
          <Typography
            variant="body1"
            component="div"
            color="primary.contrastText"
            sx={{
              "&&": {
                fontSize: 16,
              },
            }}
          >
            Complete visit
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );
}

function HomeView() {
  const { data, loading, error } = useQuery(
    getOrdersForHomeViewForCurrentUser,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const { currentUser } = useAuth();

  const [userState, setUserState] = useState<
    | {
        phone?: string | null;
        gender?: GenderEnum | null;
        firstName?: string | null;
        lastName?: string | null;
      }
    | undefined
  >(undefined);
  const [updateUserFieldMutation] = useMutation(updateUserFields);

  const isMobile = useMediaQuery("(max-width:600px)");

  const { setHorizontalTopValues } = useHorizontalTopHeader();

  useEffect(() => {
    if (isMobile) {
      setHorizontalTopValues({
        customHeaderTitle: "Home",
        onClickBack: undefined,
        bottomPercentageCompletion: undefined,
        showOnDesktop: false,
        showBorderBoxShadow: true,
        hideMobileToolbar: false,
      });
    } else {
      setHorizontalTopValues({
        customHeaderTitle: undefined,
        onClickBack: undefined,
        bottomPercentageCompletion: undefined,
        showOnDesktop: false,
        showBorderBoxShadow: true,
        hideMobileToolbar: false,
      });
    }
  }, [isMobile, setHorizontalTopValues]);

  if (!currentUser) {
    return;
  }

  if (!allowedStates.includes(currentUser?.state)) {
    return (
      <Stack
        alignItems="start"
        justifyContent="flex-start"
        p={isMobile ? "32px" : "40px"}
        pb={"60px"}
        flexGrow={1}
        sx={(theme) => ({
          overflowY: "scroll",
          backgroundColor: "#FFF",
        })}
      >
        <Stack maxWidth="420px">
          <Stack alignItems="flex-start" direction="row">
            <Typography variant="h1" color={"primary.main"}>
              We're sorry,
            </Typography>
          </Stack>
          <Stack alignItems="flex-start" mt={"16px"}>
            <Typography variant="body2" color={"primary.main"}>
              Unfortunately, we're not serving your state currently.
            </Typography>
            <Typography variant="body2" mt={"16px"} color={"primary.main"}>
              We plan to roll out to the following states in the next 2-3 months
              and we'll contact:
            </Typography>
            <Typography variant="body2Medium" mt={"8px"} color={"primary.main"}>
              AL, CO, CT, DC, DE, GA, ID, IN, KS, KY, LA, MD, ME, MI, MS, MT,
              NC, ND, NE, NV, NY, OH, OK, SC, SD, TN, UT, WA, WI, WV, WY
            </Typography>
            <Typography variant="body2" mt={"16px"} color={"primary.main"}>
              We hope to serve you then!
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      width={"100%"}
      sx={(theme) => ({
        backgroundColor: isMobile
          ? theme.palette.primary.contrastText
          : theme.palette.neutral["200"],
        overflowY: "scroll",
        paddingTop: isMobile ? "35px" : "64px",
        paddingBottom: isMobile ? "35px" : "64px",
        px: isMobile ? "32px" : "64px",
      })}
      flex={1}
    >
      <Stack>
        <Typography variant="h2" sx={{ mb: "32px" }} color="primary.main">{`${
          currentUser?.firstName ? `Hi ${currentUser?.firstName},` : "Hello,"
        }`}</Typography>
        {!data && !error && (
          <Stack
            maxWidth={isMobile ? undefined : "320px"}
            minWidth={isMobile ? undefined : "250px"}
            direction="row"
            width={isMobile ? undefined : "100%"}
            p={isMobile ? "32px" : "64px"}
          >
            <Loading />
          </Stack>
        )}
        {(data?.me.ordersInProgress.length ?? 0) > 0 && (
          <Stack
            sx={{
              mb: isMobile ? "32px" : "48px",
            }}
            key="complete-your-visits"
          >
            <Typography
              variant="h2"
              sx={{ mb: "-12px", fontSize: "24px" }}
              color="primary.main"
            >
              Complete your visit
            </Typography>
            <Grid container rowSpacing={0} maxWidth={"850px"}>
              {data?.me.ordersInProgress.map((o) => {
                if (!o.intakeResponse?.templateId) {
                  return undefined;
                }
                return (
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    m={6}
                    lg={5}
                    sx={{ mb: "24px", ml: "4px" }}
                    key="complete-your-visits"
                  >
                    <CompleteYourVisitTile order={o} />
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        )}
        {(data?.me.ordersBeingProcessed.length ?? 0) > 0 && (
          <>
            <Typography
              variant="h2"
              sx={{ mb: "-12px", fontSize: "24px" }}
              color="primary.main"
            >
              Orders in progress
            </Typography>
            <Grid container rowSpacing={0} maxWidth={"850px"}>
              {data?.me.ordersBeingProcessed.map((o) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    m={6}
                    lg={5}
                    sx={{ mb: "0px", ml: "4px" }}
                    key="orders-in-progress"
                  >
                    <OrderInProgessTile order={o} />
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
      </Stack>
    </Stack>
  );
}

export default HomeView;
