import { useEffect, useRef, useState } from "react";
import { Button, Typography, Input, useMediaQuery } from "@mui/material";
import { Stack } from "@mui/material";
import { useAuth } from "contexts/AuthContext";
import { MessageWithUserProfilePicFragment, ThreadType } from "gql-gen/graphql";
import { formatDateTimestampForMessages, formatTimestamp } from "utils/date";
import SendRoundedIcon from "@mui/icons-material/SendRounded";

import typography from "theme/typography";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  createUserMessage,
  getUserMedicalMessages,
  getUserSupportMessages,
  getUserWithProfilePic,
} from "gql/Messages.gql";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

export function Message({
  message: { sender, content, timestamp },
}: {
  message: MessageWithUserProfilePicFragment;
}) {
  const { currentUser } = useAuth();
  const senderIsCurrentUser = currentUser?.id === sender.id;
  const isSmallScreen = useMediaQuery("(max-width:990px)");

  // Function to detect links and make them clickable
  const renderTextWithLinks = (text: string) => {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <a href={part} target="_blank" rel="noopener noreferrer" key={index}>
          {part}
        </a>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  return (
    <Stack mb={"16px"}>
      <Stack
        direction={senderIsCurrentUser ? "row-reverse" : "row"}
        mb={"8px"}
        alignItems={"center"}
      >
        <Stack
          sx={(theme) => ({
            height: "36px",
            width: "36px",
            borderRadius: "100px",
            mr: senderIsCurrentUser ? undefined : "12px",
            ml: senderIsCurrentUser ? "12px" : undefined,
            backgroundColor: senderIsCurrentUser
              ? theme.palette.purple["200"]
              : theme.palette.yellow.main,
            overflow: "hidden",
          })}
        >
          <Stack component="img" src={sender.userSelfiePhoto?.presignedUrl} />
        </Stack>
        {!senderIsCurrentUser && (
          <Stack>
            <Typography
              component="div"
              variant="caption"
              color="green.dark"
              sx={{ mr: "8px" }}
              display="flex"
              alignItems="center"
            >{`${sender.firstName} ${sender.lastName}`}</Typography>
            {sender.adminInfo && (
              <Typography
                component="div"
                variant="body2"
                color="green.dark"
                sx={{ mr: "8px" }}
                display="flex"
                alignItems="center"
              >{`${sender.adminInfo.title}`}</Typography>
            )}
          </Stack>
        )}
        <Typography
          component="div"
          variant="body2Medium"
          color="gray.dark"
          fontWeight={400}
          display="flex"
          alignItems="center"
        >
          {formatTimestamp(parseInt(timestamp))}
        </Typography>
      </Stack>
      <Stack
        direction={senderIsCurrentUser ? "row-reverse" : "row"}
        minWidth={isSmallScreen ? "80%" : "40%"}
      >
        <Stack
          maxWidth={isSmallScreen ? "80%" : "40%"}
          p={"16px"}
          borderRadius="10px"
          sx={(theme) => ({
            backgroundColor: senderIsCurrentUser
              ? theme.palette.purple["300"]
              : theme.palette.neutral["300"],
          })}
        >
          <Typography
            variant="body2"
            color={senderIsCurrentUser ? "primary.contrastText" : "green.dark"}
            sx={{ whiteSpace: "pre-line" }}
          >
            {renderTextWithLinks(content)}
          </Typography>
        </Stack>
        <Stack flex={isSmallScreen ? 0.2 : 1} />
      </Stack>
    </Stack>
  );
}

export default function UserMessages({ type }: { type: ThreadType }) {
  // TODO

  const isSmallScreen = useMediaQuery("(max-width:990px)");

  // Specify the type of element for the ref
  const inputRef = useRef<HTMLDivElement>(null);

  const [content, setContent] = useState("");
  const messagesRef = useRef<string>("[]");
  const [searchParams] = useSearchParams();
  const msg = searchParams.get("msg");

  useEffect(() => {
    if (msg) setContent(msg);
  }, [setContent]);

  const client = useApolloClient();

  const { data: getUserWithProfileData } = useQuery(getUserWithProfilePic);

  const { data: userSupportMessageData } = useQuery(getUserSupportMessages, {
    skip: type !== ThreadType.SUPPORT,
  });
  const { data: userMedicaltMessageData } = useQuery(getUserMedicalMessages, {
    skip: type !== ThreadType.MEDICAL,
  });

  const hasSetMessages = useRef<boolean>(false);

  const messagesDataResponse =
    type === ThreadType.SUPPORT
      ? userSupportMessageData
      : userMedicaltMessageData;

  const messagesData = messagesDataResponse?.me.messages;

  const [myMessages, setMyMessages] = useState<
    MessageWithUserProfilePicFragment[]
  >([]);

  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  const { setHorizontalTopValues } = useHorizontalTopHeader();

  useEffect(() => {
    if (isMobile) {
      setHorizontalTopValues({
        customHeaderTitle: "Messages",
        onClickBack: undefined,
        bottomPercentageCompletion: undefined,
        showOnDesktop: false,
        showBorderBoxShadow: true,
      });
    }
  }, [isMobile, setHorizontalTopValues, navigate]);

  useEffect(() => {
    client.refetchQueries({
      include: ["GetUserHasUnreadMessages"],
    });
  }, []);

  useEffect(() => {
    inputRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [myMessages]);

  useEffect(() => {
    if (!hasSetMessages.current && messagesData) {
      setMyMessages(messagesData);
    }
  }, [setMyMessages, messagesData]);

  const [handleCreateMessage] = useMutation(createUserMessage);

  return (
    <Stack
      sx={(theme) => ({
        backgroundColor: theme.palette.neutral["200"],
        overflowY: "scroll",
      })}
      flex={1}
      width={"100%"}
    >
      <Stack
        pb={"32px"}
        p={isSmallScreen ? "32px" : "64px"}
        flex={1}
        flexGrow={1}
      >
        {myMessages.map((m) => (
          <Message message={m} key={m.id} />
        ))}
        <Stack ref={inputRef}></Stack>
      </Stack>
      <Stack
        sx={{
          position: "sticky",
          bottom: 0,
        }}
      >
        <Stack
          width="100%"
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.contrastText,
            position: "relative",
          })}
          height={isMobile ? "75px" : "150px"}
          overflow="hidden"
        >
          <Input
            type="textarea"
            placeholder="Start typing..."
            multiline
            maxRows={4}
            sx={(theme) => ({
              ...typography.caption,
              color: theme.palette.primary.main,
              height: "100%",
              display: "flex",
              alignItems: "baseline",
              borderRadius: 0,
              borderTop: `2px solid ${theme.palette.gray.main}`,
              borderBottom: "unset",
              borderLeft: "unset",
              borderRight: "unset",
              "&.Mui-focused": {
                borderTop: `2px solid ${theme.palette.primary.main}`,
                borderBottom: "unset",
                borderLeft: "unset",
                borderRight: "unset",
              },
            })}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <Stack
            sx={{
              position: "absolute",
              right: isMobile ? "20px" : "16px",
              top: isMobile ? "20px" : "50px",
            }}
          >
            <Button
              sx={(theme) => ({
                width: "fit-content",
                height: "fit-content",
                color: theme.palette.primary.main,
                "&:hover": {
                  color: theme.palette.primary.contrastText,
                },
              })}
              onClick={() => {
                setMyMessages((msgs) => {
                  const threadId = messagesDataResponse?.me.thread?.id!;
                  handleCreateMessage({
                    variables: {
                      threadId,
                      patientId: undefined,
                      content: content,
                      type,
                    },
                  });
                  setContent("");
                  return [
                    ...msgs,
                    {
                      threadId,
                      id: new Date().getTime(),
                      timestamp: `${new Date().getTime()}`,
                      content: content,
                      sender: {
                        ...getUserWithProfileData?.me!,
                      },
                    },
                  ];
                });
              }}
            >
              <SendRoundedIcon
                fontSize="large"
                sx={{
                  color: "currentColor",
                }}
              />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
