import React, { useContext } from "react";

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as LibApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
  createHttpLink,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/link-context";
import { onError } from "@apollo/link-error";
import { useAuth, AuthContextType } from "contexts/AuthContext";
import { env } from "process";

export function ApolloProvider({
  children,
}: React.PropsWithChildren<unknown>): React.ReactElement {
  const authContext = useAuth();

  return (
    <LibApolloProvider client={createApolloClient(authContext)}>
      {children}
    </LibApolloProvider>
  );
}

const BACKEND_PROTOCOL_HOST_PORT =
  import.meta.env.VITE_APP_ENVIRONMENT === "PROD"
    ? "https://api.oanahealth.com"
    : "http://localhost:8000";

function createApolloClient(
  authContext?: AuthContextType
): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      createUploadLink({
        uri: `${BACKEND_PROTOCOL_HOST_PORT}/api/graphql`,
        credentials: "include", // Important: this forwards cookies
      }),
    ]),
  });
}

/**
 * This lets us use fetched query data as part of a later mutation.
 * Ref: https://stackoverflow.com/questions/47211778/cleaning-unwanted-fields-from-graphql-responses/51380645#51380645
 */
function createTypenameRemovalLink() {
  return new ApolloLink((operation, forward) => {
    removeTypenamePropertyFromVariables(operation);
    return forward(operation).map((data) => data);
  });
}

function createCSRFTokenLink() {
  return setContext((request, previousContext) => ({
    headers: {
      ...previousContext.headers,
      //   [CSRF_TOKEN_HEADER_NAME]: localStorage.getItem(CSRF_TOKEN_HEADER_NAME),
    },
  }));
}

const createErrorLink = (authContext?: AuthContextType): ApolloLink =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ locations, message, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${JSON.stringify(path)}`
        )
      );
    }

    if (networkError) {
      const error = JSON.stringify(networkError);
      console.error(`[Network error]: ${JSON.stringify(error)}`);

      if (
        ("statusCode" in networkError && networkError.statusCode === 403) ||
        error.includes("The CSRF token is invalid.")
      ) {
        authContext?.logout();
        if (!authContext) {
          console.error("[Error]: no auth context to sign out of");
        }
      }
    }
  });

function removeTypenamePropertyFromVariables(operation: Operation) {
  if (operation.variables && !operation.getContext().hasUpload) {
    const omitTypename = (key: string, value: unknown): unknown =>
      key === "__typename" ? undefined : value;

    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
}
