import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  TextField,
  Button,
  Link,
  Typography,
  Input,
  Checkbox,
  CircularProgress,
  Select,
  MenuItem,
  useMediaQuery,
  Fade,
  IconButton,
} from "@mui/material";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Stack, alpha, borderBottom } from "@mui/system";
import { useMutation, useQuery } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import PlusSvg from "assets/icons/plus.svg";
import mixpanel from "mixpanel-browser";
import {
  completeIntake,
  getIntakeGroups,
  getLatestQuestionsAndResponseForGroup,
  submitIntakeResponse,
  upsertIntakeQuestionGroupWithResponse,
} from "gql/Intakes.gql";
import {
  GetLatestQuestionsAndResponseForGroupsQuery,
  GetIntakeGroupsQuery,
  OptionDataType,
  QuestionType,
  UpsertIntakeQuestionGroupWithResponseMutationVariables,
  OrderBillingPeriod,
  GetCurrentUserShippingAddressQueryQuery,
  OrderState,
} from "gql-gen/graphql";
import Loading from "components/Loading";
import { DateField } from "@mui/x-date-pickers/DateField";
import Welcome, { allowedStates } from "./Welcome";
import ProductTile from "components/ProductTile";
import { getCalendarStringFromDateTime, getDateObjFromDate } from "utils/date";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { convertHeicFile } from "utils/file";
import Checkout from "./Checkout";
import { useAuth } from "contexts/AuthContext";
import Completion from "./Completion";
import MeetYourDoctor from "./MeetYourDoctor";
import IdVerificationIntakeStep from "./IdVerification";
import { LoadingButton } from "@mui/lab";
import { getOrderState } from "gql/User.gql";
import SubscriptionSelect from "./SubscriptionSelect";
import ShippingAddressForm from "./ShippingAddress";
import { isProduction } from "utils/api";

type IntakeQuestionGroup =
  GetLatestQuestionsAndResponseForGroupsQuery["me"]["intakeTemplate"]["intakeQuestionGroups"][number];

type Product =
  GetLatestQuestionsAndResponseForGroupsQuery["me"]["intakeTemplate"]["product"];

type IntakeQuestionAndResponse =
  IntakeQuestionGroup["templateQuestions"][number]["question"];

const hasAllQuestionsAnswered = (group: IntakeQuestionGroup) => {
  let hasUnansweredQuestion = false;

  group.templateQuestions.forEach((tq) => {
    if (!tq.question.latestUserResponse) {
      hasUnansweredQuestion = true;
    }
  });
  return hasUnansweredQuestion;
};

type MultiTextOptionValue = {
  [id: number]: { id: number; value: number | string };
};

type ShippingAddress =
  GetCurrentUserShippingAddressQueryQuery["me"]["shippingAddress"];

function formatPhoneNumber(phoneNumber: string): string | undefined {
  // Check if the phone number is valid (i.e., exactly 10 digits)
  const cleanedNumber = phoneNumber.replace(/\D/g, "");
  if (!/^\d{10}(\d)?$/.test(cleanedNumber)) {
    return undefined;
  }

  // Check if the number includes an international code (11 digits)
  let startIndex = 0;
  let internationalCode = "";
  if (cleanedNumber.length === 11) {
    internationalCode = `+${cleanedNumber.substring(0, 1)} `;
    startIndex = 1;
  }

  // Extract area code, first three digits, and last four digits
  const areaCode = cleanedNumber.substring(startIndex, startIndex + 3);
  const firstThree = cleanedNumber.substring(startIndex + 3, startIndex + 6);
  const lastFour = cleanedNumber.substring(startIndex + 6);

  // Format and return the phone number
  return `${internationalCode}(${areaCode}) ${firstThree}-${lastFour}`;
}

type IntakeQuestionProps = { question: IntakeQuestionAndResponse } & {
  onBlur: (value: string | number) => void;
  onFile: (value: File | null, optionId: number) => void;
  onBlurMultiText: (value: MultiTextOptionValue) => void;
  onExlcusiveSelection: (optionId: number) => void;
  onOptionSelection: (optionId: number) => void;
  onOptionDeletion: (optionId: number) => void;
  showError?: boolean;
};

function IntakeQuestion({
  onBlur,
  onFile,
  onExlcusiveSelection,
  onOptionSelection,
  onOptionDeletion,
  onBlurMultiText,
  showError,
  ...props
}: IntakeQuestionProps) {
  const question = props.question;
  const latestValue = question.latestUserResponse?.value;
  const latestUserResponseId = question.latestUserResponse?.id;
  const { currentUser } = useAuth();

  let questionTypeComponent: ReactNode = <div />;

  const [date, setDate] = useState<Date | undefined | null>(
    question.type === QuestionType.CALENDAR && latestValue
      ? getDateObjFromDate(latestValue)
      : undefined
  );
  const [uploadFiles, setUploadFiles] = useState<Array<
    File | string | null
  > | null>(
    question.type === QuestionType.IMAGE_UPLOAD
      ? question.options?.map((o) => o.latestUserResponse?.presignedUrl ?? null)
      : null
  );

  const [selectedMultiOptions, setSelectedMultiOptions] = useState<
    { [id: number]: boolean } | undefined | null
  >(
    question.type === QuestionType.CHECK_BOX_MULTI_SELECT &&
      latestUserResponseId
      ? question.options.reduce((acc, opt) => {
          if (opt.latestUserResponse) {
            acc[opt.id] = true;
          }
          return acc;
        }, {} as { [id: number]: boolean })
      : undefined
  );

  const [selectOption, setSelectOption] = useState<
    { id: number } | undefined | null
  >(
    [QuestionType.DROP_DOWN_SELECT, QuestionType.VISIBLE_PILLS_SELECT].includes(
      question.type
    ) && latestUserResponseId
      ? (question.options
          .map((opt) => (opt.latestUserResponse ? opt : null))
          .filter((userResp) => userResp !== null)[0] as { id: number })
      : undefined
  );

  const [multiTextOption, setMultiTextOption] = useState<
    MultiTextOptionValue | undefined | null
  >(
    [QuestionType.MULTI_TEXT].includes(question.type) && latestUserResponseId
      ? question.options.reduce((acc, opt) => {
          if (opt.latestUserResponse?.value) {
            acc[opt.id] = {
              id: opt.id,
              value: opt.latestUserResponse.value,
            };
          }
          return acc;
        }, {} as { [id: number]: { id: number; value: number | string } })
      : undefined
  );

  const [text, setText] = useState<string | undefined | null>(latestValue);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const firstName = currentUser?.firstName;
  const lastName = currentUser?.lastName;
  const dateOfBirth = currentUser?.dateOfBirth;
  const gender = currentUser?.gender;
  const phone = currentUser?.phone;
  const prompt = question.prompt;
  useEffect(() => {
    if (prompt === "First name" && firstName) {
      setText(firstName);
      onBlur(firstName);
    }
    if (prompt === "Last name" && lastName) {
      setText(lastName);
      onBlur(lastName);
    }
    if (prompt === "Date of birth" && dateOfBirth) {
      let parts = dateOfBirth.split("-");
      // Note: months are 0-based in JavaScript
      const date = new Date(
        parseInt(parts[0]),
        parseInt(parts[1]) - 1,
        parseInt(parts[2].substr(0, 2))
      );
      setDate(date);
      onBlur(getCalendarStringFromDateTime(date));
    }
    if (
      prompt === "Phone number (we collect in case of medical emergencies)" &&
      phone
    ) {
      // Note: months are 0-based in JavaScript
      setText(phone);
      onBlur(phone);
    }
  }, [
    lastName,
    firstName,
    prompt,
    setDate,
    dateOfBirth,
    gender,
    phone,
    setText,
  ]);

  const handleDrop = (
    event: React.DragEvent,
    setUploadedFile: any,
    idx: number,
    optionId: number
  ) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    setUploadedFile((files_: any) => {
      const newFile = [...files_];
      newFile[idx] = files[0];
      return newFile;
    });
    onFile(files[0], optionId);
  };

  const onFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setUploadedFile: any,
    idx: number,
    optionId: number
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type === "image/heic") {
        const jpegBlob = await convertHeicFile(file);
        setUploadedFile((files_: any) => {
          const newFile = [...files_];
          newFile[idx] = jpegBlob;
          return newFile;
        });
        onFile(
          new File(Array.isArray(jpegBlob) ? jpegBlob : [jpegBlob], file.name),
          optionId
        );
      } else if (["image/jpeg", "image/png"].includes(file.type)) {
        setUploadedFile((files_: any) => {
          const newFile = [...files_];
          newFile[idx] = files[0];
          return newFile;
        });
        onFile(files[0], optionId);
      }
    }
  };

  if (question.type === QuestionType.SHORT_TEXT) {
    questionTypeComponent = (
      <Input
        sx={(theme) => ({
          "& input": {
            color: theme.palette.primary.main,
          },
        })}
        multiline={false}
        inputProps={{
          minLength: question.prompt.includes("Phone number") ? 10 : undefined,
        }}
        onBlur={(e) => {
          if (text) {
            const v = formatPhoneNumber(text);
            if (question.prompt.includes("Phone number")) {
              if (v) {
                setText(v);
                onBlur(v);
                setErrorMessage(undefined);
              } else {
                onBlur("");
                setErrorMessage(
                  "Phone number must be a valid US phone like (310) 123-4567"
                );
              }
            } else {
              onBlur(text);
            }
          }
        }}
        value={text ?? ""}
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
    );
  } else if (question.type === QuestionType.CALENDAR) {
    questionTypeComponent = (
      <DateField
        hiddenLabel
        value={date}
        onChange={(d) => setDate(d)}
        onBlur={() => {
          if (!date) {
            return;
          }
          onBlur(getCalendarStringFromDateTime(date));
        }}
        sx={(theme) => ({
          "& input": {
            color: theme.palette.primary.main,
          },
        })}
      />
    );
  } else if (question.type === QuestionType.IMAGE_UPLOAD) {
    if (currentUser?.id !== 5925) {
      questionTypeComponent = null;
    } else {
      questionTypeComponent = (
        <Stack>
          {props.question.options.map((opt, idx) => {
            const value = idx;
            return (
              <DragDropContext onDragEnd={() => {}}>
                <Droppable droppableId="droppable-id">
                  {(provided) => {
                    const uploadFile = uploadFiles?.[idx];
                    return (
                      <Stack
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        minHeight="120px"
                        maxHeight="200px"
                        width="100%"
                        sx={(theme) => ({
                          borderRadius: "10px",
                          border: `2px dashed ${theme.palette.gray.main}`,
                          mb: "32px",
                          position: "relative",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden",
                          backgroundColor: theme.palette.background.paper,
                        })}
                        onDragOver={(event) => event.preventDefault()}
                        onDrop={(event) => {
                          handleDrop(event, setUploadFiles, idx, opt.id);
                        }}
                      >
                        {uploadFiles && uploadFiles[idx] ? (
                          <>
                            <IconButton
                              onClick={() => {
                                setUploadFiles((o) => {
                                  const nextFiles = o
                                    ? [...o]
                                    : props.question.options.map((o) => null);
                                  nextFiles[idx] = null;
                                  return nextFiles;
                                });
                                onFile(null, opt.id);
                              }}
                              sx={(theme) => ({
                                position: "absolute",
                                top: 8,
                                Index: 3,
                                right: 8,
                                backgroundColor: theme.palette.neutral["300"],
                                "&:hover": {
                                  backgroundColor: theme.palette.neutral["200"],
                                },
                              })}
                            >
                              <CloseIcon />
                            </IconButton>
                            <img
                              src={
                                typeof uploadFile === "object" &&
                                uploadFile !== null
                                  ? URL.createObjectURL(uploadFile)
                                  : uploadFile ?? ""
                              }
                              alt="Uploaded ID"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </>
                        ) : (
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{ height: "100%", width: "100%" }}
                          >
                            <Stack
                              component="label"
                              htmlFor={`id-upload-${idx}`}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Stack
                                component="img"
                                src={PlusSvg}
                                sx={{
                                  height: "24px",
                                  width: "24px",
                                  cursor: "pointer",
                                }}
                              />
                            </Stack>
                          </Stack>
                        )}
                        <input
                          type="file"
                          id={`id-upload-${idx}`}
                          onChange={(event) => {
                            onFileSelect(event, setUploadFiles, value, opt.id);
                          }}
                          style={{ display: "none" }}
                          multiple
                          accept="image/*"
                        />
                      </Stack>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            );
          })}
        </Stack>
      );
    }
  } else if (question.type === QuestionType.CHECK_BOX_MULTI_SELECT) {
    questionTypeComponent = (
      <Stack>
        {props.question.options.map((opt) => {
          return (
            <Stack direction="row" alignItems="center" key={opt.id}>
              <Checkbox
                sx={{
                  mr: "8px",
                }}
                size={"large" as any}
                key="checkbox"
                checked={!!(selectedMultiOptions ?? {})[opt.id]}
                onClick={() => {
                  const nextDicts = selectedMultiOptions ?? {};
                  if (nextDicts[opt.id]) {
                    delete nextDicts[opt.id];
                    onOptionDeletion(opt.id);
                  } else {
                    nextDicts[opt.id] = true;
                    onOptionSelection(opt.id);
                  }
                  setSelectedMultiOptions({ ...nextDicts });
                }}
              />
              <Typography variant="caption" key="label" color="primary.main">
                {opt.label}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  } else if (question.type === QuestionType.VISIBLE_PILLS_SELECT) {
    questionTypeComponent = (
      <Stack direction="row" alignItems="center">
        {props.question.options.map((opt, idx) => {
          const isSelected = selectOption?.id === opt.id;
          return (
            <Stack
              component={"div"}
              key={opt.id}
              alignItems={"center"}
              sx={(theme) => {
                return {
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    border: `2px solid ${theme.palette.primary.main}`,
                    color: theme.palette.primary.contrastText,
                  },
                  backgroundColor: isSelected
                    ? theme.palette.primary.main
                    : undefined,
                  border: `2px solid ${
                    isSelected
                      ? theme.palette.primary.main
                      : theme.palette.gray.main
                  }`,
                  color: isSelected ? "primary.contrastText" : "primary.main",
                  padding: "16px",
                  borderRadius: "50px",
                  mr: idx + 1 !== props.question.options.length ? "12px" : 0,
                  width: "100%",
                };
              }}
              onClick={() => {
                if (isSelected) {
                  return;
                }
                setSelectOption({ id: opt.id });
                onExlcusiveSelection(opt.id);
              }}
            >
              <Typography variant="caption" color={"inherit"}>
                {opt.label}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  } else if (question.type === QuestionType.DROP_DOWN_SELECT) {
    questionTypeComponent = (
      <Select
        value={selectOption?.id ?? ""}
        onChange={(e) => {
          setSelectOption({ id: e.target.value as number });
          onExlcusiveSelection(e.target.value as number);
        }}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        {props.question.options.map((opt) => {
          return (
            <MenuItem
              value={opt.id}
              key={opt.id}
              sx={(theme) => ({
                height: "48px",
                "&.Mui-selected": {
                  backgroundColor: theme.palette.purple["100"],
                },
                "&:hover": {
                  backgroundColor: theme.palette.purple["100"],
                },
              })}
            >
              <Typography variant="caption" color="primary.main">
                {opt.label}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    );
  } else if (question.type === QuestionType.MULTI_TEXT) {
    questionTypeComponent = (
      <Stack direction="row" alignItems="center">
        {props.question.options.map((opt, idx) => {
          return (
            <Stack direction="row" alignItems="center" key={`${idx}`}>
              <Input
                key={`${idx}-input`}
                value={multiTextOption ? multiTextOption[opt.id]?.value : ""}
                type={
                  opt.dataType === OptionDataType.INTEGER_INPUT
                    ? "number"
                    : undefined
                }
                sx={(theme) => ({
                  mr: "8px",
                  color: "primary.main",
                })}
                onChange={(e) => {
                  let localMultiTextOption = multiTextOption ?? {};
                  delete localMultiTextOption[opt.id];
                  localMultiTextOption[opt.id] = {
                    id: opt.id,
                    value: e.target.value,
                  };
                  setMultiTextOption({ ...localMultiTextOption });
                }}
                onBlur={() => {
                  const localMultiTextOption = multiTextOption ?? {};
                  onBlurMultiText(localMultiTextOption);
                }}
              />
              <Typography
                variant="caption"
                component="div"
                key={`${idx}-label`}
                sx={{
                  mr: idx + 1 === props.question.options.length ? 0 : "8px",
                }}
                color="primary.main"
              >
                {opt.label}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  } else if (question.type === QuestionType.TEXT) {
    questionTypeComponent = (
      <Input
        multiline={true}
        value={text ?? ""}
        onChange={(e) => {
          setText(e.target.value);
        }}
        sx={(theme) => ({
          "& textarea": {
            color: theme.palette.primary.main,
          },
        })}
        maxRows={2}
        onBlur={(e) => {
          onBlur(text ?? "");
        }}
      />
    );
  }

  if (
    question.prompt ===
      "Please upload photos of face and or areas of concern" &&
    currentUser?.id !== 5925
  ) {
    return;
  }

  return (
    <div data-hj-suppress>
      <Stack>
        <Typography
          variant="caption"
          sx={(theme) => ({
            mb: "8px",
            p: showError ? "4px" : undefined,
            backgroundColor: showError
              ? alpha(theme.palette.red.main, 0.5)
              : undefined,
          })}
          color="primary.main"
        >
          {question.prompt}
        </Typography>
        {questionTypeComponent}
        {errorMessage && (
          <Typography variant="caption" color="red.main" mt={"4px"}>
            {errorMessage}
          </Typography>
        )}
      </Stack>
    </div>
  );
}

const isMissingAnswersForQuestionGroup =
  (questionIdToCurrentQuestionsAndAnswers: {
    [id: number]: IntakeQuestionAndResponse;
  }) => {
    return Object.keys(questionIdToCurrentQuestionsAndAnswers)
      .map((qgId) => {
        const questionAndAnswers =
          questionIdToCurrentQuestionsAndAnswers[parseInt(qgId)];
        let showConditionalquestion = !questionAndAnswers.conditionalQuestion;
        if (questionAndAnswers.conditionalQuestion) {
          const conditionalQuestion = questionAndAnswers.conditionalQuestion;
          const answeredOptions = questionIdToCurrentQuestionsAndAnswers[
            questionAndAnswers.conditionalQuestion.triggeringQuestionId
          ].options.map((opt) => opt.latestUserResponse?.id && opt.id);
          showConditionalquestion = answeredOptions.includes(
            conditionalQuestion.triggeringOptionId
          );
        }

        if (showConditionalquestion) {
          if (
            questionAndAnswers.type === QuestionType.IMAGE_UPLOAD &&
            !questionAndAnswers.options?.find((o) => o.latestUserResponse)
          ) {
            console.log("NOop");
          }
          if (
            [
              QuestionType.DROP_DOWN_SELECT,
              QuestionType.VISIBLE_PILLS_SELECT,
            ].includes(questionAndAnswers.type) &&
            !questionAndAnswers.options?.find((o) => o.latestUserResponse)
          ) {
            return questionAndAnswers;
          }
          if (
            [QuestionType.SHORT_TEXT, QuestionType.TEXT].includes(
              questionAndAnswers.type
            ) &&
            !questionAndAnswers.latestUserResponse?.value
          ) {
            return questionAndAnswers;
          }
          if (
            [QuestionType.MULTI_TEXT].includes(questionAndAnswers.type) &&
            questionAndAnswers.options?.find(
              (o) => o.latestUserResponse === undefined
            )
          ) {
            return questionAndAnswers;
          }
        }
        return undefined;
      })
      .filter((qa) => qa !== undefined)
      .reduce((acc, curr) => {
        acc[curr!.id] = curr!;
        return acc;
      }, {} as { [id: number]: IntakeQuestionAndResponse });
  };

function IntakeQuestions({
  questionGroupId,
  productEnum,
  encouragementSlide,
  showProductPreview,
  continueToNextPage,
  product,
  isFinalPage,
  billingPeriod = OrderBillingPeriod.QUARTERLY,
}: {
  product: Product;
  questionGroupId: number;
  productEnum: string;
  encouragementSlide: boolean;
  showProductPreview: boolean;
  isFinalPage: boolean;
  billingPeriod?: OrderBillingPeriod;
  continueToNextPage: () => void;
}) {
  const navigate = useNavigate();

  useEffect(() => {
    mixpanel.track("Page View", {
      product: product.productEnum,
      intakeQuestion: "MEDICAL_INTAKE",
      questionGroupId: questionGroupId,
    });
  }, []);

  const { data, loading, error } = useQuery(
    getLatestQuestionsAndResponseForGroup,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        productEnum,
      },
    }
  );
  const isMobile = useMediaQuery("(max-width:600px)");

  const [hasClickedDisabledButton, setHasClickedDisabledButton] =
    useState(false);

  const [saving, setSaving] = useState(false);
  const [onUpdateGroupResponse] = useMutation(
    upsertIntakeQuestionGroupWithResponse
  );

  const questionGroup = data?.me.intakeTemplate.intakeQuestionGroups.find(
    (g) => g.id === questionGroupId
  );

  const templateQuestions = questionGroup?.templateQuestions ?? [];

  const [currentQuestionsAndAnswers, setCurrentQuestionAndAnswers] = useState<{
    [id: number]: IntakeQuestionAndResponse;
  }>({});

  const questionGroupSerialized = JSON.stringify(templateQuestions);
  const questionIdToOrder = templateQuestions.reduce((acc, curr) => {
    acc[curr.question.id] = curr.order;
    return acc;
  }, {} as { [id: number]: number });
  useEffect(() => {
    const questionIdToCurrentQuestionsAndAnswers = (
      JSON.parse(questionGroupSerialized) as typeof templateQuestions
    ).reduce((acc, curr) => {
      acc[curr.question.id] = curr.question;
      return acc;
    }, {} as { [id: number]: IntakeQuestionAndResponse });
    setCurrentQuestionAndAnswers(questionIdToCurrentQuestionsAndAnswers);
  }, [questionGroupSerialized, setCurrentQuestionAndAnswers]);

  const questionsMissingAnswers = isMissingAnswersForQuestionGroup(
    currentQuestionsAndAnswers
  );

  const hasMissingQuestions = Object.keys(questionsMissingAnswers).length;

  const values = convertQuestionsIntoMutationPayload(
    currentQuestionsAndAnswers
  );
  const jsonValuesString = JSON.stringify(values);
  const productId = data?.me.intakeTemplate.productId;
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (productId) {
        await onUpdateGroupResponse({
          variables: {
            intakeQuestionGroupId: questionGroupId,
            responses: JSON.parse(jsonValuesString) as any,
            productId: productId,
          },
          refetchQueries: ["GetOrderState"],
        });
      }
    }, 2500);
    return () => {
      clearInterval(intervalId);
    };
  }, [jsonValuesString, productId, questionGroupId]);

  console.log("HLELEOEOEOEEO");
  if (!data) {
    return <Loading />;
  }

  return (
    <>
      <Stack
        alignItems={questionGroup?.caption ? "center" : undefined}
        sx={{
          minHeight: isMobile && encouragementSlide ? "90%" : "unset",
        }}
      >
        {questionGroup && (
          <Typography
            variant="h2"
            color={"primary.main"}
            sx={{
              mb:
                questionGroup?.caption || showProductPreview ? "16px" : "32px",
            }}
          >
            {questionGroup.subtitle}
          </Typography>
        )}
        {questionGroup?.caption && (
          <Typography
            variant="body2"
            maxWidth={"262px"}
            color={"primary.main"}
            sx={{ mb: "32px", px: "32px", textAlign: "center" }}
          >
            {questionGroup.caption}
          </Typography>
        )}
        {showProductPreview && (
          <Stack maxWidth={"320px"}>
            <ProductTile
              product={product}
              showDoctorVisitCost
              containerSx={(theme) => ({
                borderRadius: "10px",
                padding: "16px",
                mb: "32px",
                border: `1px solid ${theme.palette.gray.main}`,
                backgroundColor: theme.palette.primary.contrastText,
              })}
              billingPeriod={billingPeriod}
            />
          </Stack>
        )}
      </Stack>
      {data && !error && (
        <>
          {Object.keys(currentQuestionsAndAnswers)
            .sort(
              (a, b) =>
                questionIdToOrder[parseInt(a)] - questionIdToOrder[parseInt(b)]
            )
            .map((qgId) => {
              const questionAndAnswers =
                currentQuestionsAndAnswers[parseInt(qgId)];

              let showConditionalquestion =
                !questionAndAnswers.conditionalQuestion;

              if (questionAndAnswers.conditionalQuestion) {
                const conditionalQuestion =
                  questionAndAnswers.conditionalQuestion;
                const answeredOptions = currentQuestionsAndAnswers[
                  questionAndAnswers.conditionalQuestion.triggeringQuestionId
                ].options.map((opt) => opt.latestUserResponse?.id && opt.id);
                showConditionalquestion = answeredOptions.includes(
                  conditionalQuestion.triggeringOptionId
                );
              }

              return (
                showConditionalquestion && (
                  <Stack
                    sx={(theme) => ({
                      mb: "16px",
                    })}
                    key={qgId}
                  >
                    <IntakeQuestion
                      key={qgId}
                      question={questionAndAnswers}
                      showError={
                        hasClickedDisabledButton &&
                        parseInt(qgId) in questionsMissingAnswers
                      }
                      onFile={(value, optId) => {
                        if (!value) {
                          setCurrentQuestionAndAnswers((prev) => ({
                            ...prev,
                            [parseInt(qgId)]: {
                              ...questionAndAnswers,
                              latestUserResponse: null,
                              options: questionAndAnswers.options.map((o) => {
                                if (o.id === optId) {
                                  o.latestUserResponse = null;
                                }
                                return o;
                              }),
                            },
                          }));
                          return;
                        }
                        let currentUserResponse: {
                          id: number;
                          value: string | undefined | null;
                        } | null = questionAndAnswers.latestUserResponse
                          ? {
                              id: questionAndAnswers.latestUserResponse.id,
                              value: null,
                            }
                          : { id: -1, value: null };
                        setCurrentQuestionAndAnswers((prev) => ({
                          ...prev,
                          [parseInt(qgId)]: {
                            ...questionAndAnswers,
                            latestUserResponse: currentUserResponse,
                            options: questionAndAnswers.options.map((o) => {
                              if (o.id === optId) {
                                o.latestUserResponse = {
                                  id: -1,
                                  file: value,
                                } as { id: number };
                              }
                              return o;
                            }),
                          },
                        }));
                      }}
                      onBlurMultiText={(value) => {
                        let hasAllOptionsAnswered = true;
                        const options = questionAndAnswers.options.map((o) => {
                          if (
                            !(o.id in value) ||
                            (typeof value[o.id].value === "string" &&
                              value[o.id].value === "")
                          ) {
                            o.latestUserResponse = undefined;
                            hasAllOptionsAnswered = false;
                          } else {
                            o.latestUserResponse = {
                              id: -1,
                              value: `${value[o.id].value}`,
                            };
                          }
                          return o;
                        });
                        const latestUserResponse = hasAllOptionsAnswered
                          ? { id: -1, value: undefined }
                          : undefined;
                        setCurrentQuestionAndAnswers((prev) => ({
                          ...prev,
                          [parseInt(qgId)]: {
                            ...questionAndAnswers,
                            latestUserResponse,
                            options,
                          },
                        }));
                      }}
                      onBlur={(value: string | number) => {
                        let currentUserResponse: {
                          id: number;
                          value: string | undefined | null;
                        } | null = questionAndAnswers.latestUserResponse
                          ? {
                              id: questionAndAnswers.latestUserResponse.id,
                              value: `${value}`,
                            }
                          : { id: -1, value: `${value}` };
                        if (typeof value === "string" && value === "") {
                          currentUserResponse = null;
                        }
                        setCurrentQuestionAndAnswers((prev) => ({
                          ...prev,
                          [parseInt(qgId)]: {
                            ...questionAndAnswers,
                            latestUserResponse: currentUserResponse,
                          },
                        }));
                      }}
                      onOptionDeletion={(optionId) => {
                        const currentUserResponse: {
                          id: number;
                          value: string | undefined | null;
                        } = questionAndAnswers.latestUserResponse
                          ? {
                              id: questionAndAnswers.latestUserResponse.id,
                              value: undefined,
                            }
                          : { id: -1, value: undefined };

                        const newOptions = questionAndAnswers.options.map(
                          (o) => {
                            if (o.id === optionId) {
                              o.latestUserResponse = undefined;
                            }
                            return o;
                          }
                        );
                        setCurrentQuestionAndAnswers((prev) => ({
                          ...prev,
                          [parseInt(qgId)]: {
                            ...questionAndAnswers,
                            latestUserResponse:
                              newOptions.findIndex(
                                (o) => !!o.latestUserResponse
                              ) !== -1
                                ? currentUserResponse
                                : null,
                            options: newOptions,
                          },
                        }));
                      }}
                      onOptionSelection={(optionId) => {
                        const currentUserResponse: {
                          id: number;
                          value: string | undefined | null;
                        } = questionAndAnswers.latestUserResponse
                          ? {
                              id: questionAndAnswers.latestUserResponse.id,
                              value: undefined,
                            }
                          : { id: -1, value: undefined };
                        setCurrentQuestionAndAnswers((prev) => ({
                          ...prev,
                          [parseInt(qgId)]: {
                            ...questionAndAnswers,
                            latestUserResponse: currentUserResponse,
                            options: questionAndAnswers.options.map((o) => {
                              if (!o.latestUserResponse && o.id === optionId) {
                                o.latestUserResponse = {
                                  id: -1,
                                };
                              }
                              return o;
                            }),
                          },
                        }));
                      }}
                      onExlcusiveSelection={(optionId) => {
                        const currentUserResponse: {
                          id: number;
                          value: string | undefined | null;
                        } = questionAndAnswers.latestUserResponse
                          ? {
                              id: questionAndAnswers.latestUserResponse.id,
                              value: undefined,
                            }
                          : { id: -1, value: undefined };
                        setCurrentQuestionAndAnswers((prev) => ({
                          ...prev,
                          [parseInt(qgId)]: {
                            ...questionAndAnswers,
                            latestUserResponse: currentUserResponse,
                            options: questionAndAnswers.options.map((o) => {
                              if (o.id !== optionId) {
                                o.latestUserResponse = undefined;
                              }
                              if (!o.latestUserResponse && o.id === optionId) {
                                o.latestUserResponse = {
                                  id: -1,
                                };
                              }
                              return o;
                            }),
                          },
                        }));
                      }}
                    />
                  </Stack>
                )
              );
            })}
          <Stack
            sx={
              isMobile
                ? {
                    // position: "sticky",
                    // bottom: 0,
                    // backgroundColor: !encouragementSlide ? "#FFF" : "inherit",
                    // marginLeft: "-32px",
                    // marginRight: "-32px",
                    // paddingBottom: "32px",
                    // mt: "20px",
                    // zIndex: 4,
                    // boxShadow: "0px -4px 4px 0px rgba(217, 217, 217, 0.40)",
                    marginBottom: "32px",
                  }
                : {}
            }
          >
            {saving ? (
              <Stack alignItems="center" mt={"32px"} mr={"32px"} ml={"32px"}>
                <CircularProgress color="primary" />
              </Stack>
            ) : (
              <Button
                size="large"
                sx={(theme) => ({
                  mt: "32px",
                  marginRight: "0px", //isMobile ? "32px" : "0px",
                  marginLeft: "0px", //isMobile ? "32px" : "0px",
                  backgroundColor: Object.keys(questionsMissingAnswers).length
                    ? theme.palette.gray.main
                    : theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: Object.keys(questionsMissingAnswers).length
                      ? theme.palette.gray.main
                      : theme.palette.yellow.main,
                  },
                })}
                onClick={async () => {
                  if (!hasMissingQuestions) {
                    setSaving(true);
                    await onUpdateGroupResponse({
                      variables: {
                        intakeQuestionGroupId: questionGroupId,
                        responses: convertQuestionsIntoMutationPayload(
                          currentQuestionsAndAnswers
                        ),
                        productId: data.me.intakeTemplate.productId,
                      },
                      refetchQueries: [
                        "GetLatestQuestionsAndResponseForGroups",
                      ],
                    });
                    setSaving(false);
                    continueToNextPage();
                  } else {
                    setHasClickedDisabledButton(true);
                  }
                }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  color="primary.contrastText"
                  sx={{
                    "&&": {
                      fontSize: 16,
                    },
                  }}
                >
                  Continue
                </Typography>
              </Button>
            )}
          </Stack>
        </>
      )}
    </>
  );
}

const convertQuestionsIntoMutationPayload = (kv: {
  [id: number]: IntakeQuestionAndResponse;
}): UpsertIntakeQuestionGroupWithResponseMutationVariables["responses"] => {
  return Object.entries(kv)
    .map(([k, qa]) => {
      let recordAnswer = true;
      if (qa.conditionalQuestion) {
        const conditionalQuestion = qa.conditionalQuestion;
        const answeredOptions = kv[
          qa.conditionalQuestion.triggeringQuestionId
        ].options.map((opt) => opt.latestUserResponse?.id && opt.id);
        recordAnswer = answeredOptions.includes(
          conditionalQuestion.triggeringOptionId
        );
      }
      return recordAnswer ? qa : undefined;
    })
    .filter((v) => v !== undefined)
    .map((qa) => {
      return {
        questionId: qa!.id,
        value: qa!.latestUserResponse?.value,
        options: qa!.options
          .map((opt) => {
            return opt.latestUserResponse
              ? {
                  optionId: opt.id,
                  value: opt.latestUserResponse?.value,
                  s3Key: !(opt.latestUserResponse as any).file
                    ? opt.latestUserResponse?.s3Key
                    : undefined,
                  file: (opt.latestUserResponse as any).file,
                }
              : undefined;
          })
          .filter((v) => v !== undefined) as Array<{
          optionId: number;
          value?: string | null;
        }>,
      };
    });
};

type QuestionGroup =
  GetLatestQuestionsAndResponseForGroupsQuery["me"]["intakeTemplate"]["intakeQuestionGroups"][number];

export default function IntakeQuestionPage() {
  const { product_enum } = useParams();
  const location = useLocation();
  const pathName = location.pathname + location.search;
  const productEnum = product_enum ?? "";

  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");

  const { currentUser } = useAuth();

  const { data, loading, error } = useQuery(
    getLatestQuestionsAndResponseForGroup,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        productEnum,
      },
    }
  );

  const responseId = data?.me.intakeTemplate.latestUncompletedResponse?.id;
  const orderId = data?.me.intakeTemplate.latestUncompletedResponse?.orderId;
  console.log({ orderId });
  const {
    data: orderData,
    loading: loadingOrderData,
    error: errorOrderData,
  } = useQuery(getOrderState, {
    fetchPolicy: "cache-and-network",
    variables: {
      orderId: orderId ?? 0,
    },
    skip: !orderId,
  });
  const billingPeriod = orderData?.me.order.billingPeriod;

  const questionGroups = data?.me.intakeTemplate.intakeQuestionGroups ?? [];
  const [questionGroupIdx, setQuestionGroupIdx] = useState(0);

  const [showCompletion, setShowCompletion] = useState(false);

  const [onSubmitIntakeResponse] = useMutation(submitIntakeResponse);
  const [onCompleteIntake] = useMutation(completeIntake);

  const needsToUploadIds = false;
  //data?.me.userIdPhoto?.id && data?.me.userIdPhoto?.id ? false : true;

  const { setHorizontalTopValues } = useHorizontalTopHeader();

  const title =
    questionGroupIdx > 0 ? questionGroups[questionGroupIdx]?.title : undefined;
  const percentageCompletion =
    questionGroupIdx > 0
      ? ((questionGroupIdx + 1) /
          (questionGroups.length + 3)) /* add another for billing */ *
        100
      : undefined;

  const [runOnceGroupNavigation, setRunOnceGroupNavigation] =
    useState<boolean>(false);
  const questionGroupsSerialized = JSON.stringify(questionGroups);

  useEffect(() => {
    if (!currentUser) {
      navigate(`/signup?redirect_url=${pathName}`);
      return;
    }
  }, [currentUser, pathName]);

  const orderState = orderData?.me.order.state;
  useEffect(() => {
    const questionGroups: QuestionGroup[] = JSON.parse(
      questionGroupsSerialized
    );
    if (runOnceGroupNavigation || questionGroups.length === 0) {
      return;
    }
    if (!orderState && loadingOrderData) {
      return;
    }
    let runOnceGroupNavigationCurrent: boolean = runOnceGroupNavigation;
    questionGroups.every((qg, idx) => {
      const questionIdToCurrentQuestionsAndAnswers =
        qg.templateQuestions.reduce((acc, curr) => {
          acc[curr.question.id] = curr.question;
          return acc;
        }, {} as { [id: number]: IntakeQuestionAndResponse });

      const questionsMissingAnswers = isMissingAnswersForQuestionGroup(
        questionIdToCurrentQuestionsAndAnswers
      );
      const isMissingAnswers = Object.keys(questionsMissingAnswers).length > 0;
      if (isMissingAnswers) {
        if (idx !== 0 && !runOnceGroupNavigationCurrent) {
          setQuestionGroupIdx(idx);
          runOnceGroupNavigationCurrent = true;
          return false;
        }
        runOnceGroupNavigationCurrent = true;
        return true;
      }
      return true;
    });
    if (!runOnceGroupNavigationCurrent) {
      if (
        errorOrderData ||
        orderState === OrderState.PENDING_SUBSCRIPTION_SELECTION
      ) {
        setQuestionGroupIdx(questionGroups.length);
      } else if (orderState === OrderState.PENDING_SHIPPING_ADDRESS) {
        setQuestionGroupIdx(questionGroups.length + 1);
      } else {
        setQuestionGroupIdx(questionGroups.length + 2);
      }
    }
    setRunOnceGroupNavigation(true);
  }, [
    questionGroupsSerialized,
    isMissingAnswersForQuestionGroup,
    setQuestionGroupIdx,
    needsToUploadIds,
    orderState,
    errorOrderData,
    loadingOrderData,
    setRunOnceGroupNavigation,
  ]);

  useEffect(() => {
    if (questionGroupIdx > 0) {
      setHorizontalTopValues({
        customHeaderTitle: title,
        onClickBack: () => setQuestionGroupIdx((idx) => idx - 1),
        bottomPercentageCompletion: percentageCompletion,
        showOnDesktop: true,
        hideMobileToolbar: true,
      });
    } else {
      setHorizontalTopValues({
        customHeaderTitle: "Patient Profile",
        onClickBack: () => navigate("/dashboard/patient/home"),
        bottomPercentageCompletion: undefined,
        showBorderBoxShadow: true,
        showOnDesktop: true,
        hideMobileToolbar: true,
      });
    }
  }, [
    questionGroupIdx,
    percentageCompletion,
    setHorizontalTopValues,
    title,
    setQuestionGroupIdx,
    navigate,
  ]);

  const goBackOneQuestion = useCallback(() => {
    setQuestionGroupIdx((idx) => idx - 1);
    mixpanel.track("Pressed Go Back on Intake");
  }, [setQuestionGroupIdx]);

  const goForwardOneQuestion = useCallback(() => {
    setQuestionGroupIdx((idx) => idx + 1);
    mixpanel.track("Pressed Continue on Intake");
  }, [setQuestionGroupIdx]);

  const onSubmit = useCallback(
    (
      billingPeriod: OrderBillingPeriod,
      shippingAddress: Partial<ShippingAddress>,
      couponCode?: string
    ) => {
      if (responseId) {
        onCompleteIntake({
          variables: {
            intakeResponseId: responseId,
            billingPeriod,
            addressInput: {
              address: shippingAddress?.address!,
              zipCode: shippingAddress?.zipCode!,
              state: shippingAddress?.state!,
              aptSuite: shippingAddress?.aptSuite ?? "",
              city: shippingAddress?.city!,
              name:
                shippingAddress?.name ??
                `${currentUser?.firstName} ${currentUser?.lastName}`,
            },
            couponCode,
          },
        });
        setShowCompletion(true);
      }
    },
    [responseId, setShowCompletion]
  );

  if (showCompletion) {
    return <Completion onClick={() => navigate("/dashboard/patient/home")} />;
  }

  if (currentUser && !allowedStates.includes(currentUser.state)) {
    return (
      <Stack
        alignItems="center"
        justifyContent="flex-start"
        p={isMobile ? "32px" : "40px"}
        pb={"60px"}
        flexGrow={1}
        sx={(theme) => ({
          overflowY: "scroll",
          backgroundColor: "#FFF",
        })}
      >
        <Stack maxWidth="420px">
          <Stack alignItems="flex-start" direction="row">
            <Typography variant="h1" color={"primary.main"}>
              We're sorry,
            </Typography>
          </Stack>
          <Stack alignItems="flex-start" mt={"16px"}>
            <Typography variant="body2" color={"primary.main"}>
              Unfortunately, we're not serving your state currently.
            </Typography>
            <Typography variant="body2" mt={"16px"} color={"primary.main"}>
              We plan to roll out to the following states in the next 2-3 months
              and we'll contact:
            </Typography>
            <Typography variant="body2Medium" mt={"8px"} color={"primary.main"}>
              NJ, NY, PA, MA
            </Typography>
            <Typography variant="body2" mt={"16px"} color={"primary.main"}>
              We hope to serve you then!
            </Typography>
          </Stack>
          <Button
            size="large"
            sx={(theme) => ({
              mt: "32px",
              backgroundColor: theme.palette.primary.main,
            })}
            onClick={() => navigate("/patient/dashboard")}
          >
            <Typography
              variant="body1"
              component="div"
              color="primary.contrastText"
              sx={{
                "&&": {
                  fontSize: 16,
                },
              }}
            >
              Go home
            </Typography>
          </Button>
        </Stack>
      </Stack>
    );
  }

  const questionGroup = questionGroups[questionGroupIdx];

  return (
    <Stack
      alignItems="center"
      justifyContent="flex-start"
      p={isMobile ? "32px" : "40px"}
      pb={isMobile ? "0px" : "60px"}
      flexGrow={1}
      sx={(theme) => {
        if (questionGroup?.encouragementSlide) {
          return {
            background:
              "linear-gradient(180deg, rgba(222, 225, 253, 0.61) 26.04%, #DEE1FD 100%)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            overflowY: "scroll",
            flexGrow: 1,
          };
        }
        return {
          overflowY: "scroll",
          flexGrow: 1,
        };
      }}
    >
      <Fade in timeout={700} key={`${questionGroupIdx}-${!data && !error}`}>
        <Stack maxWidth="420px">
          {((!data && !error) || runOnceGroupNavigation === false) && (
            <Loading />
          )}
          {data &&
            !error &&
            questionGroupIdx !== -1 &&
            questionGroupIdx < questionGroups.length && (
              <IntakeQuestions
                product={data.me.intakeTemplate.product}
                continueToNextPage={() =>
                  setQuestionGroupIdx((prev) => prev + 1)
                }
                isFinalPage={questionGroupIdx + 1 === questionGroups.length}
                encouragementSlide={!!questionGroup.encouragementSlide}
                showProductPreview={!!questionGroup.showProductPreview}
                productEnum={productEnum}
                questionGroupId={questionGroups[questionGroupIdx].id}
                key={questionGroupIdx}
                billingPeriod={orderData?.me.order.billingPeriod}
              />
            )}
          {questionGroupIdx === questionGroups.length && needsToUploadIds && (
            <IdVerificationIntakeStep
              onBack={goBackOneQuestion}
              onContinue={goForwardOneQuestion}
              bottomPercentageCompletion={percentageCompletion}
              key={questionGroupIdx}
            />
          )}
          {data && orderData && questionGroupIdx === questionGroups.length && (
            <SubscriptionSelect
              onBack={goBackOneQuestion}
              onContinue={goForwardOneQuestion}
              bottomPercentageCompletion={percentageCompletion}
              orderId={orderData.me.order.id}
              product={data.me.intakeTemplate.product}
              billingPeriodBackend={orderData.me.order.billingPeriod}
            />
          )}
          {data &&
            orderData &&
            questionGroupIdx === questionGroups.length + 1 && (
              <ShippingAddressForm
                onBack={goBackOneQuestion}
                onContinue={goForwardOneQuestion}
                product={data.me.intakeTemplate.product}
                bottomPercentageCompletion={percentageCompletion}
                orderId={orderData.me.order.id}
              />
            )}
          {data &&
            orderData &&
            questionGroupIdx === questionGroups.length + 2 && (
              <Checkout
                onBack={goBackOneQuestion}
                product={data.me.intakeTemplate.product}
                bottomPercentageCompletion={percentageCompletion}
                orderId={orderData.me.order.id}
                billingPeriod={orderData.me.order.billingPeriod}
                onSubmit={onSubmit}
              />
            )}
        </Stack>
      </Fade>
    </Stack>
  );
}
