import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Link,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/material";
import {
  Address,
  OrderBillingPeriod,
  OrderState,
  ProductDetailsFragment,
} from "gql-gen/graphql";
import ProductTile from "components/ProductTile";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import CircleCheckboxGreen from "assets/icons/circle-check-black.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import DrugGreen from "assets/icons/drug-black.svg";
import PackageGreen from "assets/icons/package-black.svg";
import CircleCheckboxGray from "assets/icons/circle-check-gray.svg";
import {
  dateToFormattedString,
  formattedStringToDate,
  getDateObjFromDate,
} from "utils/date";
import { Tab } from "components/Tab";
import { useNavigate } from "react-router";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";
import { useQuery } from "@apollo/client";
import {
  getCompletedOrdersForCurrentUser,
  getPendingOrdersForCurrentUser,
} from "gql/User.gql";
import Loading from "components/Loading";
import { palette } from "@mui/system";
import { useTheme } from "@emotion/react";
import { useSearchParams } from "react-router-dom";

type OrderTabs = "current" | "past";

export const OrderStateToTimeLineData = {
  [OrderState.PENDING_INTAKE_COMPLETION]: {
    label: "Medical intake",
    order: 0,
    captionIfPassed: "We've received your intake",
    captionIfCurrentState: "Your intake is missing information",
    linkToAction: (intakeId: any) => `/intake/${intakeId}/qs`,
    alwaysShow: true,
    customLinkActionCaption: undefined,
  },
  [OrderState.PENDING_SUBSCRIPTION_SELECTION]: {
    label: "Medical intake",
    order: 0.25,
    captionIfPassed: "We've received your intake",
    captionIfCurrentState: "Select your treatment plan",
    linkToAction: (intakeId: any) => `/intake/${intakeId}/qs`,
    customLinkActionCaption: undefined,
    alwaysShow: false,
  },
  [OrderState.PENDING_SHIPPING_ADDRESS]: {
    label: "Medical intake",
    order: 0.5,
    captionIfPassed: "We've received your intake",
    captionIfCurrentState: "Your shipping information is missing",
    linkToAction: (intakeId: any) => `/intake/${intakeId}/qs`,
    alwaysShow: false,
    customLinkActionCaption: undefined,
  },
  [OrderState.PENDING_ID_VERIFICATION]: {
    label: "Pending ID verification",
    order: 1,
    captionIfPassed: undefined,
    captionIfCurrentState: "We're validating your ID",
    alwaysShow: false,
    linkToAction: undefined,
    customLinkActionCaption: undefined,
  },
  [OrderState.MISSING_ID_VERIFICATION]: {
    label: "Missing ID verification",
    order: 3,
    captionIfPassed: undefined,
    captionIfCurrentState:
      "Please upload a government issued ID so our team can verify this medical intake is for you",
    alwaysShow: false,
    linkToAction: (intakeId: any) => `/id-verification`,
    customLinkActionCaption: undefined,
  },
  [OrderState.MISSING_PAYMENT_INFO]: {
    label: "Missing payment method",
    order: 2,
    captionIfPassed: undefined,
    captionIfCurrentState:
      "Please submit payment information for us to proceed with your medical visit",
    alwaysShow: false,
    linkToAction: (intakeId: any) =>
      `/dashboard/patient/account/payment-method`,
    customLinkActionCaption: undefined,
  },
  [OrderState.FAILED_TO_CHARGE_PAYMENT_METHOD]: {
    label: "Payment method invalid",
    order: 4,
    captionIfPassed: undefined,
    captionIfCurrentState:
      "Please upload a valid payment method so we can proceed with the virtual medical visit",
    alwaysShow: false,
    linkToAction: undefined,
    customLinkActionCaption: undefined,
  },
  [OrderState.PENDING_MEDICAL_REVIEW]: {
    label: "Doctor review",
    order: 5,
    captionIfPassed: "A doctor has approved your perscription.",
    captionIfCurrentState: "A doctor is evaluating your visit",
    alwaysShow: true,
    linkToAction: undefined,
    customLinkActionCaption: undefined,
  },
  [OrderState.PENDING_PRESCRIPTION_REFILL]: {
    label: "Doctor review",
    order: 5,
    captionIfPassed: "A doctor is evaluating your refill",
    captionIfCurrentState: "A doctor has approved your refill",
    alwaysShow: false,
    linkToAction: undefined,
    customLinkActionCaption: undefined,
  },
  [OrderState.REJECTED_BY_MEDICAL_REVIEW]: {
    label: "Doctor review",
    order: 6,
    captionIfPassed: undefined,
    captionIfCurrentState:
      "A doctor reviewed your medical history and determined this prescription treatment is not right for you",
    alwaysShow: false,
    linkToAction: undefined,
    customLinkActionCaption: undefined,
  },
  [OrderState.CANCELLED]: {
    label: "Cancelled",
    order: 8,
    captionIfPassed: undefined,
    captionIfCurrentState:
      "This order has been cancelled. You will not be charged.",
    alwaysShow: true,
    linkToAction: undefined,
    customLinkActionCaption: undefined,
  },
  [OrderState.SENT_TO_PHARMACY]: {
    label: "At pharmacy",
    order: 8,
    captionIfPassed: "Your prescription has been prepared",
    captionIfCurrentState: "Your prescription is being prepared",
    alwaysShow: true,
    linkToAction: undefined,
    customLinkActionCaption: undefined,
  },
  [OrderState.SHIPPED_TO_PATIENT]: {
    label: "Treatment shipped",
    order: 9,
    captionIfPassed: "Your treatment has been shipped",
    captionIfCurrentState:
      "Your tracking information will show up here when available",
    alwaysShow: true,
    linkToAction: (intakeId: any) => `/dashboard/patient/account/subscriptions`,
    customLinkActionCaption: "See Subscription details",
  },
  [OrderState.SENDING_TO_PHARMACY]: {
    label: "Sending treatment to pharmacy",
    order: 7,
    captionIfPassed: undefined,
    captionIfCurrentState:
      "Your treatment has been approved and is being sent to the pharmacy",
    alwaysShow: false,
    linkToAction: undefined,
    customLinkActionCaption: undefined,
  },
  NEXT_REFILL: {
    label: "Next refill",
    order: 10,
    captionIfPassed: undefined,
    captionIfCurrentState: undefined,
    alwaysShow: true,
    linkToAction: undefined,
    customLinkActionCaption: undefined,
  },
};

function Order({
  currentOrderState,
  expectedNextRefillDate,
  trackingUrl,
  treatmentShippedOn,
  prescriptionName,
  prescriptionInstructions,
  shippingAddress,
  intakeId,
  billingPeriod,
  product,
}: {
  currentOrderState: OrderState;
  expectedNextRefillDate?: Date;
  treatmentShippedOn?: Date;
  trackingUrl?: string;
  prescriptionName: string;
  prescriptionInstructions?: string;
  shippingAddress?: Address;
  intakeId?: number;
  billingPeriod: OrderBillingPeriod;
  product: ProductDetailsFragment;
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const statesToAlwaysShow =
    currentOrderState !== OrderState.REJECTED_BY_MEDICAL_REVIEW
      ? [
          OrderState.PENDING_INTAKE_COMPLETION,
          OrderState.PENDING_MEDICAL_REVIEW,
          OrderState.SENT_TO_PHARMACY,
          "NEXT_REFILL",
        ]
      : [OrderState.PENDING_INTAKE_COMPLETION];

  const nextRefillCaption = expectedNextRefillDate
    ? dateToFormattedString(expectedNextRefillDate)
    : "View your next refill Account > Subscriptions";

  const [showPrescriptionInstructions, setShowPrescriptionInstructions] =
    useState(false);
  return (
    <Stack
      sx={(theme) => ({
        borderRadius: "10px",
        background: theme.palette.primary.contrastText,
        border: `1px solid ${theme.palette.gray.main}`,
        mb: "32px",
      })}
      maxWidth="inherit"
    >
      <Stack
        alignItems="start"
        justifyContent="center"
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.gray.main}`,
        })}
      >
        <ProductTile
          product={product}
          billingPeriod={billingPeriod}
          containerSx={{ m: "16px" }}
        />
      </Stack>
      <Stack
        alignItems="flex-start"
        justifyContent="start"
        height="100%"
        pl={"16px"}
        pr={"16px"}
        pt={"16px"}
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.gray.main}`,
        })}
      >
        <Timeline
          sx={{
            px: "0px",
          }}
        >
          {Object.entries(OrderStateToTimeLineData)
            .sort(([, a], [, b]) => a.order - b.order)
            .filter(
              ([key]) =>
                statesToAlwaysShow.includes(key) || key === currentOrderState
            )
            .map(([key, value]) => {
              const currentOrderNumber =
                OrderStateToTimeLineData[currentOrderState].order;
              const {
                order,
                captionIfCurrentState,
                captionIfPassed,
                label,
                linkToAction,
                customLinkActionCaption,
              } = value;

              let caption =
                currentOrderNumber > order
                  ? captionIfPassed
                  : captionIfCurrentState;
              if (key === "NEXT_REFILL") {
                caption = nextRefillCaption;
              }
              const trackingUrlElement: React.ReactNode =
                trackingUrl && key === OrderState.SHIPPED_TO_PATIENT ? (
                  <Link
                    target="_blank"
                    rel="noopener"
                    variant="body2Bold"
                    sx={{
                      cursor: "pointer",
                    }}
                    href={trackingUrl}
                  >
                    Tracking URL
                  </Link>
                ) : undefined;

              if (key === OrderState.SHIPPED_TO_PATIENT && treatmentShippedOn) {
                caption = `Shipped on ${dateToFormattedString(
                  treatmentShippedOn
                )}`;
              }

              const greenColor =
                currentOrderNumber > order ||
                (expectedNextRefillDate &&
                  new Date() > expectedNextRefillDate) ||
                [
                  OrderState.REJECTED_BY_MEDICAL_REVIEW,
                  OrderState.SHIPPED_TO_PATIENT,
                ].includes(key as OrderState);
              return (
                <TimelineItem
                  sx={{
                    "&:before": {
                      display: "none",
                    },
                  }}
                  key={key}
                >
                  <TimelineSeparator>
                    {OrderState.REJECTED_BY_MEDICAL_REVIEW !==
                    (key as OrderState) ? (
                      <Stack
                        component="img"
                        src={
                          greenColor ? CircleCheckboxGreen : CircleCheckboxGray
                        }
                      />
                    ) : (
                      <CancelIcon
                        sx={(theme) => ({
                          height: "38px",
                          width: "38px",
                          ml: "-3px",
                          mt: "-7px",
                          fill: theme.palette.red.main,
                        })}
                      />
                    )}
                    {![
                      "NEXT_REFILL",
                      OrderState.REJECTED_BY_MEDICAL_REVIEW,
                    ].includes(key) && (
                      <TimelineConnector
                        sx={(theme) => ({
                          width: "3px",
                          backgroundColor: greenColor
                            ? theme.palette.primary.main
                            : theme.palette.gray.main,
                        })}
                      />
                    )}
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{
                      paddingTop: "0px",
                      paddingBottom: "16px",
                      px: "16px",
                    }}
                  >
                    <Typography
                      variant="body2Bold"
                      component="span"
                      color="primary.main"
                    >
                      {label}
                    </Typography>
                    <Typography variant="body2" color="primary.main">
                      {caption}
                    </Typography>
                    {linkToAction && currentOrderState === key && (
                      <Typography
                        variant="body2Bold"
                        color="primary.main"
                        sx={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(linkToAction(intakeId));
                        }}
                      >
                        {customLinkActionCaption ?? "Please complete here"}
                      </Typography>
                    )}
                    {trackingUrlElement}
                  </TimelineContent>
                </TimelineItem>
              );
            })}
        </Timeline>
      </Stack>
      <Stack
        alignItems="start"
        justifyContent="center"
        px="12px"
        paddingTop="22px"
        sx={(theme) => ({
          borderBottom: shippingAddress
            ? `1px solid ${theme.palette.gray.main}`
            : undefined,
        })}
      >
        <Stack
          direction="row"
          alignItems="start"
          sx={{
            pb: "32px",
          }}
        >
          <Stack component="img" sx={{ mr: "12px" }} src={DrugGreen} />
          <Stack alignItems={"flex-start"} justifyContent="space-around">
            <Typography
              variant="body2Bold"
              color="primary.main"
              sx={{ mb: "4px", mt: "10px" }}
            >
              Prescription
            </Typography>
            <Typography variant="body2" color="primary.main" sx={{ mb: "4px" }}>
              {prescriptionName}
            </Typography>
            {prescriptionInstructions && (
              <Typography
                variant="body2Bold"
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                color="primary.main"
                onClick={() => setShowPrescriptionInstructions((s) => !s)}
              >
                {!showPrescriptionInstructions
                  ? "View instructions"
                  : "Hide instruction"}
              </Typography>
            )}
            {showPrescriptionInstructions && (
              <Typography
                variant="body2"
                color="primary.main"
                sx={{ pt: "8px", pr: "32px" }}
              >
                {prescriptionInstructions}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      {shippingAddress && (
        <Stack
          alignItems="start"
          justifyContent="center"
          px="20px"
          paddingTop="28px"
        >
          <Stack
            direction="row"
            alignItems="start"
            sx={{
              pb: "32px",
            }}
          >
            <Stack component="img" sx={{ mr: "21px" }} src={PackageGreen} />
            <Stack alignItems={"flex-start"} justifyContent="space-around">
              <Typography
                variant="body2Bold"
                color="primary.main"
                sx={{ mb: "4px", mt: "4px" }}
              >
                Shipping address
              </Typography>
              <Typography variant="body2" color="primary.main">
                {shippingAddress.address}
              </Typography>
              {shippingAddress.aptSuite && (
                <Typography variant="body2" color="primary.main">
                  {shippingAddress.aptSuite}
                </Typography>
              )}

              <Typography variant="body2" color="primary.main">
                {`${shippingAddress.city}, ${shippingAddress.state}`}
              </Typography>
              <Typography variant="body2" color="primary.main">
                {shippingAddress.zipCode}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default function UserOrdersPage() {
  // TODO

  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get("tab");

  const [currentTab, setCurrentTab] = useState<OrderTabs>(
    (initialTab as OrderTabs) ?? "current"
  );
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const { setHorizontalTopValues } = useHorizontalTopHeader();

  const { data: pendingOrders, error: pendingOrdersError } = useQuery(
    getPendingOrdersForCurrentUser,
    {
      fetchPolicy: "cache-and-network",
      skip: currentTab !== "current",
    }
  );

  const {
    data: completedOrders,

    error: completedOrdersError,
  } = useQuery(getCompletedOrdersForCurrentUser, {
    fetchPolicy: "cache-and-network",
    skip: currentTab === "current",
  });

  const data = currentTab === "current" ? pendingOrders : completedOrders;
  const error =
    currentTab === "current" ? pendingOrdersError : completedOrdersError;

  useEffect(() => {
    if (isMobile) {
      setHorizontalTopValues({
        customHeaderTitle: "Orders",
        onClickBack: undefined,
        bottomPercentageCompletion: undefined,
        showOnDesktop: false,
        showBorderBoxShadow: true,
      });
    }
  }, [isMobile, setHorizontalTopValues, navigate]);

  return (
    <Stack
      alignItems="start"
      justifyContent="flex-start"
      flexGrow={1}
      p={isMobile ? "32px" : "64px"}
      sx={(theme) => ({
        backgroundColor: theme.palette.neutral["200"],
        overflowY: "scroll",
      })}
      direction="row"
    >
      <Stack maxWidth="340px" minWidth="250px" direction="column" width="100%">
        {!isMobile && (
          <Stack direction="column" width="100%" mb={"32px"}>
            <Typography variant="h2" color="primary.main">
              Orders
            </Typography>
          </Stack>
        )}
        <Stack direction="row" mb={"32px"}>
          <Tab<OrderTabs>
            key={"current"}
            tab="current"
            currentTab={currentTab}
            label="Current"
            mr={"8px"}
            onClick={() => setCurrentTab("current")}
          />
          <Tab<OrderTabs>
            key={"past"}
            tab="past"
            currentTab={currentTab}
            label="Past"
            onClick={() => setCurrentTab("past")}
          />
        </Stack>
        <Stack>
          {!data && !error && <Loading />}
          {data?.me.ordersForCurrentUser.map((o) => {
            let prescriptionInstructions: string | undefined = undefined;
            let prescriptionName = o.product.name;
            if (o.prescriptions.length > 0) {
              prescriptionInstructions = o.prescriptions[0].directions;
              prescriptionName = o.prescriptions[0].medication.completeName;
            }
            return (
              <Order
                key={`order-${o.id}`}
                currentOrderState={o.state}
                expectedNextRefillDate={undefined}
                treatmentShippedOn={
                  o.state === OrderState.SHIPPED_TO_PATIENT
                    ? new Date(parseInt(o.shippedAt ?? o.updatedAt))
                    : undefined
                }
                product={o.product}
                billingPeriod={o.billingPeriod}
                intakeId={o.intakeResponse?.templateId ?? undefined}
                trackingUrl={o.trackingUrl ?? undefined}
                prescriptionName={o.exactPrescriptionName ?? o.product.name}
                prescriptionInstructions={prescriptionInstructions}
                shippingAddress={data.me.shippingAddress ?? undefined}
              />
            );
          })}
          {data?.me.ordersForCurrentUser.length === 0 && (
            <Typography variant="h2" color="primary.main" sx={{ mb: 4 }}>
              No orders found
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
