import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Typography,
  Input,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";

import PlusSvg from "assets/icons/plus.svg";
import { useAuth } from "contexts/AuthContext";
import { useMutation, useQuery } from "@apollo/client";
import {
  getCurrentUserQuery,
  updateUserFields,
  uploadNewUserSelfie,
  uploadUserPhotos,
} from "gql/User.gql";
import Loading from "components/Loading";
import { DateField } from "@mui/x-date-pickers";
import { getDateObjFromDate } from "utils/date";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";
import { convertHeicFile } from "utils/file";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CloseIcon from "@mui/icons-material/Close";
import { UserRole } from "gql-gen/graphql";

export default function AccountProfile() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { handleLogin } = useAuth();
  const navigate = useNavigate();

  const [uploadedSelfieFile, setUploadedSelfieFile] = useState<File | null>(
    null
  );

  const onFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setUploadedFile: any
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type === "image/heic") {
        const jpegBlob = await convertHeicFile(file);
        setUploadedFile(
          new File(Array.isArray(jpegBlob) ? jpegBlob : [jpegBlob], file.name)
        );
      } else if (["image/jpeg", "image/png"].includes(file.type)) {
        setUploadedFile(files[0]);
      }
    }
  };

  const handleDrop = async (event: React.DragEvent, setUploadedFile: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type === "image/heic") {
        const jpegBlob = await convertHeicFile(file);
        setUploadedFile(
          new File(Array.isArray(jpegBlob) ? jpegBlob : [jpegBlob], file.name)
        );
      } else if (["image/jpeg", "image/png"].includes(file.type)) {
        setUploadedFile(files[0]);
      }
    }
  };

  const { currentUser } = useAuth();
  const { data, loading, error } = useQuery(getCurrentUserQuery, {
    fetchPolicy: "cache-and-network",
  });

  const [updateUserFieldsMutation] = useMutation(updateUserFields, {
    awaitRefetchQueries: true,
    refetchQueries: ["GetCurrentUserQuery"],
  });

  const [uploadUserPhotosCallback] = useMutation(uploadNewUserSelfie);

  const [userState, setUserState] = useState<
    | {
        email: string;
        phone?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        NPI?: string | null;
        dateOfBirth?: string | null;
      }
    | {
        email: string;
        phone?: undefined;
        NPI?: undefined;
        firstName?: undefined;
        lastName?: undefined;
        dateOfBirth?: undefined;
      }
  >({ email: "" });
  const [date, setDate] = useState<Date | undefined | null>(undefined);

  const backendIsDifferentThanFrontend = data?.me
    ? JSON.stringify({
        email: data.me.email ?? undefined,
        phone: data.me.phone ?? undefined,
        firstName: data.me.firstName ?? undefined,
        lastName: data.me.lastName ?? undefined,
        dateOfBirth: data.me.dateOfBirth ?? undefined,
        NPI: data.me.adminInfo?.NPI ?? undefined,
      }) !==
        JSON.stringify({
          email: userState.email ?? undefined,
          phone: userState.phone ?? undefined,
          firstName: userState.firstName ?? undefined,
          lastName: userState.lastName ?? undefined,
          dateOfBirth: userState.dateOfBirth ?? undefined,
          NPI: userState.NPI ?? undefined,
        }) || uploadedSelfieFile
    : false;

  const backendMe = data?.me;
  const resetStateToBackend = useCallback(() => {
    if (backendMe) {
      const v = { ...backendMe };
      const NPI = v.adminInfo?.NPI;
      setUserState({ ...v, NPI });
      setDate(
        backendMe.dateOfBirth
          ? getDateObjFromDate(backendMe.dateOfBirth)
          : undefined
      );
    }
  }, [backendMe, setUserState, setDate]);

  const { setHorizontalTopValues } = useHorizontalTopHeader();
  const isMobile = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    if (data?.me) {
      resetStateToBackend();
    }
  }, [resetStateToBackend]);
  useEffect(() => {
    if (isMobile) {
      setHorizontalTopValues({
        customHeaderTitle: "Profile",
        onClickBack: () => navigate("/dashboard/patient/account/mobile-nav"),
        showOnDesktop: false,
        showBorderBoxShadow: true,
        hideMobileToolbar: false,
      });
    }
  }, [isMobile, setHorizontalTopValues, navigate]);

  const removeImage = (setUploadedFile: any) => {
    setUploadedFile(null);
  };
  return (
    <Stack
      width={"100%"}
      sx={(theme) => ({
        backgroundColor: theme.palette.neutral["200"],
        overflowY: "scroll",
      })}
      flex={1}
    >
      {!data && !error && (
        <Stack
          maxWidth={isMobile ? undefined : "320px"}
          minWidth={isMobile ? undefined : "250px"}
          direction="row"
          width={isMobile ? undefined : "100%"}
          p={isMobile ? "32px" : "64px"}
        >
          <Loading />
        </Stack>
      )}
      {data && !error && (
        <Stack
          maxWidth={isMobile ? undefined : "320px"}
          minWidth={isMobile ? undefined : "250px"}
          direction="row"
          width={isMobile ? undefined : "100%"}
          p={isMobile ? "32px" : "64px"}
        >
          <Stack direction="column" width="100%">
            {!isMobile && (
              <Typography variant="h2" mb={"32px"} color="primary.main">
                Profile
              </Typography>
            )}
            <DragDropContext onDragEnd={() => {}}>
              <Droppable droppableId="droppable-id">
                {(provided) => (
                  <Stack
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    minHeight="137px"
                    maxHeight="137px"
                    width="100%"
                    sx={(theme) => ({
                      mb: "32px",
                      height: "137px",
                      width: "137px",
                      background: "white",
                      borderRadius: "100px",
                      position: "relative",
                      overflow: "hidden",
                      backgroundColor: theme.palette.background.paper,
                    })}
                    onDragOver={(event) => event.preventDefault()}
                    onDrop={(event) => handleDrop(event, setUploadedSelfieFile)}
                  >
                    {uploadedSelfieFile ? (
                      <>
                        <IconButton
                          onClick={() => removeImage(setUploadedSelfieFile)}
                          sx={(theme) => ({
                            position: "absolute",
                            top: 20,
                            Index: 10,
                            right: 15,
                            backgroundColor: theme.palette.neutral["300"],
                            "&:hover": {
                              backgroundColor: theme.palette.neutral["200"],
                            },
                          })}
                        >
                          <CloseIcon />
                        </IconButton>
                        <img
                          src={URL.createObjectURL(uploadedSelfieFile)}
                          alt="Uploaded ID"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </>
                    ) : data.me.userSelfiePhoto?.presignedUrl ? (
                      <Stack component="label" htmlFor="id-upload">
                        <Stack
                          component="img"
                          src={data.me.userSelfiePhoto?.presignedUrl}
                        />
                      </Stack>
                    ) : (
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{ height: "100%", width: "100%" }}
                      >
                        <Stack
                          component="label"
                          htmlFor="id-upload"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Stack
                            component="img"
                            src={PlusSvg}
                            sx={{
                              height: "24px",
                              width: "24px",
                              cursor: "pointer",
                            }}
                          />
                        </Stack>
                      </Stack>
                    )}
                    <input
                      type="file"
                      id="id-upload"
                      onChange={(event) =>
                        onFileSelect(event, setUploadedSelfieFile)
                      }
                      style={{ display: "none" }}
                      multiple
                      accept="image/*"
                    />
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>
            <Stack mb={"16px"}>
              <Typography
                variant="caption"
                sx={{
                  mb: "8px",
                }}
                color="primary.main"
              >
                First name
              </Typography>
              <Input
                sx={(theme) => ({
                  "& input": {
                    color: theme.palette.primary.main,
                  },
                  backgroundColor: theme.palette.primary.contrastText,
                })}
                multiline={false}
                value={userState?.firstName ?? ""}
                onChange={(e) => {
                  setUserState((s) => ({
                    ...s,
                    firstName: e.target.value,
                  }));
                }}
                onBlur={(e) => {
                  if (backendMe?.firstName && userState?.firstName === "") {
                    setUserState((s) => ({
                      ...s,
                      firstName: backendMe?.firstName,
                    }));
                  }
                }}
              />
            </Stack>
            <Stack mb={"16px"}>
              <Typography
                variant="caption"
                sx={{
                  mb: "8px",
                }}
                color="primary.main"
              >
                Last name
              </Typography>
              <Input
                sx={(theme) => ({
                  "& input": {
                    color: theme.palette.primary.main,
                  },
                  backgroundColor: theme.palette.primary.contrastText,
                })}
                multiline={false}
                value={userState?.lastName ?? ""}
                onChange={(e) => {
                  setUserState((s) => ({
                    ...s,
                    lastName: e.target.value,
                  }));
                }}
                onBlur={(e) => {
                  if (backendMe?.lastName && userState?.lastName === "") {
                    setUserState((s) => ({
                      ...s,
                      lastName: backendMe?.lastName,
                    }));
                  }
                }}
              />
            </Stack>
            <Stack mb={"16px"}>
              <Typography
                variant="caption"
                sx={{
                  mb: "8px",
                }}
                color="primary.main"
              >
                Email
              </Typography>
              <Input
                sx={(theme) => ({
                  "& input": {
                    color: theme.palette.primary.main,
                  },
                  backgroundColor: theme.palette.primary.contrastText,
                })}
                type="email"
                multiline={false}
                value={userState?.email ?? ""}
                onChange={(e) => {
                  setUserState((s) => ({
                    ...s,
                    email: e.target.value,
                  }));
                }}
                disabled
                onBlur={(e) => {
                  if (backendMe?.email && userState?.email === "") {
                    setUserState((s) => ({
                      ...s,
                      email: backendMe?.email,
                    }));
                  }
                }}
              />
            </Stack>
            {currentUser?.role === UserRole.ADMIN && (
              <Stack mb={"16px"}>
                <Typography
                  variant="caption"
                  sx={{
                    mb: "8px",
                  }}
                  color="primary.main"
                >
                  NPI
                </Typography>
                <Input
                  sx={(theme) => ({
                    "& input": {
                      color: theme.palette.primary.main,
                    },
                    backgroundColor: theme.palette.primary.contrastText,
                  })}
                  type="string"
                  multiline={false}
                  value={userState?.NPI ?? ""}
                  onChange={(e) => {
                    setUserState((s) => ({
                      ...s,
                      NPI: e.target.value,
                    }));
                  }}
                />
              </Stack>
            )}
            <Stack mb={"16px"}>
              <Typography
                variant="caption"
                sx={{
                  mb: "8px",
                }}
                color="primary.main"
              >
                Phone number
              </Typography>
              <Input
                sx={(theme) => ({
                  "& input": {
                    color: theme.palette.primary.main,
                  },
                  backgroundColor: theme.palette.primary.contrastText,
                })}
                type="tel"
                multiline={false}
                value={userState?.phone ?? ""}
                onChange={(e) => {
                  setUserState((s) => ({
                    ...s,
                    phone: e.target.value,
                  }));
                }}
              />
            </Stack>
            <Stack mb={"32px"}>
              <Typography
                variant="caption"
                sx={{
                  mb: "8px",
                }}
                color="primary.main"
              >
                Birthday
              </Typography>
              <DateField
                hiddenLabel
                value={date}
                onChange={(d) => setDate(d)}
                onBlur={() => {
                  if (
                    !date ||
                    isNaN(date.getTime()) ||
                    date.getFullYear() < 1930
                  ) {
                    if (backendMe?.dateOfBirth) {
                      setDate(getDateObjFromDate(backendMe.dateOfBirth));
                    }
                    return;
                  }
                  const dd = String(date.getDate()).padStart(2, "0");
                  const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
                  const yyyy = date.getFullYear();
                  setUserState((s) => ({
                    ...s,
                    dateOfBirth: mm + "/" + dd + "/" + yyyy,
                  }));
                }}
                sx={(theme) => ({
                  "& input": {
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.contrastText,
                  },
                })}
              />
            </Stack>
            <Stack direction="row">
              <Button
                size="large"
                sx={(theme) => ({
                  backgroundColor: theme.palette.primary.contrastText,
                  border: `1px solid ${theme.palette.primary.main}`,
                  flexGrow: 1,
                  mr: "8px",
                })}
                onClick={() => {
                  resetStateToBackend();
                }}
                disabled={!backendIsDifferentThanFrontend}
              >
                <Typography
                  variant="body1"
                  component="div"
                  color="primary.main"
                  sx={{
                    "&&": {
                      fontSize: 16,
                    },
                  }}
                >
                  Cancel
                </Typography>
              </Button>
              <Button
                size="large"
                sx={(theme) => ({
                  backgroundColor: backendIsDifferentThanFrontend
                    ? theme.palette.primary.main
                    : theme.palette.gray.main,
                  flexGrow: 1,
                })}
                disabled={!backendIsDifferentThanFrontend}
                onClick={async () => {
                  await updateUserFieldsMutation({
                    variables: {
                      firstName: userState.firstName || undefined,
                      lastName: userState.lastName || undefined,
                      email: userState.email || undefined,
                      phone: userState.phone ?? undefined,
                      NPI: userState.NPI ?? undefined,
                      dateOfBirth: userState.dateOfBirth || undefined,
                    },
                  });
                  if (uploadedSelfieFile) {
                    await uploadUserPhotosCallback({
                      variables: {
                        userSelfiePhoto: uploadedSelfieFile,
                      },
                      refetchQueries: ["GetCurrentUserQuery"],
                    });
                    setUploadedSelfieFile(null);
                  }
                }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  color="primary.contrastText"
                  sx={{
                    "&&": {
                      fontSize: 16,
                    },
                  }}
                >
                  Save
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
