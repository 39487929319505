import React, { useEffect, useState } from "react";
import { Typography, useMediaQuery } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Stack } from "@mui/system";

import { useAuth } from "contexts/AuthContext";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";
import { UserRole } from "gql-gen/graphql";
import Loading from "components/Loading";

export default function LoginEmailComponent() {
  const [showError, setShowError] = useState(false);
  const { handleLoginToken } = useAuth();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("login-token");

  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && currentUser.role === UserRole.PATIENT) {
      navigate("/dashboard/patient/home");
    }
    if (currentUser && currentUser.role === UserRole.ADMIN) {
      navigate("/dashboard/admin/id-verification-queue");
    }
  }, [currentUser]);

  useEffect(() => {
    const handleSubmit = async (token: string) => {
      const result = await handleLoginToken({ token });
      if (!result) {
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 2000);
      }
    };
    if (token) {
      handleSubmit(token);
    }
  }, [handleLoginToken, token]);

  const isMobile = useMediaQuery("(max-width:600px)");

  const { setHorizontalTopValues } = useHorizontalTopHeader();

  useEffect(() => {
    if (isMobile) {
      setHorizontalTopValues({
        customHeaderTitle: "Oana",
        onClickBack: undefined,
        bottomPercentageCompletion: undefined,
        showOnDesktop: false,
        showBorderBoxShadow: true,
        hideMobileToolbar: true,
      });
    } else {
      setHorizontalTopValues({
        customHeaderTitle: undefined,
        onClickBack: undefined,
        bottomPercentageCompletion: undefined,
        showOnDesktop: false,
        showBorderBoxShadow: true,
        hideMobileToolbar: false,
      });
    }
  }, [isMobile, setHorizontalTopValues, navigate]);

  return (
    <Stack
      alignItems="center"
      justifyContent="flex-start"
      height="100%"
      pl={"40px"}
      pr={"40px"}
    >
      <Stack
        mt={"100px"}
        maxWidth={isMobile ? undefined : "350px"}
        width={"100%"}
      >
        {!showError && <Loading />}
        {showError && (
          <Stack
            sx={(theme) => ({
              mt: "16px",
              backgroundColor: theme.palette.pink["transparent80"],
              borderRadius: "10px",
              paddingTop: "16px",
              paddingBottom: "16px",
              paddingRight: "15px",
              paddingLeft: "16px",
            })}
          >
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Stack>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                  }}
                  color={"primary.main"}
                >
                  Invalid token
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
