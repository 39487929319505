import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Checkbox from "@mui/material/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ScribbleArrowDown from "assets/icons/scribble-arrow-down-welcome.svg";
import CheckupGreen from "assets/icons/checkup-black.svg";
import StethoscopeGreen from "assets/icons/stethoscope-black.svg";
import MessagesThinGreen from "assets/icons/messages-thin-black.svg";
import Star from "assets/icons/star.svg";
import SealHipaa2 from "assets/imgs/seal-hipaa2.png";
import LegitScript from "assets/imgs/legitscript-3.png";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Stack } from "@mui/system";
import { useIsLoggedIn } from "components/CheckAuthComponent";
import { useAuth } from "contexts/AuthContext";
import { charlyAxios } from "utils/api";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";
import { stateAcronyms } from "utils/constants";
import ProductTile from "components/ProductTile";
import { OrderBillingPeriod, ProductEnum } from "gql-gen/graphql";
import { useQuery } from "@apollo/client";
import { getProduct } from "gql/Intakes.gql";
import { Divider } from "@mui/material";

function extractProductEnum(url: string | null): string | null {
  if (!url) {
    return null;
  }

  const match = url.match(/\/intake\/([^\/]+)\/qs/);
  return match ? match[1] : null;
}

export default function SignUp() {
  const [showIntro, setShowIntro] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [state, setState] = useState<(typeof stateAcronyms)[number] | null>(
    null
  );
  const ref = useRef<HTMLDivElement>();
  const [showErrorMessage, setShowErrorMessage] = useState(undefined);
  const navigate = useNavigate();
  const { currentUser, handleSignup } = useAuth();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect_url");

  const productEnum = extractProductEnum(redirectUrl);

  const { data: productData } = useQuery(getProduct, {
    fetchPolicy: "cache-and-network",
    variables: {
      productEnum: productEnum!,
    },
    skip: productEnum === null,
  });

  useEffect(() => {
    if (productData) {
      // type ignore
      const root = document?.getElementById("root");
      if (root) {
        root.style.height = `${window.innerHeight}px`;
      }
    }
  }, [productData]);

  const { setHorizontalTopValues } = useHorizontalTopHeader();
  useEffect(() => {
    if (isMobile) {
      setHorizontalTopValues({
        customHeaderTitle: "Oana",
        onClickBack: undefined,
        bottomPercentageCompletion: undefined,
        showOnDesktop: false,
        showBorderBoxShadow: true,
        hideMobileToolbar: true,
      });
    }
  }, [isMobile, setHorizontalTopValues, navigate]);
  useEffect(() => {
    if (currentUser) {
      if (redirectUrl) {
        navigate(window.location.search.split("?redirect_url=")[1]);
      } else {
        navigate("/dashboard/patient/home");
      }
    }
  }, [currentUser, redirectUrl]);

  useEffect(() => {
    if (!showIntro) {
      document.getElementById("checkout-top")?.scrollIntoView({ block: "end" });
    }
  }, [showIntro]);

  if (redirectUrl && redirectUrl.includes("/intake") && showIntro) {
    return (
      <Stack direction="row" flexGrow={1} height={0}>
        <Stack
          alignItems="center"
          justifyContent="flex-start"
          p={isMobile ? "32px" : "40px"}
          pb={isMobile ? "0px" : "60px"}
          flexGrow={1}
          sx={{
            background:
              "linear-gradient(180deg, rgba(222, 225, 253, 0.61) 26.04%, #DEE1FD 100%)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            overflowY: "scroll",
            flexGrow: 1,
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="flex-start"
            // height="100%"
            flexGrow={1}
          >
            <Stack
              maxWidth={isMobile ? undefined : "400px"}
              // width="100%"
            >
              <Stack alignItems="flex-start" direction="row">
                <Typography variant="h1" color={"primary.main"}>
                  Start your visit
                </Typography>
              </Stack>
              {productData?.product ? (
                <Stack
                  alignItems="center"
                  mt={"20px"}
                  mb={"0px"}
                  maxWidth={"320px"}
                >
                  <ProductTile
                    product={productData.product}
                    containerSx={(theme) => ({
                      borderRadius: "10px",
                      padding: "16px",
                      mb: "20px",
                      // border: `1px solid ${theme.palette.gray.main}`,
                      backgroundColor: theme.palette.primary.contrastText,
                    })}
                    billingPeriod={OrderBillingPeriod.QUARTERLY}
                  />
                </Stack>
              ) : (
                <Stack alignItems="center" mt={"20px"} mb={"20px"}>
                  <Stack
                    alignItems="flex-start"
                    height="134px"
                    sx={(theme) => ({
                      borderRadius: "10px",
                      backgroundColor: theme.palette.primary.contrastText,
                    })}
                    width="312px"
                  />
                </Stack>
              )}
            </Stack>
            <Stack maxWidth="320px" width={"100%"}>
              <Stack alignItems="flex-start" mb={"16px"} direction="row">
                <Typography variant="h3" color={"primary.main"}>
                  Here's what to expect
                </Typography>
                <Stack
                  component="div"
                  sx={{
                    position: "relative",
                  }}
                >
                  <Stack
                    component="img"
                    sx={{
                      position: "absolute",
                      top: "15px",
                      left: "5px",
                    }}
                    src={ScribbleArrowDown}
                  ></Stack>
                </Stack>
              </Stack>
              <Stack
                sx={(theme) => ({
                  backgroundColor: theme.palette.primary.contrastText,
                  borderRadius: "10px",
                  paddingTop: "28px",
                  paddingBottom: "28px",
                  paddingRight: "26px",
                  paddingLeft: "28px",
                })}
              >
                <Stack direction="row">
                  <Stack
                    sx={(theme) => ({
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60px",
                      width: "60px",
                      minWidth: "60px",
                      borderRadius: "100px",
                      border: `1px solid ${theme.palette.primary.main}`,
                      backgroundColor: theme.palette.yellow.main,
                      mr: "16px",
                    })}
                  >
                    <Stack
                      sx={{
                        height: "34px",
                        width: "34px",
                      }}
                      component="img"
                      src={CheckupGreen}
                    />
                  </Stack>
                  <Stack>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 700,
                        fontSize: "16px",
                      }}
                      color={"primary.main"}
                    >
                      1. Fill out health intake
                    </Typography>
                    <Typography variant="body2" mt={"8px"} color="primary.main">
                      Create an account to fill out your health intake
                      questionnaire
                    </Typography>
                  </Stack>
                </Stack>
                <Stack mt={"16px"} direction="row">
                  <Stack
                    sx={(theme) => ({
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60px",
                      width: "60px",
                      minWidth: "60px",
                      borderRadius: "100px",
                      border: `1px solid ${theme.palette.primary.main}`,
                      backgroundColor: theme.palette.yellow.main,
                      mr: "16px",
                    })}
                  >
                    <Stack
                      sx={{
                        height: "38px",
                        width: "38px",
                      }}
                      component="img"
                      src={StethoscopeGreen}
                    />
                  </Stack>
                  <Stack>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 700,
                        fontSize: "16px",
                      }}
                      color={"primary.main"}
                    >
                      2. Doctor reviews
                    </Typography>
                    <Typography variant="body2" mt={"8px"} color="primary.main">
                      Our doctors will review and prescribe treatment if
                      appropriate for your needs
                    </Typography>
                  </Stack>
                </Stack>
                <Stack mt={"16px"} direction="row">
                  <Stack
                    sx={(theme) => ({
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60px",
                      width: "60px",
                      minWidth: "60px",
                      borderRadius: "100px",
                      border: `1px solid ${theme.palette.primary.main}`,
                      backgroundColor: theme.palette.yellow.main,
                      mr: "16px",
                    })}
                  >
                    <Stack
                      sx={{
                        height: "60px",
                        width: "60px",
                      }}
                      component="img"
                      src={MessagesThinGreen}
                    />
                  </Stack>
                  <Stack>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 700,
                        fontSize: "16px",
                      }}
                      color={"primary.main"}
                    >
                      3. Message us anytime
                    </Typography>
                    <Typography variant="body2" mt={"8px"} color="primary.main">
                      Our medical team is here to support you 24/7
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                sx={
                  isMobile
                    ? {
                        // mt: "40px",
                        // position: "sticky",
                        // bottom: 0,
                        // backgroundColor: "#FFF",
                        // marginLeft: "-32px",
                        // marginRight: "-32px",
                        // paddingBottom: "32px",
                        // zIndex: 4,
                        // boxShadow: "0px -4px 4px 0px rgba(217, 217, 217, 0.40)",
                        marginBottom: "32px",
                      }
                    : {}
                }
              >
                <Button
                  size="large"
                  sx={(theme) => ({
                    mt: "32px",
                    backgroundColor: theme.palette.primary.main,
                    marginRight: "0px", //isMobile ? "32px" : "0px",
                    marginLeft: "0px", //isMobile ? "32px" : "0px",
                  })}
                  onClick={() => setShowIntro(false)}
                >
                  <Typography
                    variant="body1"
                    component="div"
                    color="primary.contrastText"
                    sx={{
                      "&&": {
                        fontSize: 16,
                      },
                    }}
                  >
                    Start my visit
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack direction="row" flexGrow={1} height={0}>
      <div id="signup-top" />
      <Stack
        alignItems="center"
        justifyContent="flex-start"
        // height="100%"
        pl={"40px"}
        flexGrow={1}
        pr={"40px"}
        overflow={"scroll"}
        // ref={ref}
      >
        <Stack
          mt={isMobile ? "32px" : "32px"}
          maxWidth={isMobile ? undefined : "312px"}
          // width="100%"
        >
          <Stack alignItems="flex-start" direction="row">
            <Typography variant="h1" color={"primary.main"}>
              Create an account to begin
            </Typography>
          </Stack>
          {productEnum === null && (
            <Stack alignItems="flex-start" mt={"20px"} mb={"12px"} />
          )}
          <Stack alignItems="flex-start" mt={"20px"} mb={"12px"} />
          {/* {productData?.product ? (
            <Stack alignItems="center" mt={"20px"} mb={"0px"}>
              <ProductTile
                product={productData.product}
                containerSx={(theme) => ({
                  borderRadius: "10px",
                  padding: "16px",
                  mb: "32px",
                  border: `1px solid ${theme.palette.gray.main}`,
                  backgroundColor: theme.palette.primary.contrastText,
                })}
                billingPeriod={OrderBillingPeriod.QUARTERLY}
              />
            </Stack>
          ) : (
            <Stack alignItems="flex-start" mt={"20px"} mb={"12px"} />
          )} */}
          {showErrorMessage && (
            <Stack
              sx={(theme) => ({
                mt: "16px",
                mb: "16px",
                backgroundColor: theme.palette.pink["transparent80"],
                borderRadius: "10px",
                paddingTop: "16px",
                paddingBottom: "16px",
                paddingRight: "15px",
                paddingLeft: "16px",
              })}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Stack>
                  <Typography variant="body2" color={"primary.main"}>
                    {showErrorMessage ===
                    "Email already exist, please use another email address"
                      ? "Email already exist, please login or use another email address"
                      : showErrorMessage}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
          <Typography
            variant="caption"
            component="div"
            sx={{
              mb: "8px",
            }}
            color={"primary.main"}
          >
            Email
          </Typography>
          <Input
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Typography
            variant="caption"
            component="div"
            sx={{
              mb: "8px",
              mt: "16px",
            }}
            color={"primary.main"}
          >
            State in which you live
          </Typography>
          <Select
            value={state ?? ""}
            onChange={(e) => {
              setState(e.target.value as typeof state);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {stateAcronyms.map((state) => {
              return (
                <MenuItem
                  value={state}
                  key={state}
                  sx={(theme) => ({
                    height: "48px",
                    "&.Mui-selected": {
                      backgroundColor: theme.palette.purple["100"],
                    },
                    "&:hover": {
                      backgroundColor: theme.palette.purple["100"],
                    },
                  })}
                >
                  <Typography variant="caption" color="primary.main">
                    {state}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
          <Typography
            variant="caption"
            component="div"
            sx={{
              mt: "16px",
              mb: "8px",
            }}
            color={"primary.main"}
          >
            Password (atleast 6 characters)
          </Typography>
          <Input
            value={password}
            placeholder="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Stack direction="row" alignItems="center" mt={"16px"}>
            <Typography variant="body2">
              By entering my email, I agree to the{" "}
              <a
                href="https://www.oanahealth.com/terms-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
              ,{" "}
              <a
                href="https://www.oanahealth.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                href="https://www.oanahealth.com/telehealth-consent"
                target="_blank"
                rel="noopener noreferrer"
              >
                Telehealth Consent
              </a>
              .
            </Typography>
          </Stack>
          <Button
            size="large"
            sx={(theme) => ({
              mt: "32px",
              backgroundColor:
                !password || !state || password.length < 5 || !email
                  ? theme.palette.gray.main
                  : theme.palette.primary.main,
              mb: "16px",
            })}
            disabled={!password || !state || password.length < 5 || !email}
            onClick={async () => {
              if (state) {
                const response: any = await handleSignup({
                  email,
                  password,
                  state,
                  type: redirectUrl?.includes("eflornithine")
                    ? "eflornithine"
                    : redirectUrl?.includes("semaglutide")
                    ? "semaglutide"
                    : undefined,
                });
                if (response) {
                  setShowErrorMessage(response);
                } else {
                  (window as any).gtag_report_webapp_signup_conversion(
                    window.location.href
                  );
                }
              }
            }}
          >
            <Typography
              variant="body1"
              component="div"
              color="primary.contrastText"
              sx={{
                "&&": {
                  fontSize: 16,
                },
              }}
            >
              Continue
            </Typography>
          </Button>
          <Typography
            variant="body1"
            component="div"
            color="primary.main"
            sx={{
              "&&": {
                fontSize: 16,
                px: "32px",
                // mb: "48px",
              },
            }}
          >
            Already have an account?
            <Link
              component={RouterLink}
              sx={{
                ml: "4px",
                fontWeight: 700,
              }}
              to={`/login${redirectUrl ? `?redirect_url=${redirectUrl}` : ""}`}
            >
              Login
            </Link>
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            maxWidth: "300px",
            width: "100%",
            mt: "28px",
          }}
        >
          <Divider
            sx={(theme) => ({
              borderColor: theme.palette.gray.main,
              width: "20%",
            })}
          />

          <Stack
            component="img"
            sx={{
              width: "80px",
              height: "60px",
            }}
            src={LegitScript}
          />
          <Stack
            component="img"
            sx={{
              width: "50px",
              height: "55px",
            }}
            src={SealHipaa2}
          />
          <Divider
            sx={(theme) => ({
              borderColor: theme.palette.gray.main,

              width: "20%",
            })}
          />
        </Stack>

        <Stack alignItems="center" mt={"32px"} mb={"48px"} maxWidth={"312px"}>
          <Stack
            alignItems="flex-start"
            height="134px"
            sx={(theme) => ({
              borderRadius: "10px",
              backgroundColor: theme.palette.purple["100"],
            })}
            p="20px"
          >
            <Typography
              variant="caption"
              sx={{
                fontSize: 13,
                fontWeight: 400,
                lineHeight: 1.3,
              }}
            >
              "Oana Health provides great service! I love how easy and
              accessible it is to get my metformin. Their patient support team
              and doctors are very eager and quick to help whenever I have
              questions."
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: 13,
                fontWeight: 500,
                lineHeight: 1.3,
                mt: "12px",
              }}
            >
              Sarah P.
            </Typography>
            <Stack direction="row" mt="4px">
              <Stack
                sx={{
                  height: "16px",
                  width: "16px",
                }}
                component="img"
                src={Star}
              />
              <Stack
                sx={{
                  height: "18px",
                  width: "18px",
                }}
                component="img"
                src={Star}
              />
              <Stack
                sx={{
                  height: "18px",
                  width: "18px",
                }}
                component="img"
                src={Star}
              />
              <Stack
                sx={{
                  height: "18px",
                  width: "18px",
                }}
                component="img"
                src={Star}
              />
              <Stack
                sx={{
                  height: "18px",
                  width: "18px",
                }}
                component="img"
                src={Star}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
