import React from "react";

import CheckboxOutlinedCheckedGraySvg from "assets/icons/checkbox-outlined-checked-gray.svg";
import CheckboxOutlinedCheckedGreenSvg from "assets/icons/checkbox-outlined-checked-black.svg";
import PatientGreenSvg from "assets/icons/patient-black.svg";
import PatientGraySvg from "assets/icons/patient-gray.svg";
import HeartGraySvg from "assets/icons/heart-gray.svg";
import HeartGreenSvg from "assets/icons/heart-black.svg";
import HomeGreenSvg from "assets/icons/home-black.svg";
import HomeNeutralSvg from "assets/icons/home-neutral.svg";
import MessagesGreenSvg from "assets/icons/messages-black.svg";
import MessagesNeutralSvg from "assets/icons/messages-neutral.svg";
import OrdersGreenSvg from "assets/icons/orders-black.svg";
import OrdersNeutralSvg from "assets/icons/orders-neutral.svg";
import UserGreenSvg from "assets/icons/user-black.svg";
import UserNeutralSvg from "assets/icons/user-neutral.svg";
import Box from "@mui/material/Box";

export const TopLevelRouteToData = {
  adminAllOrders: {
    selectedIcon: CheckboxOutlinedCheckedGreenSvg,
    label: "All Orders",
    baseRoute: "/dashboard/admin/all-orders",
    routeTo: "/dashboard/admin/all-orders",
    mobileRouteTo: undefined,
    unselectedIcon: CheckboxOutlinedCheckedGraySvg,
    showMobile: false,
  },
  adminActiveSubscriptions: {
    selectedIcon: undefined,
    label: "Subscription Queue",
    baseRoute: "/dashboard/admin/active-subscriptions",
    routeTo: "/dashboard/admin/active-subscriptions",
    mobileRouteTo: undefined,
    unselectedIcon: undefined,
    showMobile: false,
  },
  home: {
    selectedIcon: HomeGreenSvg,
    label: "Home",
    baseRoute: "/dashboard/patient/home",
    routeTo: "/dashboard/patient/home",
    mobileRouteTo: undefined,
    unselectedIcon: HomeNeutralSvg,
    showMobile: true,
  },
  adminIntakeQueue: {
    selectedIcon: HeartGreenSvg,
    label: "Patient Queue",
    baseRoute: "/dashboard/admin/patient-queue",
    routeTo: "/dashboard/admin/patient-queue",
    mobileRouteTo: undefined,
    unselectedIcon: HeartGraySvg,
    showMobile: false,
  },
  adminMessagesQueue: {
    selectedIcon: MessagesGreenSvg,
    label: "Messages",
    baseRoute: "/dashboard/admin/messages",
    routeTo: "/dashboard/admin/messages",
    mobileRouteTo: undefined,
    unselectedIcon: MessagesNeutralSvg,
    showMobile: false,
  },
  adminAllPatients: {
    selectedIcon: PatientGreenSvg,
    label: "Patients",
    baseRoute: "/dashboard/admin/all-patients",
    routeTo: "/dashboard/admin/all-patients",
    mobileRouteTo: undefined,
    unselectedIcon: PatientGraySvg,
    showMobile: false,
  },
  adminAccount: {
    selectedIcon: UserGreenSvg,
    label: "Account",
    baseRoute: "/dashboard/admin/account/profile",
    routeTo: "/dashboard/admin/account/profile",
    mobileRouteTo: undefined,
    unselectedIcon: UserNeutralSvg,
    showMobile: false,
  },
  adminUploadOrder: {
    selectedIcon: undefined,
    label: "Order Shipping",
    baseRoute: "/dashboard/admin/order-shipping",
    routeTo: "/dashboard/admin/order-shipping",
    mobileRouteTo: undefined,
    unselectedIcon: undefined,
    showMobile: false,
  },
  messages: {
    selectedIcon: MessagesGreenSvg,
    label: "Messages",
    baseRoute: "/dashboard/patient/messages/*",
    mobileRouteTo: "/dashboard/patient/messages/mobile-nav",
    routeTo: "/dashboard/patient/messages/support",
    unselectedIcon: MessagesNeutralSvg,
    showMobile: true,
  },
  orders: {
    selectedIcon: OrdersGreenSvg,
    label: "Orders",
    baseRoute: "/dashboard/patient/orders",
    routeTo: "/dashboard/patient/orders",
    mobileRouteTo: undefined,
    unselectedIcon: OrdersNeutralSvg,
    showMobile: true,
  },
  account: {
    selectedIcon: UserGreenSvg,
    label: "Account",
    mobileRouteTo: "/dashboard/patient/account/mobile-nav",
    baseRoute: "/dashboard/patient/account/*",
    routeTo: "/dashboard/patient/account/profile",
    unselectedIcon: UserNeutralSvg,
    showMobile: true,
  },
};
export const AccountRouteToData = {
  profile: {
    label: "Profile",
    baseRoute: "/dashboard/patient/account/profile",
  },
  subscriptions: {
    label: "Subscriptions",
    baseRoute: "/dashboard/patient/account/subscriptions",
  },
  paymentMethod: {
    label: "Payment method",
    baseRoute: "/dashboard/patient/account/payment-method",
  },
  passwordReset: {
    label: "Password reset",
    baseRoute: "/dashboard/patient/account/password-reset",
  },
  shippingAddress: {
    label: "Shipping address",
    baseRoute: "/dashboard/patient/account/shipping-address",
  },
};

export const AdminAccountRouteToData = {
  profile: {
    label: "Profile",
    baseRoute: "/dashboard/admin/account/profile",
  },
  passwordReset: {
    label: "Password reset",
    baseRoute: "/dashboard/admin/account/password-reset",
  },
  address: {
    label: "Address",
    baseRoute: "/dashboard/admin/account/address",
  },
  licenses: {
    label: "Medical Licenses",
    baseRoute: "/dashboard/admin/account/licenses",
  },
};

export const MessagesRouteToData = {
  medical: {
    label: "Medical",
    baseRoute: "/dashboard/patient/messages/medical",
  },
  customerSupport: {
    label: "Support",
    baseRoute: "/dashboard/patient/messages/support",
  },
};

export type NavBarItemProps =
  | (typeof TopLevelRouteToData)["account"]
  | (typeof TopLevelRouteToData)["orders"]
  | ((typeof AccountRouteToData)["profile"] & {
      selectedIcon?: undefined;
      unselectedIcon?: undefined;
      routeTo?: undefined;
      mobileRouteTo?: undefined;
    });
