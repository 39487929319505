import "theme/fonts.css";

import React from "react";

import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import boxShadow from "theme/boxShadow";
import palette from "theme/palette";
import typography from "theme/typography";

export const muiTheme = createTheme({
  boxShadow,
  breakpoints: {
    /* eslint-disable sort-keys-fix/sort-keys-fix */
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1360,
      xxl: 1536,
    },
    /* eslint-enable sort-keys-fix/sort-keys-fix */
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          "@media (min-width: 600px)": {
            height: "100px",
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          color: palette.orange["900"],
        },
      },
    },
    // LumosButton
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          ":hover": {
            backgroundColor: "#E1E289",
          },
        },
      },
      variants: [
        {
          props: {
            size: "small",
          },
          style: {
            fontSize: 12,
            minHeight: 24,
          },
        },
        {
          props: {
            size: "medium",
          },
          style: {
            fontSize: 14,
            minHeight: 32,
          },
        },
        {
          props: {
            size: "large",
          },
          style: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 6,
            paddingBottom: 6,
            height: 48,
            borderRadius: 50,
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCheckbox: {
      // defaultProps: {
      //   checkedIcon: <img alt="" src={CheckboxCheckedIcon} />,
      //   icon: <img alt="" src={CheckboxIcon} />,
      // },
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "transparent",
          },
          padding: 0,
          color: "#DDD",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        // Table
        ".MuiDataGrid-columnHeaderTitleContainerContent": {
          flexGrow: 1,
          justifyContent: "center",
          overflow: "initial !important",
        },
        ".MuiPickersCalendarHeader-label": {
          "&&": {
            color: palette.primary.main,
          },
        },

        ".MuiDataGrid-footerContainer": {
          borderTop: "none !important",
          marginRight: "2.5rem",
        },

        // Table -> Column: Hide number badge which shows up when multiple filters are selected
        ".MuiDataGrid-iconButtonContainer .MuiBadge-anchorOriginTopRightRectangular":
          {
            display: "none",
          },

        ".MuiDataGrid-iconButtonContainer .MuiButtonBase-root:hover": {
          backgroundColor: "inherit",
        },

        ".MuiDataGrid-root": {
          backgroundColor: palette.neutral["100"],
          boxShadow: boxShadow.soft,
        },

        ".MuiDataGrid-root .MuiDataGrid-cell": {
          whiteSpace: "normal",
          wordWrap: "break-word",
        },

        // Remove focus on click for header and cells
        ".MuiDataGrid-root .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },

        ".MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },

        ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
          outline: "none !important",
        },

        ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
          outline: "none !important",
        },

        // Show on hover
        ".MuiDataGrid-row .MuiDataGrid-cell .show-on-hover": {
          display: "none",
        },

        // Source
        '.MuiDataGrid-row .MuiDataGrid-cell[data-field="sourceField"] div': {
          opacity: "40%",
        },

        // Show on hover (hover)
        ".MuiDataGrid-row:hover .MuiDataGrid-cell .show-on-hover": {
          display: "flex",
        },

        // Source (hover)
        '.MuiDataGrid-row:hover .MuiDataGrid-cell[data-field="sourceField"] div':
          {
            opacity: "100%",
          },

        // Toolbar button styling
        ".MuiDataGrid-toolbar .MuiButtonBase-root": {
          "&:hover": {
            backgroundColor: "inherit",
            // color: palette.neutral["900"],
          },
          // color: palette.neutral["500"],
        },
        ".MuiDataGridPanel-root": {
          boxShadow: boxShadow.soft,
        },

        // Datepicker styling
        ".MuiDateRangePickerDay-rangeIntervalDayHighlight": {
          "&&": {
            background: palette.yellow[200],
          },
        },
        ".MuiDateRangePickerDay-rangeIntervalDayHighlightEnd": {
          "&&": {
            borderBottomRightRadius: "4px",
            borderTopRightRadius: "4px",
          },
        },
        ".MuiDateRangePickerDay-rangeIntervalDayHighlightStart": {
          "&&": {
            borderBottomLeftRadius: "4px",
            borderTopLeftRadius: "4px",
          },
        },
        ".MuiDateRangePickerDay-rangeIntervalDayPreviewEnd": {
          "&&": {
            borderBottomRightRadius: "4px",
            borderTopRightRadius: "4px",
          },
        },
        ".MuiDateRangePickerDay-rangeIntervalDayPreviewStart": {
          "&&": {
            borderBottomLeftRadius: "4px",
            borderTopLeftRadius: "4px",
          },
        },
        ".MuiDateRangePickerDay-root": {
          "&& .Mui-selected": {
            // background: palette.mainGradient["100%-horizontal"],
            borderRadius: "4px",
          },
          "&&:first-of-type": {
            ".MuiDateRangePickerDay-rangeIntervalDayPreview": {
              borderBottomLeftRadius: "4px",
              borderBottomRightRadius: "0px",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "0px",
            },
            borderBottomLeftRadius: "4px",
            borderTopLeftRadius: "4px",
          },
          "&&:last-of-type": {
            ".MuiDateRangePickerDay-rangeIntervalDayPreview": {
              borderBottomRightRadius: "4px",
              borderTopRightRadius: "4px",
            },
            borderBottomRightRadius: "4px",
            borderTopRightRadius: "4px",
          },
          "&.MuiDateRangePickerDay-rangeIntervalDayPreview": {
            borderRadius: "0px",
          },
        },

        ".MuiPickerStaticWrapper-content > div > div > div:not(:last-of-type)":
          {
            // There currently isn't a great way to override the border for MuiDatePickerRange
            // This CSS is a temporary work around. Tracking issue here https://github.com/mui/mui-x/issues/4673
            borderRight: 0,
          },
        ".MuiPickersDay-root": {
          "&&": {
            borderRadius: "4px",
          },
          "&&.Mui-selected": {
            // background: palette.mainGradient["100%-horizontal"],
          },
        },

        body: {
          fontSize: "12px",
          lineHeight: 1.4,
          margin: "0px !important",
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          border: `2px solid ${palette.gray.main}`,
          borderRadius: "0.5rem",
          height: "54px",
          "&.Mui-focused": {
            border: `2px solid ${palette.primary.main}`,
          },
          "& fieldset": {
            border: `2px solid ${palette.primary.main}`,
          },
        },
        input: {
          paddingRight: 16,
          paddingLeft: 16,
          paddingTop: 18,
          paddingBottom: 18,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "54px",
          "& fieldset": {
            border: `2px solid ${palette.gray.main}`,
            borderRadius: "0.5rem",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: "54px",
          "&& fieldset": {
            border: `2px solid ${palette.gray.main}`,
            borderRadius: "0.5rem",
          },
          "&&:hover fieldset": {
            border: `2px solid ${palette.primary.main}`,
            borderRadius: "0.5rem", // Ensure the focus state also retains the border radius
          },
          "&&:focus fieldset": {
            border: `2px solid ${palette.primary.main}`,
            borderRadius: "0.5rem", // Ensure the focus state also retains the border radius
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: boxShadow.navbar,
        },
        root: {
          marginTop: "0.5rem",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "none",
          },
          padding: 0,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          "@media (min-width: 600px)": {
            display: "flex",
            justifyContent: "space-between",
            minHeight: "52px",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.neutral["100"],
          boxShadow: boxShadow.medium,
          color: "black",
          fontSize: 12,
          fontWeight: 400,
          lineHeight: 1.4,
        },
      },
    },
  },
  palette,
  spacing: (factor: number) => `${factor * 0.5}rem`,
  typography,
});
