import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Link,
  Typography,
  Input,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/system";
import { useIsLoggedIn } from "components/CheckAuthComponent";
import { useAuth } from "contexts/AuthContext";
import ScribbleArrowDown from "assets/icons/scribble-arrow-down-welcome.svg";
import CheckupGreen from "assets/icons/checkup-black.svg";
import StethoscopeGreen from "assets/icons/stethoscope-black.svg";
import MessagesThinGreen from "assets/icons/messages-thin-black.svg";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";
import mixpanel from "mixpanel-browser";

export default function Completion({ onClick }: { onClick: () => void }) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  if (!currentUser) {
    navigate("https://www.oanahealth.com/login");
  }

  const isMobile = useMediaQuery("(max-width:600px)");

  const { setHorizontalTopValues } = useHorizontalTopHeader();

  useEffect(() => {
    mixpanel.track("Page View", {
      intakeQuestion: "FINISH_CHECKOUT",
    });
  }, []);

  useEffect(() => {
    setHorizontalTopValues({
      customHeaderTitle: "Order submitted",
      onClickBack: undefined,
      bottomPercentageCompletion: undefined,
      showOnDesktop: true,
      hideMobileToolbar: true,
      showBorderBoxShadow: true,
    });
  }, [isMobile, setHorizontalTopValues, navigate]);

  return (
    <Stack
      alignItems="center"
      justifyContent="flex-start"
      p={isMobile ? "32px" : "40px"}
      pb={"60px"}
      flexGrow={1}
      sx={(theme) => ({
        overflowY: "scroll",
        backgroundColor: "#FFF",
      })}
    >
      <Stack maxWidth="420px">
        <Stack alignItems="flex-start" direction="row">
          <Typography variant="h1" color={"primary.main"}>
            You’ve completed your visit!
          </Typography>
        </Stack>
        <Stack alignItems="flex-start" mt={"32px"} mb={"16px"} direction="row">
          <Typography
            variant="body2"
            color={"primary.main"}
            sx={{
              fontFamily: "Kollektif",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          >
            Here’s what’s next.
          </Typography>
          <Stack
            component="div"
            sx={{
              position: "relative",
            }}
          >
            <Stack
              component="img"
              sx={{
                position: "absolute",
                top: "15px",
                left: "14px",
              }}
              src={ScribbleArrowDown}
            ></Stack>
          </Stack>
        </Stack>
        <Stack
          sx={(theme) => ({
            backgroundColor: theme.palette.neutral["100"],
            borderRadius: "10px",
            paddingTop: "32px",
            paddingBottom: "32px",
            paddingRight: "30px",
            paddingLeft: "32px",
          })}
        >
          <Stack direction="row">
            <Stack
              sx={(theme) => ({
                alignItems: "center",
                justifyContent: "center",
                height: "60px",
                width: "60px",
                minWidth: "60px",
                borderRadius: "100px",
                border: `1px solid ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.purple["100"],
                mr: "16px",
              })}
            >
              <Stack
                sx={{
                  height: "34px",
                  width: "34px",
                }}
                component="img"
                src={CheckupGreen}
              />
            </Stack>
            <Stack>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                }}
                color={"primary.main"}
              >
                1. Doctor review
              </Typography>
              <Typography variant="body2" mt={"8px"} color="primary.main">
                Now it’s your provider’s turn. A doctor will review your online
                visit and evaluate whether this treatment is suitable for you.
                We’ll reach out to you for any follow up questions.
              </Typography>
            </Stack>
          </Stack>
          <Stack mt={"16px"} direction="row">
            <Stack
              sx={(theme) => ({
                alignItems: "center",
                justifyContent: "center",
                height: "60px",
                width: "60px",
                minWidth: "60px",
                borderRadius: "100px",
                border: `1px solid ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.purple["100"],
                mr: "16px",
              })}
            >
              <Stack
                sx={{
                  height: "38px",
                  width: "38px",
                }}
                component="img"
                src={StethoscopeGreen}
              />
            </Stack>
            <Stack>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                }}
                color={"primary.main"}
              >
                2. Confirm treatment
              </Typography>
              <Typography variant="body2" mt={"8px"} color="primary.main">
                If everything looks good, your provider will approve your
                treatment, and we’ll send you a confirmation via email.
              </Typography>
            </Stack>
          </Stack>
          <Stack mt={"16px"} direction="row">
            <Stack
              sx={(theme) => ({
                alignItems: "center",
                justifyContent: "center",
                height: "60px",
                width: "60px",
                minWidth: "60px",
                borderRadius: "100px",
                border: `1px solid ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.purple["100"],
                mr: "16px",
              })}
            >
              <Stack
                sx={{
                  height: "60px",
                  width: "60px",
                }}
                component="img"
                src={MessagesThinGreen}
              />
            </Stack>
            <Stack>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                }}
                color={"primary.main"}
              >
                3. Shipped to your home
              </Typography>
              <Typography variant="body2" mt={"8px"} color="primary.main">
                Our partner pharmacy will send your treatment directly to your
                home
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Button
          size="large"
          sx={(theme) => ({
            mt: "32px",
            backgroundColor: theme.palette.primary.main,
          })}
          onClick={onClick}
        >
          <Typography
            variant="body1"
            component="div"
            color="primary.contrastText"
            sx={{
              "&&": {
                fontSize: 16,
              },
            }}
          >
            Go to my dashboard
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );
}
