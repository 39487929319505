import mixpanel from "mixpanel-browser";

export const isProduction = import.meta.env.VITE_APP_ENVIRONMENT === "PROD";

mixpanel.init("f3626768d9b2bb5446540a6c55485b1a", {
  debug: !isProduction,
  track_pageview: true,
  persistence: "localStorage",
});

export default mixpanel;
