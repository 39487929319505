import { useQuery } from "@apollo/client";
import { getCurrentUserQuery } from "gql/User.gql";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

type HorizontalHeaderValues = {
  customHeaderTitle?: string;
  onClickBack?: () => void;
  bottomPercentageCompletion?: number;
  showOnDesktop: boolean;
  hideMobileToolbar?: boolean;
  showBorderBoxShadow?: boolean;
};
export interface HorizontalTopHeaderType {
  customHeaderTitle?: string;
  onClickBack?: () => void;
  bottomPercentageCompletion?: number;
  showOnDesktop: boolean;
  hideMobileToolbar?: boolean;
  showBorderBoxShadow?: boolean;
  setHorizontalTopValues: React.Dispatch<
    React.SetStateAction<HorizontalHeaderValues>
  >;
}

const HorizontalTopHeaderContext = createContext<
  HorizontalTopHeaderType | undefined
>(undefined);

export const HorizontalTopHeaderProvider: React.FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const [horizontalTopValue, setHorizontalTopValues] =
    useState<HorizontalHeaderValues>({
      customHeaderTitle: undefined,
      onClickBack: undefined,
      bottomPercentageCompletion: undefined,
      hideMobileToolbar: undefined,
      showOnDesktop: false,
      showBorderBoxShadow: undefined,
    });

  return (
    <HorizontalTopHeaderContext.Provider
      value={{
        ...horizontalTopValue,
        setHorizontalTopValues,
      }}
    >
      {children}
    </HorizontalTopHeaderContext.Provider>
  );
};

export const useHorizontalTopHeader = (): HorizontalTopHeaderType => {
  const context = useContext(HorizontalTopHeaderContext);
  if (context === undefined) {
    throw new Error(
      "useHorizontalTopHeader must be used within an HorizontalTopHeaderProvider"
    );
  }
  return context;
};
