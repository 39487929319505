import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Link,
  Typography,
  useMediaQuery,
  Radio,
  Stack,
  CircularProgress,
} from "@mui/material";
import {
  GetLatestQuestionsAndResponseForGroupsQuery,
  OrderBillingPeriod,
  ProductEnum,
} from "gql-gen/graphql";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";
import { useMutation } from "@apollo/client";
import { updateIncompleteOrderBillingPeriod } from "gql/Intakes.gql";
import { useDebounce } from "use-debounce";
import mixpanel from "mixpanel-browser";

type Product =
  GetLatestQuestionsAndResponseForGroupsQuery["me"]["intakeTemplate"]["product"];

export default function SubscriptionSelect({
  onBack,
  bottomPercentageCompletion,
  onContinue,
  product,
  orderId,
  billingPeriodBackend,
}: {
  orderId?: number;
  bottomPercentageCompletion?: number;
  onBack: () => void;
  product: Product;
  billingPeriodBackend?: OrderBillingPeriod;
  onContinue: () => void;
}) {
  const navigate = useNavigate();
  console.log({ orderId });
  const { currentUser } = useAuth();

  useEffect(() => {
    mixpanel.track("Page View", {
      product: product.productEnum,
      intakeQuestion: "SELECT_SUBSCRIPTION",
    });
  }, []);

  const [loadingRaw, setLoading] = useState(false);

  const [loading] = useDebounce(loadingRaw, 300);

  const isMonthlyOnly = [
    ProductEnum.ORAL_SEMAGLUTIDE,
    ProductEnum.SEMAGLUTIDE_SHOT,
    ProductEnum.METFORMIN_SEMAGLUTIDE_PACK,
  ].includes(product.productEnum);

  const isEflornithineMembership = [
    ProductEnum.HAIRLESS_HYPE,
    ProductEnum.EFLORNITHINE,
  ].includes(product.productEnum);
  const [billingPeriod, setBillingPeriod] = useState(
    isMonthlyOnly
      ? OrderBillingPeriod.MONTHLY
      : billingPeriodBackend ?? OrderBillingPeriod.QUARTERLY
  );

  const [membership, setMembership] = useState(
    [OrderBillingPeriod.MONTHLY, OrderBillingPeriod.QUARTERLY].includes(
      billingPeriod
    )
  );

  const [updateIncompleteOrderBillingPeriodCallback] = useMutation(
    updateIncompleteOrderBillingPeriod
  );

  useEffect(() => {
    document
      .getElementById("treatment-plan-top")
      ?.scrollIntoView({ block: "end" });
  }, []);

  if (!currentUser) {
    navigate("https://www.oanahealth.com/login");
  }

  const isMobile = useMediaQuery("(max-width:600px)");

  const { setHorizontalTopValues } = useHorizontalTopHeader();

  useEffect(() => {
    setHorizontalTopValues({
      customHeaderTitle: "Treatment plan",
      onClickBack: onBack,
      bottomPercentageCompletion,
      showOnDesktop: true,
      hideMobileToolbar: true,
    });
  }, [setHorizontalTopValues, bottomPercentageCompletion, onBack]);

  const amountSaved =
    (product.priceInCentsMonthlyBilling -
      product.priceInCentsQuarterlyBilling) /
    100;

  return (
    <>
      <div
        id="treatment-plan-top"
        style={{ marginBottom: isMobile ? "32px" : "40px" }}
      />
      <Stack
        alignItems="center"
        justifyContent="flex-start"
        id="checkout-wrapper"
        pb={"60px"}
        flexGrow={1}
        sx={(theme) => ({
          overflowY: "scroll",
          backgroundColor: "#FFF",
          marginTop: isMobile ? "-32px" : "-40px",
        })}
      >
        <Stack maxWidth="420px">
          <Stack alignItems="flex-start" direction="row">
            <Typography variant="h1" color={"primary.main"}>
              Select a treatment plan
            </Typography>
          </Stack>
          {!isMonthlyOnly && !isEflornithineMembership && (
            <Stack
              direction="row"
              alignItems="flex-start"
              mb={"8px"}
              mt={"32px"}
              justifyContent="space-between"
              sx={(theme) => ({
                cursor: "pointer",
                borderRadius: "10px",
                border: `1px solid ${
                  billingPeriod === OrderBillingPeriod.QUARTERLY
                    ? theme.palette.primary.main
                    : theme.palette.gray.medium
                }`,
                p: "16px",
              })}
              onClick={async () => {
                if (orderId) {
                  setLoading(true);
                  setBillingPeriod(OrderBillingPeriod.QUARTERLY);
                  await updateIncompleteOrderBillingPeriodCallback({
                    variables: {
                      orderId,
                      orderBillingPeriod: OrderBillingPeriod.QUARTERLY,
                    },
                    refetchQueries: ["GetOrderState"],
                  });
                  setLoading(false);
                }
              }}
            >
              <Stack>
                <Typography
                  component="div"
                  variant="caption"
                  flex={1}
                  mb={"8px"}
                  sx={{
                    fontWeight: "700",
                    fontSize: "18px",
                  }}
                >
                  Every 3 months
                </Typography>
                <Stack
                  component="div"
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  justifyContent="start"
                >
                  <Typography
                    component="div"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      mr: "4px",
                    }}
                  >
                    {`$${(product.priceInCentsQuarterlyBilling / 100) * 3}${
                      product.priceInCentsQuarterlyBilling2xDose
                        ? ` - $${
                            (product.priceInCentsQuarterlyBilling2xDose / 100) *
                            3
                          }`
                        : ""
                    } billed every 3 months. Shipped every 3 months.`}
                  </Typography>
                </Stack>
                {product.priceInCentsMonthlyBilling2xDose !== 0 && (
                  <Typography variant="body2" color="primary.main" mb="8px">
                    {`(pricing depends on dosage)`}
                  </Typography>
                )}
                {amountSaved > 0 && (
                  <Stack
                    sx={{
                      mt: "8px",
                    }}
                    direction={"row"}
                  >
                    <Typography
                      component="div"
                      variant="body1"
                      sx={{
                        fontSize: "14px",
                        mr: "6px",
                        fontWeight: "400",
                        textDecoration: "line-through",
                      }}
                    >
                      {`$${product.priceInCentsMonthlyBilling / 100}`}
                    </Typography>
                    <Typography
                      component="div"
                      variant="body1"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#84A1E5",
                      }}
                    >
                      {`$${product.priceInCentsQuarterlyBilling / 100}${
                        product.priceInCentsQuarterlyBilling2xDose
                          ? ` - $${
                              product.priceInCentsQuarterlyBilling2xDose / 100
                            }`
                          : ""
                      }/mo`}
                    </Typography>
                  </Stack>
                )}
              </Stack>
              <Radio
                value={OrderBillingPeriod.QUARTERLY}
                checked={billingPeriod === OrderBillingPeriod.QUARTERLY}
                onSelect={async () => {
                  if (orderId) {
                    setLoading(true);
                    setBillingPeriod(OrderBillingPeriod.QUARTERLY);
                    await updateIncompleteOrderBillingPeriodCallback({
                      variables: {
                        orderId,
                        orderBillingPeriod: OrderBillingPeriod.QUARTERLY,
                      },
                      refetchQueries: ["GetOrderState"],
                    });
                    setLoading(false);
                  }
                }}
              />
            </Stack>
          )}
          {!isEflornithineMembership && (
            <Stack mb={"40px"}>
              <Stack
                direction="row"
                alignItems="flex-start"
                mb={"8px"}
                mt={isMonthlyOnly ? "32px" : undefined}
                justifyContent="space-between"
                sx={(theme) => ({
                  cursor: "pointer",
                  borderRadius: "10px",
                  border: `1px solid ${
                    billingPeriod === OrderBillingPeriod.MONTHLY
                      ? theme.palette.primary.main
                      : theme.palette.gray.medium
                  }`,
                  p: "16px",
                })}
                onClick={async () => {
                  if (orderId) {
                    setLoading(true);
                    setBillingPeriod(OrderBillingPeriod.MONTHLY);
                    await updateIncompleteOrderBillingPeriodCallback({
                      variables: {
                        orderId,
                        orderBillingPeriod: OrderBillingPeriod.MONTHLY,
                      },
                      refetchQueries: ["GetOrderState"],
                    });
                    setLoading(false);
                  }
                }}
              >
                <Stack>
                  <Typography
                    component="div"
                    variant="caption"
                    flex={1}
                    mb={"8px"}
                    sx={{
                      fontWeight: "700",
                      fontSize: "18px",
                    }}
                  >
                    Every month
                  </Typography>
                  <Typography
                    component="div"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                    mb={
                      product.priceInCentsMonthlyBilling2xDose !== 0
                        ? "4px"
                        : "8px"
                    }
                    flex={1}
                  >
                    {`$${product.priceInCentsMonthlyBilling / 100}${
                      product.priceInCentsMonthlyBilling2xDose
                        ? ` - $${
                            product.priceInCentsMonthlyBilling2xDose / 100
                          }`
                        : ""
                    } billed every month. Shipped every month.`}
                  </Typography>
                  {product.priceInCentsMonthlyBilling2xDose !== 0 && (
                    <Typography variant="body2" color="primary.main" mb="8px">
                      {`(pricing depends on dosage)`}
                    </Typography>
                  )}
                </Stack>

                <Radio
                  value={OrderBillingPeriod.MONTHLY}
                  checked={billingPeriod === OrderBillingPeriod.MONTHLY}
                  onSelect={async () => {
                    if (orderId) {
                      setLoading(true);
                      setBillingPeriod(OrderBillingPeriod.MONTHLY);
                      await updateIncompleteOrderBillingPeriodCallback({
                        variables: {
                          orderId,
                          orderBillingPeriod: OrderBillingPeriod.MONTHLY,
                        },
                        refetchQueries: ["GetOrderState"],
                      });
                      setLoading(false);
                    }
                  }}
                />
              </Stack>

              <Typography
                component="div"
                variant="caption"
                color="gray.dark"
                flex={1}
              >
                You can pause or cancel anytime
              </Typography>
            </Stack>
          )}
          {isEflornithineMembership && (
            <>
              <Stack
                direction="row"
                alignItems="flex-start"
                mb={"8px"}
                mt={"32px"}
                justifyContent="space-between"
                sx={(theme) => ({
                  cursor: "pointer",
                  borderRadius: "10px",
                  border: `1px solid ${
                    billingPeriod === OrderBillingPeriod.ONE_TIME
                      ? theme.palette.primary.main
                      : theme.palette.gray.medium
                  }`,
                  p: "16px",
                })}
                onClick={async () => {
                  if (orderId) {
                    setLoading(true);
                    setMembership(false);
                    setBillingPeriod(OrderBillingPeriod.ONE_TIME);
                    await updateIncompleteOrderBillingPeriodCallback({
                      variables: {
                        orderId,
                        orderBillingPeriod: OrderBillingPeriod.ONE_TIME,
                      },
                      refetchQueries: ["GetOrderState"],
                    });
                    setLoading(false);
                  }
                }}
              >
                <Stack>
                  <Typography
                    component="div"
                    variant="caption"
                    flex={1}
                    mb={"12px"}
                    sx={{
                      fontWeight: "700",
                      fontSize: "18px",
                    }}
                  >
                    One time treatment
                  </Typography>
                  <Stack
                    component="div"
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                    justifyContent="start"
                  >
                    <Typography
                      component="div"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        mr: "4px",
                      }}
                    >
                      {"20 gram bottle billed and shipped once"}
                    </Typography>
                  </Stack>
                  <Stack
                    component="div"
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                    justifyContent="start"
                    mt={"8px"}
                  >
                    <Typography
                      component="div"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        mr: "4px",
                        color: "gray.dark",
                      }}
                    >
                      {
                        "For those wanting to try the treatment for the first time"
                      }
                    </Typography>
                  </Stack>
                  <Typography
                    component="div"
                    variant="caption"
                    flex={1}
                    mt={"12px"}
                    sx={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    $89 one time fee
                  </Typography>
                </Stack>
                <Radio
                  value={OrderBillingPeriod.ONE_TIME}
                  checked={billingPeriod === OrderBillingPeriod.ONE_TIME}
                  onSelect={async () => {
                    if (orderId) {
                      setLoading(true);
                      setMembership(false);
                      setBillingPeriod(OrderBillingPeriod.ONE_TIME);
                      await updateIncompleteOrderBillingPeriodCallback({
                        variables: {
                          orderId,
                          orderBillingPeriod: OrderBillingPeriod.ONE_TIME,
                        },
                        refetchQueries: ["GetOrderState"],
                      });
                      setLoading(false);
                    }
                  }}
                />
              </Stack>
              <Stack mb={"40px"} mt={"12px"}>
                {/* <Stack
                  direction="row"
                  alignItems="flex-start"
                  mt={isMonthlyOnly ? "32px" : undefined}
                  justifyContent="space-between"
                  sx={(theme) => ({
                    cursor: "pointer",
                    borderRadius: "10px",
                    border: `1px solid ${
                      [
                        OrderBillingPeriod.MONTHLY,
                        OrderBillingPeriod.QUARTERLY,
                      ].includes(billingPeriod)
                        ? theme.palette.primary.main
                        : theme.palette.gray.medium
                    }`,
                    p: "16px",
                  })}
                  onClick={async () => {
                    if (orderId) {
                      setLoading(true);
                      setMembership(true);
                      if (billingPeriod === OrderBillingPeriod.ONE_TIME) {
                        setBillingPeriod(OrderBillingPeriod.MONTHLY);
                        await updateIncompleteOrderBillingPeriodCallback({
                          variables: {
                            orderId,
                            orderBillingPeriod: OrderBillingPeriod.MONTHLY,
                          },
                          refetchQueries: ["GetOrderState"],
                        });
                      }
                      setLoading(false);
                    }
                  }}
                >
                  <Stack>
                    <Typography
                      component="div"
                      variant="caption"
                      flex={1}
                      mb={"12px"}
                      sx={{
                        fontWeight: "700",
                        fontSize: "18px",
                      }}
                    >
                      Subscribe and Save
                    </Typography>
                    <Stack
                      component="div"
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                      justifyContent="start"
                    >
                      <Typography
                        component="div"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          mr: "4px",
                        }}
                      >
                        {"Discounted refills and flexible bottle sizes"}
                      </Typography>
                    </Stack>
                    <Stack
                      component="div"
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                      justifyContent="start"
                      mt={"8px"}
                    >
                      <Typography
                        component="div"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          mr: "4px",
                          color: "gray.dark",
                        }}
                      >
                        {
                          "For those who plan for long-term use and prefer flexible refill options"
                        }
                      </Typography>
                    </Stack>
                  </Stack>
                  <Radio
                    value={true}
                    checked={[
                      OrderBillingPeriod.MONTHLY,
                      OrderBillingPeriod.QUARTERLY,
                    ].includes(billingPeriod)}
                    onSelect={async () => {
                      if (orderId) {
                        setLoading(true);
                        setMembership(true);
                        if (billingPeriod === OrderBillingPeriod.ONE_TIME) {
                          setBillingPeriod(OrderBillingPeriod.MONTHLY);
                          await updateIncompleteOrderBillingPeriodCallback({
                            variables: {
                              orderId,
                              orderBillingPeriod: OrderBillingPeriod.MONTHLY,
                            },
                            refetchQueries: ["GetOrderState"],
                          });
                        }
                        setLoading(false);
                      }
                    }}
                  />
                </Stack> */}
                <Stack
                  direction="row"
                  alignItems="flex-start"
                  mb={"8px"}
                  // mt={"32px"}
                  justifyContent="space-between"
                  sx={(theme) => ({
                    cursor: "pointer",
                    borderRadius: "10px",
                    border: `1px solid ${
                      billingPeriod === OrderBillingPeriod.QUARTERLY
                        ? theme.palette.primary.main
                        : theme.palette.gray.medium
                    }`,
                    p: "16px",
                  })}
                  onClick={async () => {
                    if (orderId) {
                      setLoading(true);
                      setBillingPeriod(OrderBillingPeriod.QUARTERLY);
                      await updateIncompleteOrderBillingPeriodCallback({
                        variables: {
                          orderId,
                          orderBillingPeriod: OrderBillingPeriod.QUARTERLY,
                        },
                        refetchQueries: ["GetOrderState"],
                      });
                      setLoading(false);
                    }
                  }}
                >
                  <Stack>
                    <Typography
                      component="div"
                      variant="caption"
                      flex={1}
                      mb={"8px"}
                      sx={{
                        fontWeight: "700",
                        fontSize: "18px",
                      }}
                    >
                      3 month Subscription
                    </Typography>
                    <Stack
                      component="div"
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                      justifyContent="start"
                    >
                      <Typography
                        component="div"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          mr: "4px",
                          mb: "4px",
                        }}
                      >
                        {
                          "Large 60 gram bottle billed every 3 months. Shipped every 3 months."
                        }
                      </Typography>
                    </Stack>
                    <Stack
                      component="div"
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                      justifyContent="start"
                      mt={"8px"}
                    >
                      <Typography
                        component="div"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          mr: "4px",
                          color: "gray.dark",
                        }}
                      >
                        {"For those who plan for long-term use"}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems={"flex-start"}
                      mt={"12px"}
                    >
                      <Typography
                        component="span"
                        variant="caption"
                        sx={{
                          mr: "12px",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        $138 every 3 months
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#84A1E5",
                        }}
                      >
                        {`$${product.priceInCentsQuarterlyBilling / 100}${
                          product.priceInCentsQuarterlyBilling2xDose
                            ? ` - $${
                                product.priceInCentsQuarterlyBilling2xDose / 100
                              }`
                            : ""
                        }/mo`}
                      </Typography>
                    </Stack>
                    {amountSaved > 0 && (
                      <Stack
                        sx={{
                          mt: "8px",
                        }}
                        direction={"row"}
                      ></Stack>
                    )}
                  </Stack>
                  <Radio
                    value={OrderBillingPeriod.QUARTERLY}
                    checked={billingPeriod === OrderBillingPeriod.QUARTERLY}
                    onSelect={async () => {
                      if (orderId) {
                        setLoading(true);
                        setBillingPeriod(OrderBillingPeriod.QUARTERLY);
                        await updateIncompleteOrderBillingPeriodCallback({
                          variables: {
                            orderId,
                            orderBillingPeriod: OrderBillingPeriod.QUARTERLY,
                          },
                          refetchQueries: ["GetOrderState"],
                        });
                        setLoading(false);
                      }
                    }}
                  />
                </Stack>

                {/* {billingPeriod !== OrderBillingPeriod.ONE_TIME && (
                  <>
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{
                        my: "12px",
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                      flex={1}
                    >
                      Select bottle size:
                    </Typography>
                    <Stack direction={"row"} spacing={"12px"}>
                      <Stack
                        direction="row"
                        flex={1}
                        alignItems="flex-start"
                        mb={"8px"}
                        mt={isMonthlyOnly ? "32px" : undefined}
                        justifyContent="space-between"
                        sx={(theme) => ({
                          cursor: "pointer",
                          borderRadius: "10px",
                          border: `1px solid ${
                            billingPeriod === OrderBillingPeriod.MONTHLY
                              ? theme.palette.primary.main
                              : theme.palette.gray.medium
                          }`,
                          p: "16px",
                        })}
                        onClick={async () => {
                          if (orderId) {
                            setLoading(true);
                            setBillingPeriod(OrderBillingPeriod.MONTHLY);
                            await updateIncompleteOrderBillingPeriodCallback({
                              variables: {
                                orderId,
                                orderBillingPeriod: OrderBillingPeriod.MONTHLY,
                              },
                              refetchQueries: ["GetOrderState"],
                            });
                            setLoading(false);
                          }
                        }}
                      >
                        <Stack>
                          <Typography
                            component="div"
                            variant="caption"
                            flex={1}
                            mb={"8px"}
                            sx={{
                              fontWeight: "700",
                              fontSize: "18px",
                            }}
                          >
                            Every month
                          </Typography>
                          <Typography
                            component="div"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                            mb={
                              product.priceInCentsMonthlyBilling2xDose !== 0
                                ? "4px"
                                : "8px"
                            }
                            flex={1}
                          >
                            {`$${product.priceInCentsMonthlyBilling / 100}${
                              product.priceInCentsMonthlyBilling2xDose
                                ? ` - $${
                                    product.priceInCentsMonthlyBilling2xDose /
                                    100
                                  }`
                                : ""
                            } billed every month. Shipped every month.`}
                          </Typography>
                          {product.priceInCentsMonthlyBilling2xDose !== 0 && (
                            <Typography
                              variant="body2"
                              color="primary.main"
                              mb="8px"
                            >
                              {`(pricing depends on dosage)`}
                            </Typography>
                          )}
                        </Stack>

                        <Radio
                          value={OrderBillingPeriod.MONTHLY}
                          checked={billingPeriod === OrderBillingPeriod.MONTHLY}
                          onSelect={async () => {
                            if (orderId) {
                              setLoading(true);
                              setBillingPeriod(OrderBillingPeriod.MONTHLY);
                              await updateIncompleteOrderBillingPeriodCallback({
                                variables: {
                                  orderId,
                                  orderBillingPeriod:
                                    OrderBillingPeriod.MONTHLY,
                                },
                                refetchQueries: ["GetOrderState"],
                              });
                              setLoading(false);
                            }
                          }}
                        />
                      </Stack>

                      <Stack
                        flex={1}
                        direction="row"
                        alignItems="flex-start"
                        mb={"8px"}
                        mt={"32px"}
                        justifyContent="space-between"
                        sx={(theme) => ({
                          cursor: "pointer",
                          borderRadius: "10px",
                          border: `1px solid ${
                            billingPeriod === OrderBillingPeriod.QUARTERLY
                              ? theme.palette.primary.main
                              : theme.palette.gray.medium
                          }`,
                          p: "16px",
                        })}
                        onClick={async () => {
                          if (orderId) {
                            setLoading(true);
                            setBillingPeriod(OrderBillingPeriod.QUARTERLY);
                            await updateIncompleteOrderBillingPeriodCallback({
                              variables: {
                                orderId,
                                orderBillingPeriod:
                                  OrderBillingPeriod.QUARTERLY,
                              },
                              refetchQueries: ["GetOrderState"],
                            });
                            setLoading(false);
                          }
                        }}
                      >
                        <Stack>
                          <Typography
                            component="div"
                            variant="caption"
                            flex={1}
                            mb={"8px"}
                            sx={{
                              fontWeight: "700",
                              fontSize: "16px",
                            }}
                          >
                            Every 3 months
                          </Typography>
                          <Typography
                            component="div"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              mr: "-12px",
                            }}
                          >
                            {`60 gram bottle`}
                          </Typography>
                          <Stack
                            component="div"
                            display="flex"
                            flexDirection="row"
                            alignItems="flex-start"
                            justifyContent="start"
                          >
                            <Typography
                              component="div"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                mr: "-12px",
                              }}
                            >
                              {`Shipped every 3 months.`}
                            </Typography>
                          </Stack>
                          {amountSaved > 0 && (
                            <Stack
                              sx={{
                                mt: "8px",
                              }}
                              direction={"row"}
                            >
                              <Typography
                                component="div"
                                variant="body1"
                                sx={{
                                  fontSize: "14px",
                                  mr: "6px",
                                  fontWeight: "400",
                                  textDecoration: "line-through",
                                }}
                              >
                                {`$${product.priceInCentsMonthlyBilling / 100}`}
                              </Typography>
                              <Typography
                                component="div"
                                variant="body1"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  color: "#84A1E5",
                                }}
                              >
                                {`$${
                                  product.priceInCentsQuarterlyBilling / 100
                                }${
                                  product.priceInCentsQuarterlyBilling2xDose
                                    ? ` - $${
                                        product.priceInCentsQuarterlyBilling2xDose /
                                        100
                                      }`
                                    : ""
                                }/mo`}
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                        <Radio
                          value={OrderBillingPeriod.QUARTERLY}
                          checked={
                            billingPeriod === OrderBillingPeriod.QUARTERLY
                          }
                          onSelect={async () => {
                            if (orderId) {
                              setLoading(true);
                              setBillingPeriod(OrderBillingPeriod.QUARTERLY);
                              await updateIncompleteOrderBillingPeriodCallback({
                                variables: {
                                  orderId,
                                  orderBillingPeriod:
                                    OrderBillingPeriod.QUARTERLY,
                                },
                                refetchQueries: ["GetOrderState"],
                              });
                              setLoading(false);
                            }
                          }}
                        />
                      </Stack>
                    </Stack>
                  </>
                )} */}
              </Stack>
            </>
          )}
          <Stack
            sx={
              isMobile
                ? {
                    // mt: "40px",
                    // position: "sticky",
                    // bottom: 0,
                    // backgroundColor: "#FFF",
                    // marginLeft: "-32px",
                    // marginRight: "-32px",
                    // paddingBottom: "32px",
                    // zIndex: 4,
                    // boxShadow: "0px -4px 4px 0px rgba(217, 217, 217, 0.40)",
                  }
                : {}
            }
          >
            {loading ? (
              <Stack alignItems="center" mt={"32px"}>
                <CircularProgress color="primary" />
              </Stack>
            ) : (
              <Button
                size="large"
                sx={(theme) => ({
                  backgroundColor: loading
                    ? theme.palette.gray.main
                    : theme.palette.primary.main,
                })}
                onClick={() => {
                  if (!loading && orderId) {
                    updateIncompleteOrderBillingPeriodCallback({
                      variables: {
                        orderId,
                        orderBillingPeriod: billingPeriod,
                      },
                    });
                    onContinue();
                  }
                }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  color="primary.contrastText"
                  sx={{
                    "&&": {
                      fontSize: 16,
                    },
                  }}
                >
                  Continue
                </Typography>
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
