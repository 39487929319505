import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  MenuItem,
  Modal,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Loading from "components/Loading";
import { Tab } from "components/Tab";
import { OrderState, ThreadType } from "gql-gen/graphql";
import { getActiveSubscriptionsPaginatedViaAdmin } from "gql/Admin.gql";
import { useState } from "react";
import { useNavigate } from "react-router";
import {
  dateToFormattedString,
  formatTimestamp,
  getDateObjFromDate,
} from "utils/date";
import SubscriptionModal from "./components/SubscriptionModal";

const ActiveSubscriptions = () => {
  const [orderType, setOrderType] = useState(
    OrderState.PENDING_ID_VERIFICATION
  );
  const navigate = useNavigate();

  const { data: subscriptions, loading: supportLoading } = useQuery(
    getActiveSubscriptionsPaginatedViaAdmin
  );
  const [subscriptionId, setSubscriptionId] = useState<number | null>(null);

  const subscriptionsUnpacked =
    subscriptions?.admin.subscriptions?.subscriptions ?? [];
  return (
    <Stack
      width={"100%"}
      sx={(theme) => ({
        backgroundColor: theme.palette.neutral["200"],
        overflowY: "scroll",
        paddingY: "32px",
        paddingX: "32px",
      })}
      flex={1}
    >
      <Stack flex={1}>
        <Typography
          variant="h3"
          sx={{
            mb: "16px",
          }}
        >
          Next Subscriptions
        </Typography>
        <Stack
          sx={{
            "&&>.MuiStack-item": {
              marginBottom: "16px",
              height: "70px",
              alignItems: "center",
              display: "flex",
              px: "16px",
            },
          }}
        >
          {subscriptionsUnpacked === undefined && <Loading />}
          {subscriptionsUnpacked?.map(
            ({ patient, product, nextRefillAt, id }) => {
              return (
                <Stack
                  direction={"row"}
                  marginBottom={"16px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.primary.contrastText,
                    borderRadius: "10px 0 0 10px",
                  })}
                >
                  <Stack
                    spacing={0}
                    key={`orderid-${id}`}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.primary.contrastText,
                      borderRadius: "10px 0 0 10px",
                    })}
                  >
                    <Tooltip
                      title={`order id: ${id}`}
                      placement="top"
                      arrow
                      sx={{
                        color: "black",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          flexGrow: 1,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {`subscription id: ${id}`}
                      </Typography>
                    </Tooltip>
                  </Stack>
                  <Stack
                    key={`name-${id}`}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.primary.contrastText,
                      width: "125px",
                    })}
                  >
                    <Tooltip
                      title={`${patient.firstName || "(Missing first name)"} ${
                        patient.lastName || "(Missing last name)"
                      }`}
                      placement="top"
                      arrow
                      sx={{
                        color: "black",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          flexGrow: 1,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "125px",
                        }}
                      >
                        {`${patient.firstName || "(Missing first name)"} ${
                          patient.lastName || "(Missing last name)"
                        }`}
                      </Typography>
                    </Tooltip>
                  </Stack>
                  <Stack
                    key={`email-${id}`}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.primary.contrastText,
                      width: "125px",
                    })}
                  >
                    <Tooltip
                      title={patient.email}
                      placement="top"
                      arrow
                      sx={{
                        color: "black",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          flexGrow: 1,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "125px",
                        }}
                      >
                        {patient.email}
                      </Typography>
                    </Tooltip>
                  </Stack>
                  <Stack
                    key={`product-${id}`}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.primary.contrastText,
                    })}
                  >
                    <Tooltip
                      title={product.name}
                      placement="top"
                      arrow
                      sx={{
                        color: "black",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          flexGrow: 1,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "225px",
                        }}
                      >
                        {product.name}
                      </Typography>
                    </Tooltip>
                  </Stack>
                  <Stack
                    key={`timestamp-${id}`}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.primary.contrastText,
                    })}
                  >
                    <Tooltip
                      title={dateToFormattedString(
                        getDateObjFromDate(nextRefillAt)
                      )}
                      placement="top"
                      sx={{
                        color: "black",
                      }}
                      arrow
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          flexGrow: 1,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {dateToFormattedString(
                          getDateObjFromDate(nextRefillAt)
                        )}
                      </Typography>
                    </Tooltip>
                  </Stack>
                  <Stack
                    key={`action-${id}`}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.primary.contrastText,
                      borderRadius: "0 10px 10px 0",
                    })}
                  >
                    <Tooltip
                      title="Verify"
                      placement="top"
                      arrow
                      sx={{
                        color: "black",
                      }}
                    >
                      <Stack
                        sx={(theme) => ({
                          borderRadius: "50px",
                          border: `1px solid ${theme.palette.primary.main}`,
                          cursor: "pointer",
                          height: "40px",
                          minWidth: "100px", // Ensure the button has a minimum width
                          flexGrow: 0, // Do not allow the verify button to grow
                        })}
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => {
                          navigate(
                            `/dashboard/admin/patient-details/${patient.id}/`
                          );
                        }}
                      >
                        <Typography variant="body1">View Patient</Typography>
                      </Stack>
                    </Tooltip>
                  </Stack>
                  <Stack
                    key={`action-${id}`}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.primary.contrastText,
                      borderRadius: "0 10px 10px 0",
                    })}
                  >
                    <Button
                      id={`subscriptions-${id}`}
                      onClick={(e) => {
                        setSubscriptionId(id);
                      }}
                      sx={(theme) => ({
                        marginRight: "10px",
                        backgroundColor: theme.palette.neutral["300"],
                        "&:hover": {
                          backgroundColor: theme.palette.neutral["200"],
                        },
                      })}
                    >
                      Actions
                    </Button>
                  </Stack>
                </Stack>
              );
            }
          )}
        </Stack>
      </Stack>
      <Modal
        open={!!subscriptionId}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          width="75%"
          height="75%"
          justifyContent={"center"}
          alignItems={"center"}
          borderRadius={"8px"}
          overflow={"clip"}
        >
          {subscriptionId ? (
            <SubscriptionModal
              subscriptionId={subscriptionId}
              onClose={() => {
                setSubscriptionId(null);
              }}
            />
          ) : (
            <div />
          )}
        </Stack>
      </Modal>
    </Stack>
  );
};

export default ActiveSubscriptions;
