import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  useRoutes,
  Outlet,
  useLocation,
} from "react-router-dom";
import { Stack, ThemeProvider } from "@mui/system";
import { Button, Fade, Typography, useMediaQuery } from "@mui/material";
import Welcome from "pages/intake/Welcome";
import IntakeQuestions from "pages/intake/IntakeQuestions";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { matchPath, matchRoutes, useMatch, useNavigate } from "react-router";

import {
  HorizontalTopHeaderProvider,
  useHorizontalTopHeader,
} from "contexts/HorizontalTopHeaderContext";
import LeftArrowGreen from "assets/icons/left-arrow-black.svg";
import MobileAccountNav from "pages/account/MobileAccountNav";
import MobileMessagesNav from "pages/messages/MobileMessagesNav";
import { TopLevelRouteToData } from "./NavbarRoutes";
import { MobileNavbarItem } from "./NavbarItems";

export const TopHeaderLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const {
    customHeaderTitle,
    onClickBack,
    bottomPercentageCompletion,
    showOnDesktop,
    showBorderBoxShadow,
    hideMobileToolbar,
  } = useHorizontalTopHeader();

  if (isMobile) {
    return (
      <Stack height="100%" width="100vw">
        <Stack
          direction="row"
          minHeight={"70px"}
          alignItems={"center"}
          px={"16px"}
          justifyContent="space-between"
          sx={(theme) => ({
            boxShadow: showBorderBoxShadow
              ? "0px 4px 4px 0px rgba(217, 217, 217, 0.40)"
              : undefined,
            zIndex: 4,
            backgroundColor: theme.palette.primary.contrastText,
            position: "sticky",
            top: 0,
          })}
        >
          {!onClickBack ? (
            <Stack sx={{ width: "25px", height: "25px" }} />
          ) : (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ width: "25px", height: "25px" }}
            >
              <Stack
                component="img"
                src={LeftArrowGreen}
                onClick={onClickBack}
              />
            </Stack>
          )}
          <Typography
            variant="h1"
            sx={{
              fontSize: "18px",
              fontWeight: "400",
            }}
            color="primary.main"
          >
            {customHeaderTitle}
          </Typography>
          <Stack sx={{ width: "25px", height: "25px" }} />
        </Stack>
        {bottomPercentageCompletion && (
          <Stack direction="row" height="4px">
            <Stack
              width={`${bottomPercentageCompletion}%`}
              height="100%"
              sx={(theme) => ({
                backgroundColor: theme.palette.purple["300"],
              })}
            />
            <Stack
              width={`${100 - bottomPercentageCompletion}%`}
              sx={(theme) => ({
                backgroundColor: theme.palette.gray.main,
              })}
              height="100%"
            />
          </Stack>
        )}
        {children}
        {!hideMobileToolbar && (
          <Stack
            direction="row"
            px={"32px"}
            py="11px"
            justifyContent={"space-between"}
            sx={(theme) => ({
              borderTop: `1px solid ${theme.palette.neutral["400"]}`,
              position: "sticky",
              bottom: 0,
              zIndex: 4,
              backgroundColor: theme.palette.primary.contrastText,
            })}
          >
            {Object.entries(TopLevelRouteToData)
              .filter(([k, v]) => v.showMobile)
              .map(([k, v]) => {
                return <MobileNavbarItem {...(v as unknown as any)} />;
              })}
          </Stack>
        )}
      </Stack>
    );
  }
  if (showOnDesktop) {
    return (
      <Stack height="100vh" width="100vw">
        <Stack
          component="div"
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          position={"relative"}
          sx={(theme) => ({
            boxShadow: showBorderBoxShadow
              ? "0px 4px 4px 0px rgba(217, 217, 217, 0.40)"
              : undefined,
            zIndex: 4,
            px: "32px",
            py: "24px",
          })}
        >
          {!onClickBack ? (
            <Stack sx={{ width: "25px", height: "25px" }} />
          ) : (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ width: "25px", height: "25px", cursor: "pointer" }}
            >
              <Stack
                component="img"
                src={LeftArrowGreen}
                onClick={onClickBack}
              />
            </Stack>
          )}
          <Typography
            variant="h1"
            component="div"
            color={(theme) => theme.palette.primary.main}
          >
            {customHeaderTitle}
          </Typography>
          <Stack sx={{ width: "25px", height: "25px" }}></Stack>
        </Stack>
        {bottomPercentageCompletion && (
          <Stack direction="row" height="8px">
            <Stack
              width={`${bottomPercentageCompletion}%`}
              height="100%"
              sx={(theme) => ({
                backgroundColor: theme.palette.purple["300"],
              })}
            />
            <Stack
              width={`${100 - bottomPercentageCompletion}%`}
              sx={(theme) => ({
                backgroundColor: theme.palette.gray.main,
              })}
              height="100%"
            />
          </Stack>
        )}
        {children}
      </Stack>
    );
  }
  return (
    <Stack height="100vh" width="100vw">
      <Stack
        component="div"
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        position={"relative"}
        sx={{
          zIndex: 4,
          py: 3,
          px: "40px",
          boxShadow: "0px 4px 4px 0px rgba(217, 217, 217, 0.4)",
        }}
      >
        <Typography
          variant="body1"
          component={"div"}
          sx={{ mr: 2, cursor: "pointer", position: "absolute", left: 40 }}
          color={(theme) => theme.palette.primary.main}
        >
          Our Story
        </Typography>
        <Typography
          variant="h1"
          component="div"
          color={(theme) => theme.palette.primary.main}
        >
          Oana
        </Typography>
        <Button
          size="large"
          sx={(theme) => ({
            ml: 2,
            right: 40,
            position: "absolute",
            backgroundColor: theme.palette.primary.main,
          })}
        >
          <Typography
            variant="body1"
            component="div"
            color={(theme) => theme.palette.primary.contrastText}
            sx={{
              "&&": {
                fontSize: 16,
              },
            }}
          >
            All Treatments
          </Typography>
        </Button>
      </Stack>
      {children}
    </Stack>
  );
};
