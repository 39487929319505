import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Link,
  Typography,
  Input,
  Checkbox,
  useMediaQuery,
  Divider,
} from "@mui/material";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Stack } from "@mui/system";

import { useAuth } from "contexts/AuthContext";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";
import { UserRole } from "gql-gen/graphql";

export default function Login() {
  const [email, setEmail] = useState("");
  const [emailLink, setEmailLink] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const { handleLogin } = useAuth();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect_url");

  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && currentUser.role === UserRole.PATIENT) {
      if (redirectUrl) {
        navigate(window.location.search.split("?redirect_url=")[1]);
      } else {
        navigate("/dashboard/patient/home");
      }
    }
    if (currentUser && currentUser.role === UserRole.ADMIN) {
      navigate("/dashboard/admin/id-verification-queue");
    }
  }, [currentUser, redirectUrl]);

  const isMobile = useMediaQuery("(max-width:600px)");

  const { setHorizontalTopValues } = useHorizontalTopHeader();

  useEffect(() => {
    if (isMobile) {
      setHorizontalTopValues({
        customHeaderTitle: "Oana",
        onClickBack: undefined,
        bottomPercentageCompletion: undefined,
        showOnDesktop: false,
        showBorderBoxShadow: true,
        hideMobileToolbar: true,
      });
    } else {
      setHorizontalTopValues({
        customHeaderTitle: undefined,
        onClickBack: undefined,
        bottomPercentageCompletion: undefined,
        showOnDesktop: false,
        showBorderBoxShadow: true,
        hideMobileToolbar: false,
      });
    }
  }, [isMobile, setHorizontalTopValues, navigate]);

  const handleSubmit = async () => {
    const result = await handleLogin({ email, password });
    if (!result) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 2000);
    }
  };
  return (
    <Stack
      alignItems="center"
      justifyContent="flex-start"
      height="100%"
      pl={"40px"}
      pr={"40px"}
    >
      <Stack
        mt={"100px"}
        maxWidth={isMobile ? undefined : "350px"}
        width={"100%"}
      >
        <Stack alignItems="flex-start" mb={"32px"} direction="row" width="100%">
          <Typography
            variant="h1"
            component="div"
            maxWidth="240px"
            color={"primary.main"}
          >
            Login to start your visit
          </Typography>
        </Stack>
        <Typography
          variant="caption"
          component="div"
          sx={{
            mb: "8px",
          }}
          color={"primary.main"}
        >
          Email
        </Typography>
        <Input
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Typography
          variant="caption"
          component="div"
          sx={{
            mt: "16px",
            mb: "8px",
          }}
          color={"primary.main"}
        >
          Password
        </Typography>
        <Input
          value={password}
          placeholder="Password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Typography
          color="primary.main"
          variant="body1"
          sx={{
            "&&": {
              mt: "16px",
              fontSize: 16,
            },
          }}
        >
          <Link
            component={RouterLink}
            sx={{
              ml: "4px",
              fontWeight: 700,
            }}
            to={`/request-reset-password`}
          >
            Forgot your Password?
          </Link>
        </Typography>
        {showError && (
          <Stack
            sx={(theme) => ({
              mt: "16px",
              backgroundColor: theme.palette.pink["transparent80"],
              borderRadius: "10px",
              paddingTop: "16px",
              paddingBottom: "16px",
              paddingRight: "15px",
              paddingLeft: "16px",
            })}
          >
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Stack>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                  }}
                  color={"primary.main"}
                >
                  Invalid email or password
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
        <Button
          size="large"
          sx={(theme) => ({
            mt: "32px",
            backgroundColor: theme.palette.primary.main,
            mb: "16px",
          })}
          onClick={handleSubmit}
        >
          <Typography
            variant="body1"
            component="div"
            color="primary.contrastText"
            sx={{
              "&&": {
                fontSize: 16,
              },
            }}
          >
            Continue
          </Typography>
        </Button>
        {/* <Stack sx={{ mt: 1, mb: 1 }}>
          <Divider sx={{ mb: 3 }} />
          <Typography
            variant="caption"
            component="div"
            sx={{
              mb: "8px",
            }}
            color={"primary.main"}
          >
            Email
          </Typography>
          <Input
            value={emailLink}
            placeholder="Email"
            onChange={(e) => setEmailLink(e.target.value)}
          />
          <Button
            size="large"
            sx={(theme) => ({
              mt: "32px",
              backgroundColor: theme.palette.primary.main,
              mb: "16px",
            })}
            onClick={handleSubmit}
          >
            <Typography
              variant="body1"
              component="div"
              color="primary.contrastText"
              sx={{
                "&&": {
                  fontSize: 16,
                },
              }}
            >
              Request login link
            </Typography>
          </Button>
        </Stack> */}
        <Typography
          variant="body1"
          component="div"
          color="primary.main"
          sx={{
            "&&": {
              fontSize: 16,
              px: "12px",
              mb: isMobile ? "64px" : "32px",
            },
          }}
        >
          Need to create an account?
          <br />
          <Link
            component={RouterLink}
            sx={{
              ml: "4px",
              fontWeight: 700,
            }}
            to={`/signup${redirectUrl ? `?redirect_url=${redirectUrl}` : ""}`}
          >
            Sign Up
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
}
