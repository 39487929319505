import { graphql } from "gql-gen";

export const getCurrentUserQuery = graphql(`
  query GetCurrentUserQuery {
    me {
      id
      userSelfiePhoto {
        id
        presignedUrl
      }
      adminInfo {
        id
        NPI
      }
      email
      firstName
      lastName
      phone
      dateOfBirth
    }
  }
`);

export const getMedicalLicenses = graphql(`
  query GetMedicalLicenses {
    me {
      id
      medicalLicenses {
        id
        state
        licenseId
      }
    }
  }
`);

export const getCurrentUserShippingAddressQuery = graphql(`
  query GetCurrentUserShippingAddressQuery {
    me {
      id
      firstName
      lastName
      shippingAddress {
        id
        name
        address
        aptSuite
        city
        state
        zipCode
      }
      paymentMethods {
        id
        squareCardInfo {
          id
          cardBrand
          last4
          expMonth
          expYear
          billingAddress {
            postalCode
          }
        }
      }
    }
  }
`);

export const authorizeTransaction = graphql(`
  mutation AuthCard(
    $orderId: Int!
    $paymentToken: String!
    $cardholderLastName: String!
    $cardholderFirstName: String!
    $billingAddress: AddressInput!
    $priceToAuthorize: String
  ) {
    authorizeCreditCardForCharge(
      orderId: $orderId
      paymentToken: $paymentToken
      cardholderLastName: $cardholderLastName
      cardholderFirstName: $cardholderFirstName
      billingAddress: $billingAddress
      priceToAuthorize: $priceToAuthorize
    )
  }
`);

export const checkIfCouponIsValid = graphql(`
  query CheckIfCouponIsValid($couponCode: String!) {
    me {
      id
      checkCouponIsValid(couponCode: $couponCode) {
        valid
        discount
        removeVisitFee
      }
    }
  }
`);

export const updateCurrentUserShippingAddress = graphql(`
  mutation UpdateCurrentUserShippingAddress(
    $addressInput: AddressInput!
    $orderId: Int
  ) {
    updateCurrentUserShippingAddress(
      addressInput: $addressInput
      orderId: $orderId
    )
  }
`);

export const getOrderState = graphql(`
  query GetOrderState($orderId: Int!) {
    me {
      id
      order(id: $orderId) {
        id
        billingPeriod
        state
      }
    }
  }
`);

export const addPaymentMethodToCurrentUser = graphql(`
  mutation AddPaymentMethodToCurrentUser(
    $paymentToken: String!
    $cardholderLastName: String!
    $cardholderFirstName: String!
    $billingAddress: AddressInput!
  ) {
    addPaymentInfo(
      paymentToken: $paymentToken
      cardholderLastName: $cardholderLastName
      cardholderFirstName: $cardholderFirstName
      billingAddress: $billingAddress
    )
  }
`);

export const getMyPaymentMethods = graphql(`
  query GetMyPaymentMethods {
    me {
      id
      paymentMethods {
        id
        ccNumber
        ccExp
        ccType
        squareCardInfo {
          id
          cardBrand
          last4
          expMonth
          expYear
          billingAddress {
            postalCode
          }
        }
      }
    }
  }
`);

export const uploadUserPhotos = graphql(`
  mutation UploadUserPhotos($userIdPhoto: File!) {
    uploadUserPhotos(userIdPhoto: $userIdPhoto) {
      id
      s3Key
      filename
    }
  }
`);

export const uploadNewUserSelfie = graphql(`
  mutation UploadNewUserSelfie($userSelfiePhoto: File!) {
    uploadNewUserSelfie(userSelfiePhoto: $userSelfiePhoto) {
      id
      s3Key
      filename
    }
  }
`);

export const getPendingOrdersForCurrentUser = graphql(`
  query GetPendingOrdersForCurrentUser {
    me {
      shippingAddress {
        id
        address
        aptSuite
        city
        state
        zipCode
      }
      ordersForCurrentUser(
        state: [
          PENDING_INTAKE_COMPLETION
          PENDING_ID_VERIFICATION
          MISSING_ID_VERIFICATION
          MISSING_PAYMENT_INFO
          FAILED_TO_CHARGE_PAYMENT_METHOD
          PENDING_MEDICAL_REVIEW
          REJECTED_BY_MEDICAL_REVIEW
          SENDING_TO_PHARMACY
          SENT_TO_PHARMACY
        ]
      ) {
        id
        billingPeriod
        updatedAt
        shippedAt
        priceInCents
        state
        subscription {
          nextRefillAt
        }
        exactPrescriptionName
        doctorInstructions
        trackingUrl
        product {
          ...ProductDetails
        }
        prescriptions {
          id
          directions
          medication {
            id
            formulaFormat
            formulaIngredients
            defaultDirections
            quantity
            form
            units
            completeName
            defaultMessageToSendToUser
          }
        }
        intakeResponse {
          templateId
        }
      }
    }
  }
`);

export const getCompletedOrdersForCurrentUser = graphql(`
  query GetCompletedOrdersForCurrentUser {
    me {
      shippingAddress {
        id
        address
        aptSuite
        city
        state
        zipCode
      }
      ordersForCurrentUser(state: [SHIPPED_TO_PATIENT, CANCELLED]) {
        id
        updatedAt
        shippedAt
        priceInCents
        state
        billingPeriod
        subscription {
          nextRefillAt
        }
        exactPrescriptionName
        doctorInstructions
        trackingUrl
        product {
          ...ProductDetails
        }
        prescriptions {
          id
          directions
          medication {
            id
            formulaFormat
            formulaIngredients
            defaultDirections
            quantity
            form
            units
            completeName
            defaultMessageToSendToUser
          }
        }
        intakeResponse {
          templateId
        }
      }
    }
  }
`);

export const getOrdersForHomeViewForCurrentUser = graphql(`
  query GetOrdersForHomeViewForCurrentUser {
    me {
      ordersInProgress: ordersForCurrentUser(
        state: [
          PENDING_INTAKE_COMPLETION
          MISSING_ID_VERIFICATION
          MISSING_PAYMENT_INFO
        ]
      ) {
        id
        priceInCents
        state
        billingPeriod
        trackingUrl
        shippedAt
        product {
          ...ProductDetails
        }
        intakeResponse {
          templateId
          completedAt
        }
      }
      ordersBeingProcessed: ordersForCurrentUser(
        state: [
          PENDING_MEDICAL_REVIEW
          PENDING_ID_VERIFICATION
          SENDING_TO_PHARMACY
          SENT_TO_PHARMACY
          SHIPPED_TO_PATIENT
        ]
        includeLatestOrder: true
        lookbackMaximumInDays: 7
      ) {
        id
        billingPeriod
        priceInCents
        trackingUrl
        shippedAt
        state
        product {
          ...ProductDetails
        }
      }
    }
  }
`);

export const updateUserFields = graphql(`
  mutation UpdateCurrentUserFields(
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $dateOfBirth: String
    $NPI: String
  ) {
    updateUserInformation(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      dateOfBirth: $dateOfBirth
      NPI: $NPI
    )
  }
`);

export const updateUserPassword = graphql(`
  mutation UpdateUserPassword(
    $oldPassword: String!
    $newPassword: String!
    $confirmNewPassword: String!
  ) {
    updateUserPassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmNewPassword: $confirmNewPassword
    )
  }
`);

export const deleteMedicalLicense = graphql(`
  mutation DeleteMedicalLicense($licenseId: Int!) {
    deleteMedicalLicense(licenseId: $licenseId)
  }
`);

export const updateMedicalLicense = graphql(`
  mutation UpdateMedicalLicense($licenseId: Int!, $license: String!) {
    updateMedicalLicense(licenseId: $licenseId, license: $license)
  }
`);

export const createMedicalLicense = graphql(`
  mutation CreateMedicalLicense($state: String!, $license: String!) {
    createMedicalLicense(state: $state, license: $license)
  }
`);
