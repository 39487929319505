import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Button,
  Stack,
  IconButton,
  alpha,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import PlusSvg from "assets/icons/plus.svg";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useMutation } from "@apollo/client";
import { uploadUserPhotos } from "gql/User.gql";
import { convertHeicFile } from "utils/file";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";

export default function IdVerificationIntakeStep({
  onContinue,
  onBack,
  bottomPercentageCompletion,
  allowSkip = true,
}: {
  onContinue: () => void;
  onBack: () => void;
  bottomPercentageCompletion?: number;
  allowSkip?: boolean;
}) {
  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const [uploadedIdFile, setUploadedIdFile] = useState<File | null>(null);
  const [loadingHeicId, setLoadingHeicId] = useState<boolean>(false);
  // const [loadingHeicSelfie, setLoadingHeicSelfie] = useState<boolean>(false);
  // const [uploadedSelfieFile, setUploadedSelfieFile] = useState<File | null>(
  //   null
  // );
  const location = useLocation();
  const pathName = location.pathname;
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (!currentUser) {
      navigate(`/login?redirect_url=${pathName}`);
    }
  }, [currentUser, pathName]);

  const { setHorizontalTopValues } = useHorizontalTopHeader();

  const titleRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    titleRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);
  useEffect(() => {
    setHorizontalTopValues({
      customHeaderTitle: "ID Verification",
      onClickBack: () => onBack(),
      bottomPercentageCompletion,
      showBorderBoxShadow: true,
      showOnDesktop: true,
      hideMobileToolbar: true,
    });
  }, [setHorizontalTopValues, navigate, bottomPercentageCompletion, onBack]);

  const [uploadUserPhotosCallback] = useMutation(uploadUserPhotos);

  const onFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setUploadedFile: any,
    type: "id"
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type === "image/heic") {
        setLoadingHeicId(true);
        const jpegBlob = await convertHeicFile(file);
        setLoadingHeicId(false);
        setUploadedFile(
          new File(Array.isArray(jpegBlob) ? jpegBlob : [jpegBlob], file.name)
        );
      } else if (["image/jpeg", "image/png"].includes(file.type)) {
        setUploadedFile(files[0]);
      }
    }
  };

  const handleDrop = async (
    event: React.DragEvent,
    setUploadedFile: any,
    type: "id"
  ) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type === "image/heic") {
        setLoadingHeicId(true);
        const jpegBlob = await convertHeicFile(file);
        setLoadingHeicId(false);
        setUploadedFile(
          new File(Array.isArray(jpegBlob) ? jpegBlob : [jpegBlob], file.name)
        );
      } else if (["image/jpeg", "image/png"].includes(file.type)) {
        setUploadedFile(files[0]);
      }
    }
  };

  const removeImage = (setUploadedFile: any) => {
    setUploadedFile(null);
  };

  return (
    <Stack alignItems="center" justifyContent="flex-start" height="100%">
      <div ref={titleRef} />
      <Stack mt={"32px"} maxWidth="440px">
        <Stack alignItems="flex-start" width="100%">
          <Typography
            variant="h2"
            component="div"
            color={"primary.main"}
            mb={"16px"}
          >
            Lastly, we need to verify your ID
          </Typography>
          <Typography
            variant="body2"
            component="div"
            color={"primary.main"}
            mb={"16px"}
          >
            To provide a prescription product, our team must first confirm your
            age and identify
          </Typography>
        </Stack>
        <Stack alignItems="flex-start">
          <Typography
            variant="captionMedium"
            component="div"
            color={"primary.main"}
            mb={"32px"}
            sx={{
              fontSize: "14px",
            }}
          >
            Please upload a photo of a government issued ID (ex. driver’s
            license, passport)
          </Typography>
          <DragDropContext onDragEnd={() => {}}>
            <Droppable droppableId="droppable-id">
              {(provided) => (
                <Stack
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  minHeight="120px"
                  maxHeight="200px"
                  width="100%"
                  sx={(theme) => ({
                    borderRadius: "10px",
                    border: `2px dashed #7B7B7B`,
                    mb: "32px",
                    position: "relative",
                    overflow: "hidden",
                    backgroundColor: theme.palette.background.paper,
                  })}
                  onDragOver={(event) => event.preventDefault()}
                  onDrop={(event) => handleDrop(event, setUploadedIdFile, "id")}
                >
                  {loadingHeicId ? (
                    <Stack
                      alignItems="center"
                      height="100%"
                      justifyContent="center"
                    >
                      <CircularProgress />
                    </Stack>
                  ) : uploadedIdFile ? (
                    <>
                      <IconButton
                        onClick={() => removeImage(setUploadedIdFile)}
                        sx={(theme) => ({
                          position: "absolute",
                          top: 8,
                          Index: 3,
                          right: 8,
                          backgroundColor: theme.palette.neutral["300"],
                          "&:hover": {
                            backgroundColor: theme.palette.neutral["200"],
                          },
                        })}
                      >
                        <CloseIcon />
                      </IconButton>
                      <img
                        src={URL.createObjectURL(uploadedIdFile)}
                        alt="Uploaded ID"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </>
                  ) : (
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{ height: "100%", width: "100%" }}
                    >
                      <Stack
                        component="label"
                        htmlFor="id-upload"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Stack
                          component="img"
                          src={PlusSvg}
                          sx={{
                            height: "24px",
                            width: "24px",
                            cursor: "pointer",
                          }}
                        />
                      </Stack>
                    </Stack>
                  )}
                  <input
                    type="file"
                    id="id-upload"
                    onChange={(event) =>
                      onFileSelect(event, setUploadedIdFile, "id")
                    }
                    style={{ display: "none" }}
                    multiple
                    accept="image/*;capture=camera"
                  />
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </Stack>
        <Stack
          sx={
            isMobile
              ? {
                  // position: "sticky",
                  // bottom: 0,
                  // backgroundColor: "#FFF",
                  // marginLeft: "-32px",
                  // marginRight: "-32px",
                  // paddingBottom: "32px",
                  // mt: "20px",
                  // zIndex: 4,
                  // boxShadow: "0px -4px 4px 0px rgba(217, 217, 217, 0.40)",
                  marginBottom: "32px",
                }
              : {}
          }
        >
          <Stack
            direction={"row"}
            mt={"24px"}
            sx={{
              marginRight: "0px", //isMobile ? "32px" : "0px",
              marginLeft: "0px", //isMobile ? "32px" : "0px",
            }}
          >
            {allowSkip && (
              <Button
                size="large"
                sx={(theme) => ({
                  flex: 1,
                  border: `1px solid ${theme.palette.common.black}`,
                  mr: "12px",
                  backgroundColor: "#FFF",
                  "&:hover": {
                    backgroundColor: "#FFF",
                  },
                })}
                onClick={() => {
                  onContinue();
                }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  color="common.black"
                  sx={{ "&&": { fontSize: 16 } }}
                >
                  Upload later
                </Typography>
              </Button>
            )}
            <Button
              size="large"
              sx={(theme) => ({
                flex: 1,
                backgroundColor: !uploadedIdFile
                  ? theme.palette.gray.main
                  : theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: !uploadedIdFile
                    ? theme.palette.gray.main
                    : theme.palette.yellow.main,
                },
              })}
              onClick={() => {
                if (uploadedIdFile) {
                  uploadUserPhotosCallback({
                    variables: {
                      userIdPhoto: uploadedIdFile,
                    },
                  });
                  onContinue();
                }
              }}
            >
              <Typography
                variant="body1"
                component="div"
                color="primary.contrastText"
                sx={{ "&&": { fontSize: 16 } }}
              >
                Submit
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
