import { graphql } from "gql-gen";

export const userWithProfilePicFragment = graphql(`
  fragment UserWithProfilePic on User {
    id
    firstName
    lastName
    adminInfo {
      id
      title
    }
    userSelfiePhoto {
      id
      presignedUrl
    }
  }
`);

export const getUserWithProfilePic = graphql(`
  query GetUserWithProfilePic {
    me {
      ...UserWithProfilePic
    }
  }
`);

export const messageUserWithProfilePicFragment = graphql(`
  fragment MessageWithUserProfilePic on Message {
    id
    content
    timestamp
    threadId
    sender {
      ...UserWithProfilePic
    }
  }
`);

export const getUserHasUnreadMessages = graphql(`
  query GetUserHasUnreadMessages {
    me {
      hasUnreadMessages
      hasUnreadSupportMessages
      hasUnreadMedicalMessages
    }
  }
`);

export const getUserSupportMessages = graphql(`
  query GetUserSupportMessages {
    me {
      thread: supportThread {
        id
      }
      messages: supportMessages {
        ...MessageWithUserProfilePic
      }
    }
  }
`);

export const getUserMedicalMessages = graphql(`
  query GetUserMedicalMessages {
    me {
      thread: medicalThread {
        id
      }
      messages: medicalMessages {
        ...MessageWithUserProfilePic
      }
    }
  }
`);

export const createUserMessage = graphql(`
  mutation CreateUserMessage(
    $content: String!
    $type: ThreadType!
    $threadId: Int
    $patientId: Int
  ) {
    createMessage(
      content: $content
      type: $type
      patientId: $patientId
      threadId: $threadId
    ) {
      ...MessageWithUserProfilePic
    }
  }
`);
