import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Typography,
  Input,
  Checkbox,
  useMediaQuery,
  Divider,
  Select,
  MenuItem,
  Stack,
  CircularProgress,
  Modal,
} from "@mui/material";
import {
  GetLatestQuestionsAndResponseForGroupsQuery,
  OrderBillingPeriod,
  ProductEnum,
  SquareCardInfo,
} from "gql-gen/graphql";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { env } from "process";
import { useIsLoggedIn } from "components/CheckAuthComponent";
import { useAuth } from "contexts/AuthContext";
import ScribbleArrowDown from "assets/icons/scribble-arrow-down-welcome.svg";
import CheckupGreen from "assets/icons/checkup-black.svg";
import StethoscopeGreen from "assets/icons/stethoscope-black.svg";
import MessagesThinGreen from "assets/icons/messages-thin-black.svg";
import CardsList from "assets/imgs/credit_cards/card-list.png";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";
import ProductTile from "components/ProductTile";
import { useMutation, useQuery } from "@apollo/client";
import {
  authorizeTransaction,
  checkIfCouponIsValid,
  getCurrentUserShippingAddressQuery,
} from "gql/User.gql";
import { GetCurrentUserShippingAddressQueryQuery } from "gql-gen/graphql";
import { updateIncompleteOrderBillingPeriod } from "gql/Intakes.gql";
import AmexCard from "assets/imgs/credit_cards/amex-48.png";
import DinersClubCard from "assets/imgs/credit_cards/diners-club-48.png";
import DiscoverCard from "assets/imgs/credit_cards/discover-card-48.png";
import JCBCard from "assets/imgs/credit_cards/jcb-48.png";
import MasterCard from "assets/imgs/credit_cards/mastercard-48.png";
import UnionPayCard from "assets/imgs/credit_cards/unionpay-48.png";
import VisaCard from "assets/imgs/credit_cards/visa-48.png";
import PurplePolygon from "assets/icons/purple-polygon.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { isProduction } from "utils/api";
import { allowedStates } from "./Welcome";
import { useDebounce } from "use-debounce";
import { Box } from "@mui/system";
import { stateAcronyms } from "utils/constants";
import mixpanel from "mixpanel-browser";

const CardToSource = {
  VISA: VisaCard,
  JCB: JCBCard,
  AMERICAN_EXPRESS: AmexCard,
  AMEX: AmexCard,
  DISCOVER_DINERS: DinersClubCard,
  DISCOVER: DiscoverCard,
  MASTERCARD: MasterCard,
  CHINA_UNIONPAY: UnionPayCard,
};
export const Card = ({ card }: { card: SquareCardInfo }) => {
  return (
    <Stack
      direction={"row"}
      alignItems="center"
      justifyContent="start"
      p={"8px"}
      sx={(theme) => ({
        borderRadius: "10px",
        border: `solid 1px ${theme.palette.gray.main}`,
        maxWidth: "120px",
        mb: "8px",
      })}
    >
      {card.cardBrand in CardToSource ? (
        <Stack
          component="img"
          sx={{
            height: "24px",
            width: "24px",
            mr: "8px",
          }}
          src={CardToSource[card.cardBrand as keyof typeof CardToSource]}
        />
      ) : (
        <Typography
          variant="caption"
          fontWeight={500}
          sx={{
            mr: "8px",
          }}
        >
          {card.cardBrand}
        </Typography>
      )}
      <Stack>
        <Typography
          variant="caption"
          fontSize={12}
        >{`Ends in ${card.last4}`}</Typography>
        <Typography
          variant="caption"
          fontSize={12}
          fontWeight={500}
        >{`${card.expMonth}/${card.expYear}`}</Typography>
      </Stack>
    </Stack>
  );
};

export const CardNmi = ({
  ccNumber,
  ccExp,
  ccType,
}: {
  ccNumber: string;
  ccExp: string;
  ccType: string;
}) => {
  return (
    <Stack
      direction={"row"}
      alignItems="center"
      justifyContent="start"
      p={"8px"}
      sx={(theme) => ({
        borderRadius: "10px",
        border: `solid 1px ${theme.palette.gray.main}`,
        maxWidth: "120px",
        mb: "8px",
      })}
    >
      {ccType.toUpperCase() in CardToSource ? (
        <Stack
          component="img"
          sx={{
            height: "24px",
            width: "24px",
            mr: "8px",
          }}
          src={CardToSource[ccType.toUpperCase() as keyof typeof CardToSource]}
        />
      ) : (
        <Typography
          variant="caption"
          fontWeight={500}
          sx={{
            mr: "8px",
          }}
        >
          {ccType}
        </Typography>
      )}
      <Stack>
        <Typography
          variant="caption"
          fontSize={12}
        >{`Ends with ${ccNumber.slice(-4)}`}</Typography>
        <Typography
          variant="caption"
          fontSize={12}
          fontWeight={500}
        >{`${ccExp.slice(0, 2)}/${ccExp.slice(2)}`}</Typography>
      </Stack>
    </Stack>
  );
};

type Product =
  GetLatestQuestionsAndResponseForGroupsQuery["me"]["intakeTemplate"]["product"];

type ShippingAddress =
  GetCurrentUserShippingAddressQueryQuery["me"]["shippingAddress"];

const productionAppId = "sq0idp-4r-tbsouPE-cZmmmQAqWoQ";
const locationId = {
  CA: "LWWEQ1T8YQKAM",
  FL: "L766HXD0CW77E",
  TX: "L766HXD0CW77E",
  IN: "L766HXD0CW77E",
  AZ: "L766HXD0CW77E",
  NH: "L766HXD0CW77E",
  MN: "L766HXD0CW77E",
};

export default function Checkout({
  onBack,
  bottomPercentageCompletion,
  product,
  onSubmit,
  orderId,
  billingPeriod,
}: {
  orderId?: number;
  bottomPercentageCompletion?: number;
  onBack: () => void;
  billingPeriod: OrderBillingPeriod;
  onSubmit: (
    billingPeriod: OrderBillingPeriod,
    address: Partial<ShippingAddress>,
    couponCode?: string
  ) => void;
  product: Product;
}) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  useEffect(() => {
    mixpanel.track("Page View", {
      product: product.productEnum,
      intakeQuestion: "CHECKOUT",
    });
  }, []);

  const [paymentToken, setPaymentToken] = useState<string | undefined>();

  const [card, setCard] = useState<any>(null); // Adjust the type as needed
  const [cardError, setCardError] = useState<any>(false); // Adjust the type as needed

  const [searchParams] = useSearchParams();
  const useSandbox = searchParams.get("use_sanbox");
  const couponCodeDefault = searchParams.get("coupon") ?? "";

  const [hasLoadedScript, setHasLoadedScript] = useState(false);

  const hasAuthorizedTransaction = useRef(false);

  const [processingTransaction, setProcessingTransaction] = useState(false);

  const [shippingAddress, setShippingAddress] = useState<
    Partial<ShippingAddress> | undefined
  >(undefined);

  const [couponCode, setCouponCode] = useState<string | undefined>(
    couponCodeDefault
  );

  const [chargedModalOpen, setChargedModalOpen] = useState(false);

  const [couponCodeDebounceValue] = useDebounce(couponCode, 750);

  const { data: dataCouponCode, loading } = useQuery(checkIfCouponIsValid, {
    fetchPolicy: "cache-and-network",
    skip: !couponCodeDebounceValue,
    variables: {
      couponCode: couponCodeDebounceValue?.toUpperCase() ?? "",
    },
  });
  const [billingAddress, setBillingAddress] = useState<
    Partial<ShippingAddress> | undefined
  >(undefined);

  const [cardHolderFirstName, setCardHolderFirstName] = useState<
    string | undefined
  >(undefined);
  const [cardHolderLastName, setCardHolderLastName] = useState<
    string | undefined
  >(undefined);

  const [
    useSameShippingAddressForBilling,
    setUseSameShippingAddressForBilling,
  ] = useState<boolean>(false);

  const { data, error } = useQuery(getCurrentUserShippingAddressQuery, {
    fetchPolicy: "network-only",
  });

  const [onAuthorizeTransaction] = useMutation(authorizeTransaction);

  const [updateIncompleteOrderBillingPeriodCallback] = useMutation(
    updateIncompleteOrderBillingPeriod
  );

  useEffect(() => {
    if (data?.me.shippingAddress) {
      setShippingAddress({ ...data.me.shippingAddress });
    }
  }, [data, setShippingAddress]);

  useEffect(() => {
    if (data?.me.shippingAddress) {
      setBillingAddress({ ...data.me.shippingAddress });
    }
  }, [data, setBillingAddress]);

  useEffect(() => {
    document.getElementById("checkout-top")?.scrollIntoView({ block: "end" });
  }, []);

  const state = currentUser?.state;
  useEffect(() => {
    if (!state) {
      return;
    }
    const script = document.createElement("script");

    script.src = "https://greenpay.transactiongateway.com/token/Collect.js";
    script.async = true;
    script.setAttribute(
      "data-tokenization-key",
      state === "CA"
        ? "wdGHPD-7Dw6NY-NKez5k-w9Q6qM"
        : "fWCpUr-cHgt6d-uR7gaf-USr4fB"
    );

    // script.src = "https://usapayments.transactiongateway.com/token/Collect.js";
    // script.async = true;
    // script.setAttribute(
    //   "data-tokenization-key",
    //   "checkout_public_46MUyGqU8XF5CPq585458Q4tAv8euE4Q"
    // );

    document.body.appendChild(script);

    script.onload = () => {
      setHasLoadedScript(true);
    };

    // Optional: Clean up
    return () => {
      document.body.removeChild(script);
    };
  }, [state, setHasLoadedScript]); // Empty array ensures this effect runs once on mount only

  const billAddressString = useSameShippingAddressForBilling
    ? JSON.stringify(shippingAddress ?? {})
    : JSON.stringify(billingAddress ?? {});
  const shippingAddressString = JSON.stringify(shippingAddress ?? {});

  useEffect(() => {
    if (!state) {
      return;
    }
    const intializeUSAPayment = async () => {
      (window as any).CollectJS.configure({
        paymentSelector: "#demoPayButton",
        variant: "inline",
        styleSniffer: "true",
        customCss: {
          border: "none",
          "padding-right": "16px",
          "padding-left": "16px",
          "padding-top": "18px",
          "padding-bottom": "18px",
          "line-height": "1.4375em",
          font: "inherit",
          height: "54px",
          "font-size": "18px",
          "font-weight": "400",
        },
        invalidCss: {
          color: "#9D0000",
          "background-color": "rgba(219, 177, 188, 0.60)",
        },
        validCss: {
          color: "black",
        },
        placeholderCss: {
          color: "#7B7B7B",
        },
        focusCss: {
          color: "black",
          border: "none !important",
        },
        fields: {
          ccnumber: {
            selector: "#ccnumber",
            title: "Card Number",
            placeholder: "Card Number",
          },
          ccexp: {
            selector: "#ccexp",
            title: "Card Expiration",
            placeholder: "MM/YY",
          },
          cvv: {
            display: "show",
            selector: "#cvv",
            title: "CVV Code",
            placeholder: "CVV",
          },
        },
        currency: "USD",
        country: "US",

        timeoutDuration: 10000,
        timeoutCallback: function () {
          setCardError("Error authorizing transaction");
          setProcessingTransaction(false);
          // console.log(
          //   "The tokenization didn't respond in the expected timeframe.  This could be due to an invalid or incomplete field or poor connectivity"
          // );
        },
        fieldsAvailableCallback: function () {
          // console.log("Collect.js loaded the fields onto the form");
        },
        callback: function (response: { token?: string }) {
          if (!response.token) {
            setCardError("Error authorizing transaction");
            setProcessingTransaction(false);
            return;
          }
          setPaymentToken(response.token);
        },
      });
    };
    if (hasLoadedScript) {
      intializeUSAPayment();
    }
  }, [currentUser, state, hasLoadedScript]);

  if (!currentUser) {
    navigate("https://www.oanahealth.com/login");
  }

  const isMobile = useMediaQuery("(max-width:600px)");

  const { setHorizontalTopValues } = useHorizontalTopHeader();

  useEffect(() => {
    setHorizontalTopValues({
      customHeaderTitle: "Finish your visit",
      onClickBack: onBack,
      bottomPercentageCompletion,
      showOnDesktop: true,
      hideMobileToolbar: true,
    });
  }, [setHorizontalTopValues, bottomPercentageCompletion, onBack]);

  const enabledSubmission =
    shippingAddress?.address &&
    shippingAddress.state &&
    shippingAddress.zipCode &&
    shippingAddress.city &&
    (useSameShippingAddressForBilling ||
      (billingAddress?.address &&
        billingAddress.state &&
        billingAddress.zipCode &&
        billingAddress.city)) &&
    cardHolderFirstName &&
    cardHolderLastName;

  const isEflornithine = [
    ProductEnum.HAIRLESS_HYPE,
    ProductEnum.EFLORNITHINE,
  ].includes(product.productEnum);
  const couponIsValid = dataCouponCode?.me.checkCouponIsValid.valid;

  const subtotal =
    billingPeriod === OrderBillingPeriod.QUARTERLY
      ? (product.priceInCentsQuarterlyBilling * 3) / 100
      : product.priceInCentsMonthlyBilling / 100;

  const total = couponIsValid
    ? subtotal *
        (1 - (dataCouponCode?.me.checkCouponIsValid.discount ?? 0) / 100) +
      (product.productEnum !== ProductEnum.ORAL_SEMAGLUTIDE &&
      product.productEnum !== ProductEnum.SEMAGLUTIDE_SHOT &&
      product.productEnum !== ProductEnum.METFORMIN_SEMAGLUTIDE_PACK &&
      product.productEnum !== ProductEnum.METFORMIN_SPIRO_BUNDLE &&
      dataCouponCode?.me.checkCouponIsValid.removeVisitFee !== true
        ? 20
        : 0)
    : subtotal +
      (product.productEnum !== ProductEnum.ORAL_SEMAGLUTIDE &&
      product.productEnum !== ProductEnum.SEMAGLUTIDE_SHOT &&
      product.productEnum !== ProductEnum.METFORMIN_SEMAGLUTIDE_PACK &&
      product.productEnum !== ProductEnum.METFORMIN_SPIRO_BUNDLE &&
      dataCouponCode?.me.checkCouponIsValid.removeVisitFee !== true
        ? 20
        : 0);

  const amountSaved =
    (product.priceInCentsMonthlyBilling -
      product.priceInCentsQuarterlyBilling) /
    100;

  useEffect(() => {
    const authTransaction = async () => {
      const billingAddressParsed = JSON.parse(billAddressString);
      const shippingAddressParsed = JSON.parse(shippingAddressString);
      if (
        !hasAuthorizedTransaction.current &&
        cardHolderFirstName &&
        cardHolderLastName &&
        billingAddressParsed &&
        paymentToken &&
        shippingAddressParsed &&
        orderId
      ) {
        const eflornithinePrice =
          billingPeriod === OrderBillingPeriod.ONE_TIME
            ? "89.00"
            : billingPeriod === OrderBillingPeriod.MONTHLY
            ? "89.00"
            : "158.00";
        const result = await onAuthorizeTransaction({
          variables: {
            orderId,
            paymentToken,
            cardholderFirstName: cardHolderFirstName,
            cardholderLastName: cardHolderLastName,
            billingAddress: {
              address: billingAddressParsed.address,
              zipCode: billingAddressParsed.zipCode,
              city: billingAddressParsed.city,
              state: billingAddressParsed.state,
              aptSuite: billingAddressParsed.aptSuite,
            },
            priceToAuthorize: isEflornithine
              ? eflornithinePrice
              : Math.max(total, 2).toFixed(2),
          },
        });
        setProcessingTransaction(false);
        if (result.data?.authorizeCreditCardForCharge) {
          hasAuthorizedTransaction.current = true;
          onSubmit(
            billingPeriod,
            shippingAddressParsed,
            couponCode?.toUpperCase()
          );
          (window as any).gtag_report_webapp_purchase_conversion(
            window.location.href,
            `order_${orderId}`
          );
        } else {
          setCardError(
            "Error from credit card issuer when authorizing. Please try again or consider using another card"
          );
        }
      }
    };
    authTransaction();
  }, [
    paymentToken,
    billingPeriod,
    cardHolderFirstName,
    cardHolderLastName,
    billAddressString,
    shippingAddressString,
    setProcessingTransaction,
    onAuthorizeTransaction,
    couponCode,
    isEflornithine,
    orderId,
    total,
    onSubmit,
  ]);
  return (
    <>
      <div
        id="checkout-top"
        style={{ marginBottom: isMobile ? "32px" : "40px" }}
      />
      <Stack
        alignItems="center"
        justifyContent="flex-start"
        id="checkout-wrapper"
        pb={"60px"}
        flexGrow={1}
        sx={(theme) => ({
          overflowY: "scroll",
          backgroundColor: "#FFF",
          marginTop: isMobile ? "-32px" : "-40px",
        })}
      >
        <Stack maxWidth="420px">
          <Stack alignItems="flex-start" direction="row">
            <Typography variant="h1" color={"primary.main"}>
              Checkout
            </Typography>
          </Stack>

          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            mt={"32px"}
          >
            <Stack maxWidth={"320px"}>
              <ProductTile
                product={product}
                containerSx={(theme) => ({
                  borderRadius: "10px",
                  padding: "16px",
                  width: "100%",
                  mb: "32px",
                  border: `1px solid ${theme.palette.gray.main}`,
                })}
                showDoctorVisitCost
                billingPeriod={billingPeriod}
              />
            </Stack>
          </Stack>
          <Stack>
            <Typography variant="h3" color={"primary.main"} mb={"16px"}>
              Order summary
            </Typography>
            {/* {isEflornithine && (
              <>
                {[
                  OrderBillingPeriod.MONTHLY,
                  OrderBillingPeriod.QUARTERLY,
                ].includes(billingPeriod) && (
                  <>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography
                        variant="caption"
                        color={"primary.main"}
                        mb={"16px"}
                      >
                        {"Every 3 months (if prescribed)"}
                      </Typography>
                      <Stack direction="row">
                        <Typography
                          variant="caption"
                          color={"primary.main"}
                          mb={"16px"}
                          sx={{
                            fontWeight: 500,
                            mr: couponIsValid ? "8px" : 0,
                            textDecoration: couponIsValid
                              ? "line-through"
                              : "unset",
                          }}
                        >
                          {`$60`}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography
                        variant="caption"
                        color={"primary.main"}
                        mb={"16px"}
                      >
                        {`${
                          billingPeriod === OrderBillingPeriod.MONTHLY
                            ? "20gm"
                            : "60gm"
                        } bottle`}
                      </Typography>
                      <Stack direction="row">
                        <Typography
                          variant="caption"
                          color={"primary.main"}
                          mb={"16px"}
                          sx={{
                            fontWeight: 500,
                            mr: couponIsValid ? "8px" : 0,
                            textDecoration: couponIsValid
                              ? "line-through"
                              : "unset",
                          }}
                        >
                          {`${
                            billingPeriod === OrderBillingPeriod.MONTHLY
                              ? "$42"
                              : "$79"
                          }`}
                        </Typography>
                      </Stack>
                    </Stack>
                  </>
                )}
                {[OrderBillingPeriod.ONE_TIME].includes(billingPeriod) && (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      variant="caption"
                      color={"primary.main"}
                      mb={"16px"}
                    >
                      {"Hairless Hype (if prescribed)"}
                    </Typography>
                    <Stack direction="row">
                      <Typography
                        variant="caption"
                        color={"primary.main"}
                        mb={"16px"}
                        sx={{
                          fontWeight: 500,
                          mr: couponIsValid ? "8px" : 0,
                          textDecoration: couponIsValid
                            ? "line-through"
                            : "unset",
                        }}
                      >
                        {`$89`}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </>
            )} */}
            {
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  variant="caption"
                  color={"primary.main"}
                  mb={"16px"}
                >
                  {`${
                    // Change made Feb 12th
                    billingPeriod === OrderBillingPeriod.MONTHLY
                      ? "Every month"
                      : billingPeriod === OrderBillingPeriod.ONE_TIME
                      ? product.name
                      : "Every 3 months"
                  } (if prescribed)`}
                </Typography>
                <Stack direction="row">
                  <Typography
                    variant="caption"
                    color={"primary.main"}
                    mb={"16px"}
                    sx={{
                      fontWeight: 500,
                      mr: couponIsValid ? "8px" : 0,
                      textDecoration: couponIsValid ? "line-through" : "unset",
                    }}
                  >
                    {isEflornithine &&
                    billingPeriod !== OrderBillingPeriod.ONE_TIME
                      ? `$${subtotal}${
                          product.priceInCentsQuarterlyBilling2xDose
                            ? ` - $${
                                billingPeriod === OrderBillingPeriod.QUARTERLY
                                  ? (product.priceInCentsQuarterlyBilling2xDose *
                                      3) /
                                    100
                                  : product.priceInCentsMonthlyBilling2xDose /
                                    100
                              }`
                            : ""
                        }`
                      : isEflornithine &&
                        billingPeriod === OrderBillingPeriod.ONE_TIME
                      ? "$89"
                      : ""}
                    {!isEflornithine &&
                      `$${subtotal}${
                        product.priceInCentsQuarterlyBilling2xDose
                          ? ` - $${
                              billingPeriod === OrderBillingPeriod.QUARTERLY
                                ? (product.priceInCentsQuarterlyBilling2xDose *
                                    3) /
                                  100
                                : product.priceInCentsMonthlyBilling2xDose / 100
                            }`
                          : ""
                      }`}
                  </Typography>
                  {couponIsValid && (
                    <Typography
                      variant="caption"
                      color={"primary.main"}
                      mb={"16px"}
                      sx={{
                        fontWeight: 500,
                        mr: couponIsValid ? "8px" : 0,
                      }}
                    >
                      {`$${total.toFixed(2)}`}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            }
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography
                  variant="caption"
                  color={"primary.main"}
                  mb={"8px"}
                  // sx={{
                  //   fontWeight: 400,
                  //   fontSize: 20,
                  // }}
                >
                  {`Initial online medical visit`}
                </Typography>
                <Stack
                  sx={{
                    backgroundColor: "neutral.100",
                    borderRadius: "10px",
                    p: "12px",
                    mr: "24px",
                    position: "relative",
                  }}
                  mb={"16px"}
                >
                  <Stack
                    src={PurplePolygon}
                    component="img"
                    width="24px"
                    height="16px"
                    sx={{
                      position: "absolute",
                      top: "-7px",
                      left: "0px",
                    }}
                  />
                  <Typography variant="body2" color={"primary.main"}>
                    {product.productEnum !==
                      ProductEnum.METFORMIN_SPIRO_BUNDLE &&
                    product.productEnum !== ProductEnum.ORAL_SEMAGLUTIDE &&
                    product.productEnum !== ProductEnum.SEMAGLUTIDE_SHOT &&
                    product.productEnum !==
                      ProductEnum.METFORMIN_SEMAGLUTIDE_PACK &&
                    billingPeriod !== OrderBillingPeriod.ONE_TIME
                      ? "It includes ongoing support from our medical team. This is only charged once."
                      : "It includes ongoing support from our medical team."}
                  </Typography>
                </Stack>
              </Stack>
              <Typography
                variant="caption"
                color={"primary.main"}
                sx={{
                  fontWeight:
                    billingPeriod === OrderBillingPeriod.ONE_TIME ||
                    product.productEnum ===
                      ProductEnum.METFORMIN_SPIRO_BUNDLE ||
                    product.productEnum === ProductEnum.ORAL_SEMAGLUTIDE ||
                    product.productEnum === ProductEnum.SEMAGLUTIDE_SHOT ||
                    product.productEnum ===
                      ProductEnum.METFORMIN_SEMAGLUTIDE_PACK ||
                    dataCouponCode?.me.checkCouponIsValid.removeVisitFee
                      ? 700
                      : "unset",
                  color:
                    billingPeriod === OrderBillingPeriod.ONE_TIME ||
                    product.productEnum ===
                      ProductEnum.METFORMIN_SPIRO_BUNDLE ||
                    product.productEnum === ProductEnum.ORAL_SEMAGLUTIDE ||
                    product.productEnum === ProductEnum.SEMAGLUTIDE_SHOT ||
                    product.productEnum ===
                      ProductEnum.METFORMIN_SEMAGLUTIDE_PACK ||
                    dataCouponCode?.me.checkCouponIsValid.removeVisitFee
                      ? "#35A254"
                      : "primary.main",
                }}
              >
                {billingPeriod !== OrderBillingPeriod.ONE_TIME &&
                product.productEnum !== ProductEnum.METFORMIN_SPIRO_BUNDLE &&
                product.productEnum !== ProductEnum.ORAL_SEMAGLUTIDE &&
                product.productEnum !==
                  ProductEnum.METFORMIN_SEMAGLUTIDE_PACK &&
                product.productEnum !== ProductEnum.SEMAGLUTIDE_SHOT &&
                dataCouponCode?.me.checkCouponIsValid.removeVisitFee !== true
                  ? "$20"
                  : "FREE"}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="caption"
                color={"primary.main"}
                mb={"16px"}
                // sx={{
                //   fontWeight: 400,
                //   fontSize: 20,
                // }}
              >
                {product.productEnum == ProductEnum.ORAL_SEMAGLUTIDE ||
                product.productEnum == ProductEnum.METFORMIN_SEMAGLUTIDE_PACK
                  ? "2-day shipping"
                  : product.productEnum == ProductEnum.SEMAGLUTIDE_SHOT
                  ? "1-day shipping"
                  : `3-4 day shipping`}
              </Typography>
              <Typography
                variant="caption"
                color={"primary.main"}
                mb={"16px"}
                sx={{
                  fontWeight: 700,
                  color: "#35A254",
                }}
              >
                FREE
              </Typography>
            </Stack>
            <Divider
              sx={{
                borderColor: "#DDD",
              }}
            />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={"center"}
              mt={"16px"}
              mb={"0px"}
            >
              <Typography
                variant="caption"
                sx={{
                  fontSize: 17,
                  fontWeight: 700,
                }}
                color={"primary.main"}
              >
                {`Due now`}
              </Typography>
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="caption"
                  color={"primary.main"}
                  sx={{
                    fontSize: 17,
                    fontWeight: 500,
                    mr: couponIsValid ? "8px" : 0,
                    textDecoration: couponIsValid ? "line-through" : "unset",
                  }}
                >
                  $0
                  {/* {`$${(
                    subtotal +
                    (product.productEnum !== ProductEnum.METFORMIN ? 20 : 0)
                  ).toFixed(2)}`} */}
                </Typography>
                {/* {couponIsValid && (
                  <Typography
                    variant="caption"
                    color={"primary.main"}
                    mb={"16px"}
                    sx={{
                      fontWeight: 500,
                      mr: couponIsValid ? "8px" : 0,
                    }}
                  >
                    {`$${total}`}
                  </Typography>
                )} */}
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={"center"}
              mt={"12px"}
              mb={"16px"}
              onClick={() => setChargedModalOpen(true)}
            >
              <Typography
                variant="caption"
                sx={{
                  fontSize: 12,
                  fontWeight: 700,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                color={"primary.main"}
              >
                {`When will I be charged?`}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography
                variant="caption"
                color={"primary.main"}
                mb={"16px"}
                sx={{
                  fontWeight: 500,
                  mr: couponIsValid ? "8px" : 0,
                  textDecoration: couponIsValid ? "line-through" : "unset",
                }}
              />
            </Stack>

            <Stack mb={"16px"}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems={"center"}
                mb={"16px"}
                width="100%"
              >
                <Input
                  sx={(theme) => ({
                    "& input": {
                      color: theme.palette.primary.main,
                    },
                    width: "100%",
                    backgroundColor: theme.palette.primary.contrastText,
                  })}
                  multiline={false}
                  placeholder="Add promo code"
                  value={couponCode ?? ""}
                  onChange={(e) => {
                    setCouponCode(e.target.value);
                  }}
                />
                {dataCouponCode === undefined ? (
                  <Stack ml="32px" mr="8px">
                    <Box height={24} width={24} />
                  </Stack>
                ) : couponIsValid ? (
                  <Stack color="#35A254" ml="32px" mr="8px">
                    <CheckCircleIcon fontSize="medium" color="inherit" />
                  </Stack>
                ) : (
                  <Stack color="red.main" ml="32px" mr="8px">
                    <CancelIcon fontSize="medium" color="inherit" />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Divider
            sx={{
              borderColor: "#DDD",
              mt: "32px",
            }}
          />
          <Stack alignItems="flex-start" direction="column" mt={"24px"}>
            <Typography variant="h1" color={"primary.main"} mb={"16px"}>
              Billing address
            </Typography>
            <Stack flexDirection="row" mb={"16px"} alignItems={"center"}>
              <Checkbox
                checked={useSameShippingAddressForBilling}
                onClick={() => setUseSameShippingAddressForBilling((c) => !c)}
                size={"large" as any}
                sx={{
                  mr: "16px",
                }}
              />
              <Typography
                component={"div"}
                variant="body1"
                color={"primary.main"}
                height="fit-content"
              >
                Use shipping address
              </Typography>
            </Stack>
            {!useSameShippingAddressForBilling && (
              <>
                <Stack mb={"16px"} width="100%">
                  <Typography
                    variant="caption"
                    sx={{
                      mb: "8px",
                    }}
                    color="primary.main"
                  >
                    Address
                  </Typography>
                  <Input
                    sx={(theme) => ({
                      "& input": {
                        color: theme.palette.primary.main,
                      },
                      backgroundColor: theme.palette.primary.contrastText,
                    })}
                    multiline={false}
                    value={billingAddress?.address ?? ""}
                    onChange={(e) => {
                      setBillingAddress((s) => ({
                        ...s,
                        address: e.target.value,
                      }));
                    }}
                  />
                </Stack>
                <Stack mb={"16px"} width="100%">
                  <Typography
                    variant="caption"
                    sx={{
                      mb: "8px",
                    }}
                    color="primary.main"
                  >
                    Apartment, suite, etc (optional)
                  </Typography>
                  <Input
                    sx={(theme) => ({
                      "& input": {
                        color: theme.palette.primary.main,
                      },
                      backgroundColor: theme.palette.primary.contrastText,
                    })}
                    multiline={false}
                    value={billingAddress?.aptSuite ?? ""}
                    onChange={(e) => {
                      setBillingAddress((s) => ({
                        ...s,
                        aptSuite: e.target.value,
                      }));
                    }}
                  />
                </Stack>
                <Stack
                  mb={"16px"}
                  direction="row"
                  width="100%"
                  justifyContent={"space-between"}
                >
                  <Stack flex={1} mr="8px">
                    <Typography
                      variant="caption"
                      sx={{
                        mb: "8px",
                      }}
                      color="primary.main"
                    >
                      City
                    </Typography>
                    <Input
                      sx={(theme) => ({
                        "& input": {
                          color: theme.palette.primary.main,
                        },
                        backgroundColor: theme.palette.primary.contrastText,
                      })}
                      multiline={false}
                      value={billingAddress?.city ?? ""}
                      onChange={(e) => {
                        setBillingAddress((s) => ({
                          ...s,
                          city: e.target.value,
                        }));
                      }}
                    />
                  </Stack>
                  <Stack flex={1}>
                    <Typography
                      variant="caption"
                      sx={{
                        mb: "8px",
                      }}
                      color="primary.main"
                    >
                      State
                    </Typography>
                    <Select
                      value={billingAddress?.state ?? ""}
                      onChange={(e) => {
                        setBillingAddress((s) => ({
                          ...s,
                          state: e.target.value,
                        }));
                      }}
                      sx={(theme) => ({
                        backgroundColor: theme.palette.primary.contrastText,
                      })}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {stateAcronyms.map((state) => {
                        return (
                          <MenuItem
                            value={state}
                            key={state}
                            sx={(theme) => ({
                              backgroundColor:
                                theme.palette.primary.contrastText,
                              height: "48px",
                              "&.Mui-selected": {
                                backgroundColor: theme.palette.purple["100"],
                              },
                              "&:hover": {
                                backgroundColor: theme.palette.purple["200"],
                              },
                            })}
                          >
                            <Typography variant="caption" color="primary.main">
                              {state}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Stack>
                </Stack>
                <Stack width="136px">
                  <Typography
                    variant="caption"
                    sx={{
                      mb: "8px",
                    }}
                    color="primary.main"
                  >
                    Zip code
                  </Typography>
                  <Input
                    sx={(theme) => ({
                      "& input": {
                        color: theme.palette.primary.main,
                      },
                      backgroundColor: theme.palette.primary.contrastText,
                    })}
                    type="text"
                    multiline={false}
                    value={billingAddress?.zipCode ?? ""}
                    onChange={(e) => {
                      setBillingAddress((s) => ({
                        ...s,
                        zipCode: e.target.value,
                      }));
                    }}
                  />
                </Stack>
              </>
            )}
          </Stack>
          <Divider
            sx={{
              borderColor: "#DDD",
              mt: "32px",
            }}
          />
          <Stack
            alignItems="flex-start"
            direction="column"
            mt={"32px"}
            mb="32px"
          >
            <Typography variant="h1" color={"primary.main"} mb={"8px"}>
              Payment method
            </Typography>
            <Stack mb={"16px"} width="100%">
              <Typography
                variant="caption"
                sx={{
                  mb: "12px",
                }}
                color="primary.main"
              >
                Cardholder Name
              </Typography>
              <Stack flexDirection={"row"}>
                <Input
                  sx={(theme) => ({
                    "& input": {
                      color: theme.palette.primary.main,
                    },
                    mr: "16px",
                    backgroundColor: theme.palette.primary.contrastText,
                  })}
                  placeholder="First name"
                  multiline={false}
                  value={cardHolderFirstName ?? ""}
                  onChange={(e) => {
                    e.preventDefault();
                    setCardHolderFirstName(e.target.value);
                  }}
                />
                <Input
                  sx={(theme) => ({
                    "& input": {
                      color: theme.palette.primary.main,
                    },
                    backgroundColor: theme.palette.primary.contrastText,
                  })}
                  multiline={false}
                  placeholder="Last name"
                  value={cardHolderLastName ?? ""}
                  onChange={(e) => {
                    e.preventDefault();
                    setCardHolderLastName(e.target.value);
                  }}
                />
              </Stack>
            </Stack>
            <Stack
              sx={{ mt: "8px" }}
              alignItems={"self-start"}
              justifyContent={"center"}
            >
              <Stack
                flexDirection={"row"}
                alignItems={"self-start"}
                justifyContent={"center"}
                pl={"16px"}
              >
                <Stack
                  sx={{
                    height: "17px",
                    width: "106px",
                    mb: "8px",
                  }}
                  src={CardsList}
                  component={"img"}
                ></Stack>
              </Stack>
              <Stack
                flexDirection="row"
                sx={{
                  border: "2px solid #DDD",
                  borderRadius: "0.5rem",
                  overflow: "hidden",
                  "&:hover": {
                    border: "2px solid black",
                  },
                }}
              >
                <Stack flexDirection="row" flex={1}>
                  <div id="ccnumber" />
                </Stack>
                <Stack flexDirection="row" flex={1}>
                  <div id="ccexp" />
                  <div id="cvv" />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {cardError && (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems={"center"}
                mt={"12px"}
                mb={"16px"}
                onClick={() =>
                  navigate(
                    "/dashboard/patient/messages/support?msg=Hello, I would like to pay with FSA/HSA card and its not working?"
                  )
                }
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: 13,
                    fontWeight: 700,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  color={"primary.main"}
                >
                  {`FSA/HSA Card not working? Speak with support`}
                </Typography>
              </Stack>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                sx={(theme) => ({
                  height: "40px",
                  borderRadius: "10px",
                  p: "12px",
                  mb: "16px",
                  mt: "16px",
                  backgroundColor: theme.palette.pink.transparent80,
                })}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  Error validating payment
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  Please try again or add a new card
                </Typography>
              </Stack>
            </>
          )}
          {processingTransaction ? (
            <Stack alignItems="center" mb={"32px"}>
              <CircularProgress color="primary" />
            </Stack>
          ) : (
            <Button
              size="large"
              sx={(theme) => ({
                mb: "32px",
                backgroundColor: enabledSubmission
                  ? theme.palette.primary.main
                  : theme.palette.gray.main,
              })}
              onClick={() => {
                const response = (
                  window as any
                ).CollectJS.startPaymentRequest();
                console.log({ response });
                setProcessingTransaction(true);
              }}
              disabled={!enabledSubmission}
            >
              <Typography
                variant="body1"
                component="div"
                color="primary.contrastText"
                sx={{
                  "&&": {
                    fontSize: 16,
                  },
                }}
              >
                Submit
              </Typography>
            </Button>
          )}
          <Stack
            sx={{
              p: "16px",
              borderRadius: "10px",
            }}
          >
            <Typography
              variant="body2Bold"
              mb={"16px"}
              color="rgba(123, 123, 123, 1)"
            >
              $0 Due Now - Pay Upon Medical Approval
            </Typography>
            {!isEflornithine ? (
              <Typography
                variant="body2"
                mb={"8px"}
                color="rgba(123, 123, 123, 1)"
              >
                {`By clicking “Submit”, you agree that, if prescribed, you will
            automatically be charged $${total.toFixed(
              2
            )} for your first shipment and $${subtotal} ${billingPeriod.toLowerCase()} thereafter until you cancel or your
            prescription expires. We will require ID verification before medical review.`}
              </Typography>
            ) : billingPeriod === OrderBillingPeriod.ONE_TIME ? (
              <Typography
                variant="body2"
                mb={"8px"}
                color="rgba(123, 123, 123, 1)"
              >
                {`By clicking “Submit”, you agree that, if prescribed, you will
        automatically be charged $89 for your first shipment. We will require ID verification before medical review.`}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                mb={"8px"}
                color="rgba(123, 123, 123, 1)"
              >
                {`By clicking “Submit”, you agree that, if prescribed, you will
            automatically be charged ${
              billingPeriod === OrderBillingPeriod.MONTHLY ? "$89" : "$158"
            } for your first shipment and ${
                  billingPeriod === OrderBillingPeriod.MONTHLY
                    ? "$69 every month"
                    : "$138 every 3 month"
                } thereafter until you cancel or your
            prescription expires. Refills billed separately. We will require ID verification before medical review.`}
              </Typography>
            )}

            {/* {product.productEnum !== ProductEnum.METFORMIN ? (
              <Typography
                variant="body2"
                mb={"20px"}
                color="rgba(123, 123, 123, 1)"
              >
                We will make an auth charge of $20 to the card on file. This
                will be paid to the physician upon medical review.
              </Typography>
            ) : (
              <Typography
                variant="body2"
                mb={"20px"}
                color="rgba(123, 123, 123, 1)"
              >
                We will make an auth charge of $27 to the card on file. This
                will be voided if the medication is not approved.
              </Typography>
            )} */}
            <Typography variant="body2" color="rgba(123, 123, 123, 1)">
              You can pause or cancel your plan at any time.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Modal
        open={chargedModalOpen}
        onClose={(values, reason) => {
          console.log({ values });
          setChargedModalOpen(false);
        }}
        sx={{
          "&:focus-visible": {
            outline: "none",
          },
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "absolute",
            top: isMobile ? "20%" : "50%",
            left: isMobile ? undefined : "50%",
            transform: isMobile ? undefined : "translate(-50%, -50%)",
            "&:focus-visible": {
              outline: "none",
            },
          }}
        >
          <Stack
            sx={(theme) => ({
              borderRadius: "10px",
              border: `1px solid ${theme.palette.gray.main}`,
              background: theme.palette.neutral["100"],
              width: isMobile ? undefined : "400px",
              height: "fit-content",
              padding: "32px",
            })}
          >
            <Typography variant="h4" color="primary.main" fontWeight={700}>
              When will I be charged?
            </Typography>
            <Typography
              variant="body2"
              sx={{ mt: 3, mb: 1.5 }}
              color="primary.main"
            >
              Rest assured, Oana Health will only process your payment after our
              licensed doctors have medically approved your prescription,
              including the cost of the visit itself.
            </Typography>
            <Typography variant="body2" sx={{ mb: 1.5 }} color="primary.main">
              While a temporary fraud prevention hold may appear on your credit
              card post checkout, it will not be finalized unless your
              prescription gets approved.
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }} color="primary.main">
              With Oana Health, you’ll always get:
            </Typography>
            <Typography
              variant="body2"
              sx={{ ml: 1.5, mb: 0.5, fontWeight: 700 }}
              color="primary.main"
            >
              ✅ Transparent pricing
            </Typography>
            <Typography
              variant="body2"
              sx={{ ml: 1.5, mb: 0.5, fontWeight: 700 }}
              color="primary.main"
            >
              ✅ Free shipping
            </Typography>
            <Typography
              variant="body2"
              sx={{ ml: 1.5, mb: 1.5 }}
              color="primary.main"
            >
              <b>✅ Free ongoing support </b>
              <Box component={"span"}>
                from our medical team throughout the course of treatment
              </Box>
            </Typography>
            <Typography variant="body2" color="primary.main">
              Cancel or pause anytime.
            </Typography>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
}
