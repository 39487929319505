import { SxProps, Theme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
  ProductDetailsFragment,
  OrderBillingPeriod,
  ProductEnum,
} from "gql-gen/graphql";

const ProductEnumToDescription = {
  [ProductEnum.HAIRLESS_HYPE]: "Topical cream",
  [ProductEnum.HAIR_PLUS_PLUS]: "Topical serum",
  [ProductEnum.MET_SPIRO]: "Oral capsules",
  [ProductEnum.TOPICAL_METFORMIN]: "Topical cream",
  [ProductEnum.METFORMIN]: "Oral pills",
  [ProductEnum.SPIRONOLACTONE]: "Oral pills",
  [ProductEnum.BUPROPION]: "Oral pills",
  [ProductEnum.METFORMIN_BUPROPION_BUNDLE]: "Oral pills",
  [ProductEnum.METFORMIN_SPIRO_BUNDLE]: "Oral pills",
  [ProductEnum.METFORMIN_SEMAGLUTIDE_PACK]: "",
  [ProductEnum.EFLORNITHINE]: "Topical cream",
  [ProductEnum.ORAL_SEMAGLUTIDE]: "Drops under the tongue",
  [ProductEnum.SEMAGLUTIDE_SHOT]: "Once a week injection",
  [ProductEnum.ORAL_MINOXIDIL]: "Oral pills",
};

export default function ProductTile({
  containerSx,
  product,
  billingPeriod,
  showDoctorVisitCost = false,
}: {
  containerSx: SxProps<Theme>;
  product: ProductDetailsFragment;
  billingPeriod: OrderBillingPeriod;
  showDoctorVisitCost?: boolean;
}) {
  const isMonthlyOnly = [
    ProductEnum.ORAL_SEMAGLUTIDE,
    ProductEnum.SEMAGLUTIDE_SHOT,
    ProductEnum.METFORMIN_SEMAGLUTIDE_PACK,
  ].includes(product.productEnum);
  const isEflornithine = [
    ProductEnum.HAIRLESS_HYPE,
    ProductEnum.EFLORNITHINE,
  ].includes(product.productEnum);
  return (
    <Stack sx={containerSx} direction="row">
      <Stack
        sx={(theme) => ({
          borderRadius: "10px",
          border: `1px solid ${theme.palette.yellow.main}`,
          background: theme.palette.yellow.main,
          mr: "16px",
          width: "100px",
          height: "100px",
        })}
      >
        <Stack
          sx={(theme) => ({
            width: "100px",
            height: "100px",
            background: `url("${product.productImages[0].sourceUrl}")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          })}
        />
      </Stack>
      <Stack
        alignItems="flex-start"
        justifyContent="center"
        mb={"8px"}
        mt="8px"
      >
        <Typography variant="body2" color="primary.main" mb="4px">
          {product.name}
        </Typography>
        <Typography
          variant="body2"
          color="primary.main"
          mb="6px"
          sx={{
            whiteSpace: "wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {ProductEnumToDescription[product.productEnum]}
        </Typography>
        {isEflornithine ? (
          <Typography
            variant="body2"
            color="primary.main"
            mb={product.priceInCentsMonthlyBilling2xDose ? "0px" : "8px"}
          >
            {billingPeriod === OrderBillingPeriod.MONTHLY
              ? "$69/mo"
              : billingPeriod === OrderBillingPeriod.ONE_TIME
              ? "$89 per 20gm bottle"
              : "$46/mo billed quartery"}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            color="primary.main"
            mb={product.priceInCentsMonthlyBilling2xDose ? "0px" : "8px"}
          >
            {`$${
              isMonthlyOnly
                ? product.priceInCentsMonthlyBilling / 100
                : billingPeriod === OrderBillingPeriod.MONTHLY
                ? product.priceInCentsMonthlyBilling / 100
                : product.priceInCentsQuarterlyBilling / 100
            }${
              product.priceInCentsMonthlyBilling2xDose
                ? billingPeriod === OrderBillingPeriod.MONTHLY
                  ? `-${product.priceInCentsMonthlyBilling2xDose / 100} `
                  : `-${product.priceInCentsQuarterlyBilling2xDose / 100} `
                : " "
            } / mo ${
              isMonthlyOnly
                ? ""
                : billingPeriod === OrderBillingPeriod.MONTHLY
                ? ""
                : "billed quarterly"
            }`}
          </Typography>
        )}
        {product.priceInCentsMonthlyBilling2xDose !== 0 && (
          <Typography variant="body2" color="primary.main" mb="8px">
            {`(pricing depends on dosage)`}
          </Typography>
        )}
        {showDoctorVisitCost &&
          product.productEnum !== ProductEnum.ORAL_SEMAGLUTIDE &&
          product.productEnum !== ProductEnum.EFLORNITHINE &&
          product.productEnum !== ProductEnum.HAIRLESS_HYPE &&
          product.productEnum !== ProductEnum.METFORMIN_SPIRO_BUNDLE &&
          product.productEnum !== ProductEnum.METFORMIN_SEMAGLUTIDE_PACK &&
          product.productEnum !== ProductEnum.SEMAGLUTIDE_SHOT && (
            <Typography variant="body2" color="primary.main">
              {"$20 initial doctor visit"}
            </Typography>
          )}
      </Stack>
    </Stack>
  );
}
