import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Link,
  Typography,
  Input,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/system";
import { useIsLoggedIn } from "components/CheckAuthComponent";
import { useAuth } from "contexts/AuthContext";
import ScribbleArrowDown from "assets/icons/scribble-arrow-down-welcome.svg";
import CheckupGreen from "assets/icons/checkup-black.svg";
import StethoscopeGreen from "assets/icons/stethoscope-black.svg";
import MessagesThinGreen from "assets/icons/messages-thin-black.svg";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";

export const allowedStates = [
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "IA",
  "ID",
  "IN",
  "KY",
  "MD",
  "MI",
  "MN",
  "MT",
  "ND",
  "NH",
  "NV",
  "OH",
  "OK",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "WA",
  "WI",
  "NC",
  "WY",
  "ME",
  "NE",
];

export default function Welcome({
  onStartVisit,
}: {
  onStartVisit: () => void;
}) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const isMobile = useMediaQuery("(max-width:600px)");

  const { setHorizontalTopValues } = useHorizontalTopHeader();
  useEffect(() => {
    if (isMobile) {
      setHorizontalTopValues({
        customHeaderTitle: "How it works",
        onClickBack: () => navigate("/dashboard/patient/home"),
        bottomPercentageCompletion: undefined,
        showOnDesktop: true,
        hideMobileToolbar: true,
        showBorderBoxShadow: true,
      });
    }
  }, [isMobile, setHorizontalTopValues, navigate]);

  if (!currentUser) {
    navigate("https://www.oanahealth.com/login");
    return;
  }

  if (!allowedStates.includes(currentUser.state)) {
    return (
      <Stack
        alignItems="center"
        justifyContent="flex-start"
        p={isMobile ? "32px" : "40px"}
        pb={"60px"}
        flexGrow={1}
        sx={(theme) => ({
          overflowY: "scroll",
          backgroundColor: "#FFF",
        })}
      >
        <Stack maxWidth="420px">
          <Stack alignItems="flex-start" direction="row">
            <Typography variant="h1" color={"primary.main"}>
              We're sorry,
            </Typography>
          </Stack>
          <Stack alignItems="flex-start" mt={"16px"}>
            <Typography variant="body2" color={"primary.main"}>
              Unfortunately, we're not serving your state currently.
            </Typography>
            <Typography variant="body2" mt={"16px"} color={"primary.main"}>
              We plan to roll out to the following states in the next 2-3 months
              and we'll contact:
            </Typography>
            <Typography variant="body2Medium" mt={"8px"} color={"primary.main"}>
              NJ, NY, PA, MA
            </Typography>
            <Typography variant="body2" mt={"16px"} color={"primary.main"}>
              We hope to serve you then!
            </Typography>
          </Stack>
          <Button
            size="large"
            sx={(theme) => ({
              mt: "32px",
              backgroundColor: theme.palette.primary.main,
            })}
            onClick={() => navigate("/patient/dashboard")}
          >
            <Typography
              variant="body1"
              component="div"
              color="primary.contrastText"
              sx={{
                "&&": {
                  fontSize: 16,
                },
              }}
            >
              Go home
            </Typography>
          </Button>
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack
      alignItems="center"
      justifyContent="flex-start"
      p={isMobile ? "32px" : "40px"}
      pb={isMobile ? "0px" : "60px"}
      flexGrow={1}
      sx={(theme) => ({
        overflowY: "scroll",
        backgroundColor: "#FFF",
      })}
    >
      <Stack maxWidth="420px">
        <Stack alignItems="flex-start" direction="row">
          <Typography variant="h1" color={"primary.main"}>
            Welcome,
          </Typography>
        </Stack>
        <Stack alignItems="flex-start" mt={"16px"}>
          <Typography variant="body2" color={"primary.main"}>
            We’re going to ask you a few questions to ensure the medication we
            prescribe is safe for you.
          </Typography>
          <Typography variant="body2" mt={"16px"} color={"primary.main"}>
            Your answers will be kept confidential and only used to make sure we
            provide you the right care.
          </Typography>
        </Stack>
        <Stack alignItems="flex-start" mt={"32px"} mb={"16px"} direction="row">
          <Typography
            variant="h1"
            sx={{
              fontWeight: "400px",
              fontSize: "24px",
            }}
            color={"primary.main"}
          >
            How this works
          </Typography>
          <Stack
            component="div"
            sx={{
              position: "relative",
            }}
          >
            <Stack
              component="img"
              sx={{
                position: "absolute",
                top: "15px",
                left: "14px",
              }}
              src={ScribbleArrowDown}
            ></Stack>
          </Stack>
        </Stack>
        <Stack
          sx={(theme) => ({
            backgroundColor: theme.palette.neutral["100"],
            borderRadius: "10px",
            paddingTop: "32px",
            paddingBottom: "32px",
            paddingRight: "30px",
            paddingLeft: "32px",
          })}
        >
          <Stack direction="row">
            <Stack
              sx={(theme) => ({
                alignItems: "center",
                justifyContent: "center",
                height: "60px",
                width: "60px",
                minWidth: "60px",
                borderRadius: "100px",
                border: `1px solid ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.purple["100"],
                mr: "16px",
              })}
            >
              <Stack
                sx={{
                  height: "34px",
                  width: "34px",
                }}
                component="img"
                src={CheckupGreen}
              />
            </Stack>
            <Stack>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                }}
                color={"primary.main"}
              >
                1. Start an online visit
              </Typography>
              <Typography variant="body2" mt={"8px"} color="primary.main">
                Tell your doctor about your health and medical history. The
                online visit takes about 10-15 minutes to complete.
              </Typography>
            </Stack>
          </Stack>
          <Stack mt={"16px"} direction="row">
            <Stack
              sx={(theme) => ({
                alignItems: "center",
                justifyContent: "center",
                height: "60px",
                width: "60px",
                minWidth: "60px",
                borderRadius: "100px",
                border: `1px solid ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.purple["100"],
                mr: "16px",
              })}
            >
              <Stack
                sx={{
                  height: "38px",
                  width: "38px",
                }}
                component="img"
                src={StethoscopeGreen}
              />
            </Stack>
            <Stack>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                }}
                color={"primary.main"}
              >
                2. Doctor review
              </Typography>
              <Typography variant="body2" mt={"8px"} color="primary.main">
                Your doctor will review the information you provide and
                determine whether the treatment is suitable for you.
              </Typography>
            </Stack>
          </Stack>
          <Stack mt={"16px"} direction="row">
            <Stack
              sx={(theme) => ({
                alignItems: "center",
                justifyContent: "center",
                height: "60px",
                width: "60px",
                minWidth: "60px",
                borderRadius: "100px",
                border: `1px solid ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.purple["100"],
                mr: "16px",
              })}
            >
              <Stack
                sx={{
                  height: "60px",
                  width: "60px",
                }}
                component="img"
                src={MessagesThinGreen}
              />
            </Stack>
            <Stack>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                }}
                color={"primary.main"}
              >
                3. Doctor follow up
              </Typography>
              <Typography variant="body2" mt={"8px"} color="primary.main">
                Your doctor will follow up via our secure portal to complete
                your consultation.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={
            isMobile
              ? {
                  // mt: "40px",
                  // position: "sticky",
                  // bottom: 0,
                  // backgroundColor: "#FFF",
                  // marginLeft: "-32px",
                  // marginRight: "-32px",
                  // paddingBottom: "32px",
                  // zIndex: 4,
                  // boxShadow: "0px -4px 4px 0px rgba(217, 217, 217, 0.40)",
                  marginBottom: "32px",
                }
              : {}
          }
        >
          <Button
            size="large"
            sx={(theme) => ({
              mt: "32px",
              backgroundColor: theme.palette.primary.main,
              marginRight: "0px", //isMobile ? "32px" : "0px",
              marginLeft: "0px", //isMobile ? "32px" : "0px",
            })}
            onClick={onStartVisit}
          >
            <Typography
              variant="body1"
              component="div"
              color="primary.contrastText"
              sx={{
                "&&": {
                  fontSize: 16,
                },
              }}
            >
              Start my visit
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
