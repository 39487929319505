import { AdminType, GenderEnum, UserRole } from "gql-gen/graphql";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { charlyAxios, fetcher } from "utils/api";
import mixpanel from "mixpanel-browser";
export const isProduction = import.meta.env.VITE_APP_ENVIRONMENT === "PROD";

export interface AuthContextType {
  currentUser?: {
    email: string;
    id: number;
    firstName: string | null;
    lastName: string | null;
    role: UserRole;
    dateOfBirth: string | null;
    phone: string | null;
    gender: GenderEnum | null;
    adminInfo: { type: AdminType } | null;
    state: string;
  } | null;
  logout: () => void;
  isLoading: boolean;
  handleLogin: (args: { email: string; password: string }) => Promise<boolean>;
  handleLoginToken: (args: { token: string }) => Promise<boolean>;
  handleSignup: (args: {
    email: string;
    password: string;
    state: string;
    type?: string;
  }) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [fetchCount, setFetchCount] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const handleLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const response = await charlyAxios.post("/api/auth/login", {
        email,
        password,
      });
      if (response.status === 200) {
        setFetchCount((fc) => fc + 1);
      }
      return true;
      // Handle success (e.g., navigate to the logged-in view)
    } catch (error) {
      return false;
      // Handle error (e.g., display an error message)
    }
  };

  const handleLoginToken = async ({ token }: { token: string }) => {
    try {
      const response = await charlyAxios.post("/api/auth/login-via-token", {
        token,
      });
      if (response.status === 200) {
        setFetchCount((fc) => fc + 1);
      }
      return true;
      // Handle success (e.g., navigate to the logged-in view)
    } catch (error) {
      return false;
      // Handle error (e.g., display an error message)
    }
  };

  const handleSignup = async ({
    email,
    password,
    state,
    type,
  }: {
    email: string;
    password: string;
    state: string;
    type?: string;
  }) => {
    try {
      const response = await charlyAxios.post("/api/auth/patient/register", {
        email,
        password,
        state,
        type,
      });
      if (response.status === 201) {
        setFetchCount((fc) => fc + 1);
        mixpanel.track("User Signup", {
          userId: `${isProduction ? "prod" : "dev"}_${response.data.user.id}`,
        });
      }

      // Handle success (e.g., navigate to the logged-in view)
    } catch (error) {
      return (error as any).response?.data?.message ?? "";
      // Handle error (e.g., display an error message)
    }
  };
  const [currentUser, setCurrentUser] = useState<
    | {
        email: string;
        id: number;
        firstName: string | null;
        lastName: string | null;
        role: UserRole;
        adminInfo: { type: AdminType } | null;
        dateOfBirth: string | null;
        phone: string | null;
        gender: GenderEnum | null;
        state: string;
      }
    | undefined
  >();

  useEffect(() => {
    const refreshFetcher = async () => {
      charlyAxios
        .get("/api/auth/user/refresh")
        .then((r) => {
          setIsLoading(false);
          if (r.data?.user?.id !== currentUser?.id) {
            setCurrentUser(r.data.user);
            mixpanel.identify(
              `${isProduction ? "prod" : "dev"}_${r.data.user.id}`
            );
            mixpanel.track("User Login", {
              userId: `${isProduction ? "prod" : "dev"}_${r.data.user.id}`,
            });
          }
        })
        .catch(() => {
          setCurrentUser(undefined);
          setIsLoading(false);
        });
    };
    const interval = setInterval(refreshFetcher, 60 * 60 * 1000);
    refreshFetcher();
    return () => {
      clearInterval(interval);
    };
  }, [fetchCount, currentUser, setIsLoading]);

  const logout = useCallback(() => {
    setCurrentUser(undefined);
    mixpanel.reset();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        handleLoginToken,
        isLoading,
        logout,
        handleLogin,
        handleSignup,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
