import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Link,
  Typography,
  Input,
  Checkbox,
  useMediaQuery,
  Divider,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  CircularProgress,
} from "@mui/material";
import { GetLatestQuestionsAndResponseForGroupsQuery } from "gql-gen/graphql";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";
import { useMutation, useQuery } from "@apollo/client";
import {
  authorizeTransaction,
  checkIfCouponIsValid,
  updateCurrentUserShippingAddress,
  getCurrentUserShippingAddressQuery,
} from "gql/User.gql";
import { GetCurrentUserShippingAddressQueryQuery } from "gql-gen/graphql";
import PurplePolygon from "assets/icons/purple-polygon.svg";
import { allowedStates } from "./Welcome";
import { stateAcronyms } from "utils/constants";
import mixpanel from "mixpanel-browser";

type Product =
  GetLatestQuestionsAndResponseForGroupsQuery["me"]["intakeTemplate"]["product"];

type ShippingAddress =
  GetCurrentUserShippingAddressQueryQuery["me"]["shippingAddress"];

export default function ShippingAddressForm({
  onBack,
  bottomPercentageCompletion,
  onContinue,
  product,
  orderId,
}: {
  orderId: number;
  bottomPercentageCompletion?: number;
  onBack: () => void;
  product: Product;
  onContinue: () => void;
}) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    mixpanel.track("Page View", {
      product: product.productEnum,
      intakeQuestion: "ADD_SHIPPING_ADDRESS",
    });
  }, []);

  const [shippingAddress, setShippingAddress] = useState<
    Partial<ShippingAddress> | undefined
  >(undefined);

  const { data, error } = useQuery(getCurrentUserShippingAddressQuery, {
    fetchPolicy: "cache-and-network",
  });

  const [updateShippingAddress] = useMutation(updateCurrentUserShippingAddress);

  useEffect(() => {
    if (data?.me.shippingAddress) {
      setShippingAddress({ ...data.me.shippingAddress });
    }
  }, [data, setShippingAddress]);

  useEffect(() => {
    document.getElementById("checkout-top")?.scrollIntoView({ block: "end" });
  }, []);

  const state = currentUser?.state;

  if (!currentUser) {
    navigate("https://www.oanahealth.com/login");
  }

  const isMobile = useMediaQuery("(max-width:600px)");

  const { setHorizontalTopValues } = useHorizontalTopHeader();

  useEffect(() => {
    setHorizontalTopValues({
      customHeaderTitle: "Shipping Address",
      onClickBack: onBack,
      bottomPercentageCompletion,
      showOnDesktop: true,
      hideMobileToolbar: true,
    });
  }, [setHorizontalTopValues, bottomPercentageCompletion, onBack]);

  const enabledSubmission =
    shippingAddress?.address &&
    shippingAddress.state &&
    shippingAddress.zipCode &&
    shippingAddress.city;

  return (
    <>
      <div
        id="checkout-top"
        style={{ marginBottom: isMobile ? "32px" : "40px" }}
      />
      <Stack
        alignItems="center"
        justifyContent="flex-start"
        id="checkout-wrapper"
        pb={"60px"}
        flexGrow={1}
        sx={(theme) => ({
          overflowY: "scroll",
          backgroundColor: "#FFF",
          marginTop: isMobile ? "-32px" : "-40px",
        })}
      >
        <Stack maxWidth="420px" width="100%">
          <Stack alignItems="flex-start" direction="row">
            <Typography variant="h1" color={"primary.main"}>
              Shipping Address
            </Typography>
          </Stack>
          <Stack
            alignItems="flex-start"
            direction="column"
            mt={"32px"}
            mb={"40px"}
          >
            <Stack mb={"16px"} width="100%">
              <Typography
                variant="caption"
                sx={{
                  mb: "8px",
                }}
                color="primary.main"
              >
                Name
              </Typography>
              <Input
                sx={(theme) => ({
                  "& input": {
                    color: theme.palette.primary.main,
                  },
                  backgroundColor: theme.palette.primary.contrastText,
                })}
                multiline={false}
                disabled
                value={`${data?.me?.firstName ?? ""} ${
                  data?.me?.lastName ?? ""
                }`}
                onChange={(e) => {
                  setShippingAddress((s) => ({
                    ...s,
                    name: e.target.value,
                  }));
                }}
              />
            </Stack>
            <Stack mb={"16px"} width="100%">
              <Typography
                variant="caption"
                sx={{
                  mb: "8px",
                }}
                color="primary.main"
              >
                Address
              </Typography>
              <Input
                sx={(theme) => ({
                  "& input": {
                    color: theme.palette.primary.main,
                  },
                  backgroundColor: theme.palette.primary.contrastText,
                })}
                multiline={false}
                value={shippingAddress?.address ?? ""}
                onChange={(e) => {
                  setShippingAddress((s) => ({
                    ...s,
                    address: e.target.value,
                  }));
                }}
              />
            </Stack>
            <Stack mb={"16px"} width="100%">
              <Typography
                variant="caption"
                sx={{
                  mb: "8px",
                }}
                color="primary.main"
              >
                Apartment, suite, etc (optional)
              </Typography>
              <Input
                sx={(theme) => ({
                  "& input": {
                    color: theme.palette.primary.main,
                  },
                  backgroundColor: theme.palette.primary.contrastText,
                })}
                multiline={false}
                value={shippingAddress?.aptSuite ?? ""}
                onChange={(e) => {
                  setShippingAddress((s) => ({
                    ...s,
                    aptSuite: e.target.value,
                  }));
                }}
              />
            </Stack>
            <Stack
              mb={"16px"}
              direction="row"
              width="100%"
              justifyContent={"space-between"}
            >
              <Stack flex={1} mr="8px">
                <Typography
                  variant="caption"
                  sx={{
                    mb: "8px",
                  }}
                  color="primary.main"
                >
                  City
                </Typography>
                <Input
                  sx={(theme) => ({
                    "& input": {
                      color: theme.palette.primary.main,
                    },
                    backgroundColor: theme.palette.primary.contrastText,
                  })}
                  multiline={false}
                  value={shippingAddress?.city ?? ""}
                  onChange={(e) => {
                    setShippingAddress((s) => ({
                      ...s,
                      city: e.target.value,
                    }));
                  }}
                />
              </Stack>
              <Stack flex={1}>
                <Typography
                  variant="caption"
                  sx={{
                    mb: "8px",
                  }}
                  color="primary.main"
                >
                  State
                </Typography>
                <Select
                  value={shippingAddress?.state ?? ""}
                  onChange={(e) => {
                    setShippingAddress((s) => ({
                      ...s,
                      state: e.target.value,
                    }));
                  }}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.primary.contrastText,
                  })}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {allowedStates.map((state) => {
                    return (
                      <MenuItem
                        value={state}
                        key={state}
                        sx={(theme) => ({
                          backgroundColor: theme.palette.primary.contrastText,
                          height: "48px",
                          "&.Mui-selected": {
                            backgroundColor: theme.palette.purple["100"],
                          },
                          "&:hover": {
                            backgroundColor: theme.palette.purple["200"],
                          },
                        })}
                      >
                        <Typography variant="caption" color="primary.main">
                          {state}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Stack>
            <Stack width="136px">
              <Typography
                variant="caption"
                sx={{
                  mb: "8px",
                }}
                color="primary.main"
              >
                Zip code
              </Typography>
              <Input
                sx={(theme) => ({
                  "& input": {
                    color: theme.palette.primary.main,
                  },
                  backgroundColor: theme.palette.primary.contrastText,
                })}
                type="text"
                multiline={false}
                value={shippingAddress?.zipCode ?? ""}
                onChange={(e) => {
                  setShippingAddress((s) => ({
                    ...s,
                    zipCode: e.target.value,
                  }));
                }}
              />
            </Stack>
          </Stack>
          <Stack
            sx={
              isMobile
                ? {
                    // mt: "40px",
                    // position: "sticky",
                    // bottom: 0,
                    // backgroundColor: "#FFF",
                    // marginLeft: "-32px",
                    // marginRight: "-32px",
                    // paddingBottom: "32px",
                    // zIndex: 4,
                    // boxShadow: "0px -4px 4px 0px rgba(217, 217, 217, 0.40)",
                  }
                : {}
            }
          >
            {loading ? (
              <Stack alignItems="center" mt={"32px"}>
                <CircularProgress color="primary" />
              </Stack>
            ) : (
              <Button
                size="large"
                sx={(theme) => ({
                  backgroundColor: enabledSubmission
                    ? theme.palette.primary.main
                    : theme.palette.gray.main,
                })}
                onClick={async () => {
                  if (
                    shippingAddress?.address &&
                    shippingAddress.city &&
                    shippingAddress.state &&
                    shippingAddress.zipCode
                  ) {
                    setLoading(true);
                    await updateShippingAddress({
                      variables: {
                        orderId: orderId,
                        addressInput: {
                          address: shippingAddress.address,
                          aptSuite: shippingAddress.aptSuite,
                          zipCode: shippingAddress.zipCode,
                          state: shippingAddress.state,
                          city: shippingAddress.city,
                        },
                      },
                      refetchQueries: ["GetOrderState"],
                    });
                    setLoading(false);
                    onContinue();
                  }
                }}
                disabled={!enabledSubmission}
              >
                <Typography
                  variant="body1"
                  component="div"
                  color="primary.contrastText"
                  sx={{
                    "&&": {
                      fontSize: 16,
                    },
                  }}
                >
                  Continue
                </Typography>
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
