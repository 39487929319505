const palette = Object.freeze({
  background: {
    default: "#FFFFFF",
    paper: "#FFFFFF",
  },
  blue: {
    "100": "#F4F6FF",
  },
  green: {
    dark: "#254441",
    "100": "#EAFFED",
    "200": "#C7FFD7",
    "300": "#63FF8F",
    "400": "#0FCE60",
    "500": "#009B32",
  },
  mainGradient: {},
  neutral: {
    "100": "#F4F4F4",
    "200": "#F9F9F9",
    "300": "#F1F1F1",
    "400": "#F5F5F5",
  },
  gray: {
    main: "#DDD",
    dark: "#7B7B7B",
    medium: "#C3C3C3",
    activeNavbarItem: "#DEE1FD",
    hoverNavbarItem: "rgba(222, 225, 253, 0.40)",
  },
  orange: {
    "80": "#FE5019",
    "900": "#FA765E",
  },
  pink: {
    main: "#DBB1BC",
    transparent80: "rgba(219, 177, 188, 0.60)",
    transparent08: "rgba(219, 177, 188, 0.10)",
  },
  purple: {
    "100": "#DEE1FD",
    "200": "#B2B7E9",
    "300": "#A7ADE7",
    "400": "#5C7AFF",
  },
  primary: {
    contrastText: "#FFFFFF",
    main: "#000",
  },
  red: {
    main: "#C25E70",
    "100": "#FFEDEB",
    "200": "#FFA396",
    "300": "#FF6A54",
    "400": "#D6352A",
    "500": "#9D0000",
  },
  secondary: {
    contrastText: "#254441",
    dark: "#42526E",
    light: "#DFE1E6",
    main: "#7A869A",
  },
  yellow: {
    main: "#EBF490",
    "100": "#FFF9EB",
    "200": "#FFEEC8",
    "300": "#FFE1A0",
    "400": "#FFA757",
    "500": "#C87829",
  },
});

export default palette;
