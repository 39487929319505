import { useEffect } from "react";
import mixpanel from "utils/mixpanel";

interface MixpanelProviderProps {
  children: React.ReactNode;
}

const MixpanelProvider: React.FC<MixpanelProviderProps> = ({ children }) => {
  useEffect(() => {
    mixpanel.track("Page View", { page: window.location.pathname });
  }, []);

  return <>{children}</>;
};

export default MixpanelProvider;
