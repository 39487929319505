import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loading() {
  return (
    <Stack alignItems="center">
      <Typography variant="h2" color="primary.main" sx={{ mb: 4 }}>
        Loading...
      </Typography>
      <CircularProgress color="primary" />
    </Stack>
  );
}
