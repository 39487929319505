import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Link,
  Typography,
  Input,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";

import { useAuth } from "contexts/AuthContext";
import { useMutation, useQuery } from "@apollo/client";
import { getCurrentUserQuery, updateUserFields } from "gql/User.gql";
import Loading from "components/Loading";
import { DateField } from "@mui/x-date-pickers";
import { getDateObjFromDate } from "utils/date";
import { useHorizontalTopHeader } from "contexts/HorizontalTopHeaderContext";
import RightArrowGreen from "assets/icons/right-arrow-black.svg";
import { charlyAxios } from "utils/api";

export default function MobileAccountNav() {
  const { setHorizontalTopValues } = useHorizontalTopHeader();
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (isMobile) {
      setHorizontalTopValues({
        customHeaderTitle: "Account",
        onClickBack: undefined,
        bottomPercentageCompletion: undefined,
        showOnDesktop: false,
        showBorderBoxShadow: true,
        hideMobileToolbar: false,
      });
    } else {
      navigate("/dashboard/account/profile");
    }
  }, [isMobile, setHorizontalTopValues, navigate]);
  const handleLogout = async () => {
    try {
      await charlyAxios.get("/api/auth/logout");
      auth.logout();
      navigate("/login");
    } catch (error) {
      // Handle error during logout
    }
  };

  return (
    <Stack
      width={"100%"}
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.contrastText,
        overflowY: "scroll",
        paddingY: "32px",
      })}
      flex={1}
    >
      <Stack direction="row" flex={1}>
        <Stack width="100%">
          <Stack
            direction="row"
            sx={(theme) => ({
              borderRadius: "10px",
              border: `1px solid ${theme.palette.gray.main}`,
              px: "16px",
              py: "14px",
              mb: "16px",
              justifyContent: "space-between",
              alignItems: "center",
              mx: "32px",
            })}
            onClick={() => {
              navigate("/dashboard/patient/account/profile");
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: "20px",
                lineHeight: 1.6,
              }}
              color="primary.main"
            >
              Profile
            </Typography>
            <Stack
              component="img"
              sx={{
                height: "14px",
                width: "10px",
              }}
              src={RightArrowGreen}
            />
          </Stack>
          <Stack
            direction="row"
            sx={(theme) => ({
              borderRadius: "10px",
              border: `1px solid ${theme.palette.gray.main}`,
              px: "16px",
              py: "14px",
              mb: "16px",
              justifyContent: "space-between",
              alignItems: "center",
              mx: "32px",
            })}
            onClick={() => {
              navigate("/dashboard/patient/account/subscriptions");
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: "20px",
                lineHeight: 1.6,
              }}
              color="primary.main"
            >
              Subscriptions
            </Typography>
            <Stack
              component="img"
              sx={{
                height: "14px",
                width: "10px",
              }}
              src={RightArrowGreen}
            />
          </Stack>
          <Stack
            direction="row"
            sx={(theme) => ({
              borderRadius: "10px",
              border: `1px solid ${theme.palette.gray.main}`,
              px: "16px",
              py: "14px",
              mb: "16px",
              justifyContent: "space-between",
              alignItems: "center",
              mx: "32px",
            })}
            onClick={() => {
              navigate("/dashboard/patient/account/payment-method");
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: "20px",
                lineHeight: 1.6,
              }}
              color="primary.main"
            >
              Payment method
            </Typography>
            <Stack
              component="img"
              sx={{
                height: "14px",
                width: "10px",
              }}
              src={RightArrowGreen}
            />
          </Stack>
          <Stack
            direction="row"
            sx={(theme) => ({
              borderRadius: "10px",
              border: `1px solid ${theme.palette.gray.main}`,
              px: "16px",
              py: "14px",
              mb: "16px",
              justifyContent: "space-between",
              alignItems: "center",
              mx: "32px",
            })}
            onClick={() => {
              navigate("/dashboard/patient/account/password-reset");
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: "20px",
                lineHeight: 1.6,
              }}
              color="primary.main"
            >
              Password reset
            </Typography>
            <Stack
              component="img"
              sx={{
                height: "14px",
                width: "10px",
              }}
              src={RightArrowGreen}
            />
          </Stack>
          <Stack
            direction="row"
            sx={(theme) => ({
              borderRadius: "10px",
              border: `1px solid ${theme.palette.gray.main}`,
              px: "16px",
              py: "14px",
              justifyContent: "space-between",
              alignItems: "center",
              mx: "32px",
              mb: "42px",
            })}
            onClick={() => {
              navigate("/dashboard/patient/account/shipping-address");
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: "20px",
                lineHeight: 1.6,
              }}
              color="primary.main"
            >
              Shipping address
            </Typography>
            <Stack
              component="img"
              sx={{
                height: "14px",
                width: "10px",
              }}
              src={RightArrowGreen}
            />
          </Stack>
          <Button onClick={handleLogout}>Logout</Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
