import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  useRoutes,
  Outlet,
  useNavigate,
} from "react-router-dom";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import LoginToken from "./pages/LoginToken";
import { CheckAuthComponent } from "components/CheckAuthComponent";
import { AuthProvider } from "contexts/AuthContext";
import { ApolloProvider } from "contexts/ApolloProvider";
import { muiTheme } from "theme";
import { Stack, ThemeProvider } from "@mui/system";
import IntakeQuestions from "pages/intake/IntakeQuestions";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import Home from "pages/Home";
import AccountProfile from "pages/account/Profile";
import PaymentMethod from "pages/account/Payment";
import UserOrdersPage from "pages/orders/Orders";
import UserMessages from "pages/messages/UserMessages";
import { ThreadType } from "gql-gen/graphql";
import { HorizontalTopHeaderProvider } from "contexts/HorizontalTopHeaderContext";
import MobileAccountNav from "pages/account/MobileAccountNav";
import MobileMessagesNav from "pages/messages/MobileMessagesNav";
import { TopHeaderLayout } from "layouts/TopHeader";
import {
  PatientAccountLeftNavbar,
  PatientLeftNavbar,
  PatientMessagesLeftNavbar,
} from "layouts/PatientLeftNavbar";
import {
  AdminAccountLeftNavbar,
  AdminLeftNavbar,
} from "layouts/AdminLeftNavbar";
import Loading from "components/Loading";
import IdVerificationIntakeStep from "pages/intake/IdVerification";
import { useMediaQuery } from "@mui/material";
import MixpanelProvider from "contexts/MixpanelProvider";
import ActiveSubscriptions from "pages/admin/ActiveSubscriptions";

const AllThreads = React.lazy(() => import("./pages/admin/AllThreads"));
const AdminMedicalLicenses = React.lazy(
  () => import("./pages/account/AdminMedicalLicenses")
);
const IntakeQueue = React.lazy(() => import("./pages/admin/IntakeQueue"));
const AllOrders = React.lazy(() => import("./pages/admin/AllOrders"));
const PatientDetails = React.lazy(() => import("./pages/admin/PatientDetails"));
const UploadOrders = React.lazy(() => import("./pages/admin/UploadOrders"));
const AllPatients = React.lazy(() => import("./pages/admin/AllPatients"));
const Subscriptions = React.lazy(() => import("./pages/account/Subscriptions"));
const PasswordReset = React.lazy(() => import("./pages/account/PasswordReset"));
const AccountShippingAddress = React.lazy(
  () => import("./pages/account/ShippingAddress")
);
const VerifyEmail = React.lazy(() => import("./pages/VerifyEmail"));
const ResetPasswordRequest = React.lazy(
  () => import("./pages/ResetPasswordRequest")
);
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));

function RoutesComponent() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  return useRoutes([
    {
      path: "/signup",
      element: (
        <TopHeaderLayout>
          <SignUp />
        </TopHeaderLayout>
      ),
    },
    {
      path: "/login",
      element: (
        <TopHeaderLayout>
          <Login />
        </TopHeaderLayout>
      ),
    },
    {
      path: "/login-token",
      element: (
        <TopHeaderLayout>
          <LoginToken />
        </TopHeaderLayout>
      ),
    },
    {
      path: "/verify-email",
      element: (
        <TopHeaderLayout>
          <Suspense
            fallback={
              <Stack p={"64px"}>
                <Loading />
              </Stack>
            }
          >
            <VerifyEmail />
          </Suspense>
        </TopHeaderLayout>
      ),
    },
    {
      path: "/reset-password",
      element: (
        <TopHeaderLayout>
          <Suspense
            fallback={
              <Stack p={"64px"}>
                <Loading />
              </Stack>
            }
          >
            <ResetPassword />
          </Suspense>
        </TopHeaderLayout>
      ),
    },
    {
      path: "/request-reset-password",
      element: (
        <TopHeaderLayout>
          <Suspense
            fallback={
              <Stack p={"64px"}>
                <Loading />
              </Stack>
            }
          >
            <ResetPasswordRequest />
          </Suspense>
        </TopHeaderLayout>
      ),
    },
    {
      path: "/intake/:product_enum/",
      element: (
        <TopHeaderLayout>
          <Stack direction="row" flexGrow={1} height={0}>
            <Outlet />
          </Stack>
        </TopHeaderLayout>
      ),
      children: [{ path: "qs", element: <IntakeQuestions /> }],
    },
    {
      path: "id-verification",
      element: (
        <TopHeaderLayout>
          <Stack direction="row" flexGrow={1} height={0}>
            <Stack
              alignItems="center"
              justifyContent="flex-start"
              p={isMobile ? "32px" : "40px"}
              pb={"60px"}
              flexGrow={1}
              sx={(theme) => {
                return {
                  overflowY: "scroll",
                  flexGrow: 1,
                };
              }}
            >
              <IdVerificationIntakeStep
                onBack={() => {
                  navigate("/dashboard/patient/home");
                }}
                allowSkip={false}
                onContinue={() => {
                  navigate("/dashboard/patient/home");
                }}
              />
            </Stack>
          </Stack>
        </TopHeaderLayout>
      ),
    },
    {
      path: "/dashboard/",
      children: [
        {
          path: "patient/*",
          element: (
            <TopHeaderLayout>
              <PatientLeftNavbar>
                <CheckAuthComponent require="loggedin" />
              </PatientLeftNavbar>
            </TopHeaderLayout>
          ),
          children: [
            { path: "home", element: <Home /> },
            {
              path: "messages",
              element: (
                <PatientMessagesLeftNavbar>
                  <Outlet />
                </PatientMessagesLeftNavbar>
              ),
              children: [
                { path: "mobile-nav", element: <MobileMessagesNav /> },
                {
                  path: "medical",
                  element: (
                    <UserMessages
                      type={ThreadType.MEDICAL}
                      key={ThreadType.MEDICAL}
                    />
                  ),
                },
                {
                  path: "support",
                  element: (
                    <UserMessages
                      type={ThreadType.SUPPORT}
                      key={ThreadType.SUPPORT}
                    />
                  ),
                },
              ],
            },
            {
              path: "orders",
              element: <UserOrdersPage />,
            },
            {
              path: "account",
              element: (
                <PatientAccountLeftNavbar>
                  <Outlet />
                </PatientAccountLeftNavbar>
              ),
              children: [
                { path: "mobile-nav", element: <MobileAccountNav /> },
                { path: "profile", element: <AccountProfile /> },
                {
                  path: "subscriptions",
                  element: (
                    <Suspense
                      fallback={
                        <Stack p={"64px"}>
                          <Loading />
                        </Stack>
                      }
                    >
                      {" "}
                      <Subscriptions />
                    </Suspense>
                  ),
                },
                {
                  path: "payment-method",
                  element: (
                    <Suspense
                      fallback={
                        <Stack p={"64px"}>
                          <Loading />
                        </Stack>
                      }
                    >
                      <PaymentMethod />
                    </Suspense>
                  ),
                },
                {
                  path: "password-reset",
                  element: (
                    <Suspense
                      fallback={
                        <Stack p={"64px"}>
                          <Loading />
                        </Stack>
                      }
                    >
                      <PasswordReset />
                    </Suspense>
                  ),
                },
                {
                  path: "shipping-address",
                  element: (
                    <Suspense
                      fallback={
                        <Stack p={"64px"}>
                          <Loading />
                        </Stack>
                      }
                    >
                      <AccountShippingAddress />
                    </Suspense>
                  ),
                },
              ],
            },
            // You can add more protected routes here
            {
              path: "*",
              element: <Navigate to="/dashboard/patient/home" replace />,
            },
          ],
        },
        {
          path: "admin/*",
          element: (
            <Stack height="100vh" width="100vw">
              <AdminLeftNavbar>
                <CheckAuthComponent require="loggedin" adminOnly />
              </AdminLeftNavbar>
            </Stack>
          ),
          children: [
            {
              path: "all-orders",
              element: (
                <Suspense
                  fallback={
                    <Stack p={"64px"}>
                      <Loading />
                    </Stack>
                  }
                >
                  <AllOrders />
                </Suspense>
              ),
            },
            {
              path: "messages",
              element: (
                <Suspense
                  fallback={
                    <Stack p={"64px"}>
                      <Loading />
                    </Stack>
                  }
                >
                  <AllThreads />
                </Suspense>
              ),
            },
            {
              path: "patient-queue",
              element: (
                <Suspense
                  fallback={
                    <Stack p={"64px"}>
                      <Loading />
                    </Stack>
                  }
                >
                  <IntakeQueue />
                </Suspense>
              ),
            },
            {
              path: "patient-details/:patient_id/",
              element: (
                <Suspense
                  fallback={
                    <Stack p={"64px"}>
                      <Loading />
                    </Stack>
                  }
                >
                  <PatientDetails />
                </Suspense>
              ),
            },
            {
              path: "all-patients",
              element: (
                <Suspense
                  fallback={
                    <Stack p={"64px"}>
                      <Loading />
                    </Stack>
                  }
                >
                  <AllPatients />
                </Suspense>
              ),
            },
            {
              path: "active-subscriptions",
              element: (
                <Suspense
                  fallback={
                    <Stack p={"64px"}>
                      <Loading />
                    </Stack>
                  }
                >
                  <ActiveSubscriptions />
                </Suspense>
              ),
            },
            {
              path: "order-shipping",
              element: (
                <Suspense
                  fallback={
                    <Stack p={"64px"}>
                      <Loading />
                    </Stack>
                  }
                >
                  <UploadOrders />
                </Suspense>
              ),
            },
            {
              path: "account",
              element: (
                <AdminAccountLeftNavbar>
                  <Outlet />
                </AdminAccountLeftNavbar>
              ),
              children: [
                { path: "profile", element: <AccountProfile /> },
                {
                  path: "password-reset",
                  element: (
                    <Suspense
                      fallback={
                        <Stack p={"64px"}>
                          <Loading />
                        </Stack>
                      }
                    >
                      <PasswordReset />
                    </Suspense>
                  ),
                },
                {
                  path: "address",
                  element: (
                    <Suspense
                      fallback={
                        <Stack p={"64px"}>
                          <Loading />
                        </Stack>
                      }
                    >
                      <AccountShippingAddress />
                    </Suspense>
                  ),
                },
                {
                  path: "licenses",
                  element: (
                    <Suspense
                      fallback={
                        <Stack p={"64px"}>
                          <Loading />
                        </Stack>
                      }
                    >
                      <AdminMedicalLicenses />
                    </Suspense>
                  ),
                },
              ],
            },
            // You can add more protected routes here
            {
              path: "*",
              element: <Navigate to="/dashboard/admin/all-orders" replace />,
            },
          ],
        },
      ],
    },
    { path: "*", element: <Navigate to="/login" replace /> },
  ]);
}

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MixpanelProvider>
          <HorizontalTopHeaderProvider>
            <AuthProvider>
              <ApolloProvider>
                <Router>
                  <RoutesComponent />
                </Router>
              </ApolloProvider>
            </AuthProvider>
          </HorizontalTopHeaderProvider>
        </MixpanelProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
