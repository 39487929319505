import { graphql } from "gql-gen";

export const productDetailsFragment = graphql(`
  fragment ProductDetails on Product {
    id
    name
    priceInCentsQuarterlyBilling
    priceInCentsMonthlyBilling
    priceInCentsMonthlyBilling2xDose
    priceInCentsQuarterlyBilling2xDose
    productEnum
    instructions
    productImages {
      id
      sourceUrl
    }
  }
`);

export const getLatestQuestionsAndResponseForGroup = graphql(`
  query GetLatestQuestionsAndResponseForGroups($productEnum: String!) {
    me {
      id
      userSelfiePhoto {
        id
      }
      userIdPhoto {
        id
      }
      intakeTemplate(productEnum: $productEnum) {
        id
        latestUncompletedResponse {
          id
          orderId
        }
        productId
        product {
          ...ProductDetails
        }
        intakeQuestionGroups {
          id
          title
          subtitle
          caption
          encouragementSlide
          showProductPreview
          intakeTemplateId
          templateQuestions {
            id
            order
            question {
              id
              prompt
              type
              latestUserResponse {
                value
                id
              }
              conditionalQuestion {
                triggeringOptionId
                triggeringQuestionId
              }
              options {
                id
                label
                dataType
                latestUserResponse {
                  id
                  value
                  s3Key
                  presignedUrl
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const getIntakeGroups = graphql(`
  query GetIntakeGroups($productEnum: String!) {
    me {
      id
      intakeTemplate(productEnum: $productEnum) {
        latestUncompletedResponse {
          id
        }
        intakeQuestionGroups {
          id
          title
          subtitle
          caption
          encouragementSlide
          showProductPreview
          intakeTemplateId
        }
      }
    }
  }
`);

export const getProduct = graphql(`
  query GetProduct($productEnum: String!) {
    product(productEnum: $productEnum) {
      ...ProductDetails
    }
  }
`);

export const upsertIntakeQuestionGroupWithResponse = graphql(`
  mutation UpsertIntakeQuestionGroupWithResponse(
    $intakeQuestionGroupId: Int!
    $productId: Int!
    $orderId: Int
    $responses: [QuestionResponse!]!
  ) {
    upsertIntakeQuestionGroupWithResponses(
      intakeQuestionGroupId: $intakeQuestionGroupId
      responses: $responses
      productId: $productId
      orderId: $orderId
    )
  }
`);

export const submitIntakeResponse = graphql(`
  mutation SubmitIntakeResponse($intakeResponseId: Int!) {
    submitIntakeResponse(intakeResponseId: $intakeResponseId)
  }
`);

export const completeIntake = graphql(`
  mutation CompleteIntake(
    $billingPeriod: OrderBillingPeriod!
    $intakeResponseId: Int!
    $addressInput: AddressInput!
    $couponCode: String
  ) {
    completeIntake(
      billingPeriod: $billingPeriod
      responseId: $intakeResponseId
      addressInput: $addressInput
      couponCode: $couponCode
    )
  }
`);

export const updateIncompleteOrderBillingPeriod = graphql(`
  mutation UpdateIncompleteOrderBillingPeriod(
    $orderId: Int!
    $orderBillingPeriod: OrderBillingPeriod!
  ) {
    updateIncompleteOrderBillingPeriod(
      orderId: $orderId
      orderBillingPeriod: $orderBillingPeriod
    )
  }
`);
