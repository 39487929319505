import axios from "axios";

// Check if the app is running in production
export const isProduction = import.meta.env.VITE_APP_ENVIRONMENT === "PROD";

// Set Axios base URL based on the environment
if (isProduction) {
  axios.defaults.baseURL = "https://api.oanahealth.com";
}
axios.defaults.withCredentials = true;
export const charlyAxios = axios;

export const fetcher = (url: Array<string>) => {
  return charlyAxios.get(url[0]).then((res) => res.data);
};
